import { useEffect } from 'react'
import Swal from 'sweetalert2'
/*****************************************************************************
 * @description componente para manejo de mensaje de validacion
 ****************************************************************************/

interface MessageTypes {
  type: number
  msg: string
  details?: string
  callback?: () => void
}

const MessageManager = ({ type, msg, details, callback }: MessageTypes) => {
  useEffect(() => {
    showMessage()
    // eslint-disable-next-line
  }, [])

  const showMessage = () => {
    Swal.fire({
      title: `<span style="font-weight: 400">${msg}</span>`,
      html: `${details}`,
      icon: type === 2 ? 'error' : 'success',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: '#f1b80e'
    }).then(() => callback && callback())
  }

  return <></>
}

export default MessageManager
