import SvgIcon from '@mui/material/SvgIcon'
// @ts-ignore
import { ReactComponent as altio } from './svgs/altio.svg'
import { ReactComponent as logo } from './svgs/logo-altio-horizontal.svg'

export const AltioLogo = () => {
  return (
    <SvgIcon
      component={logo}
      viewBox="0 0 543.59 167.47"
      sx={{
        fontSize: '4rem',
        width: 150
        //maxWidth: { xs: '90px', sm: '25vw' }
      }}
    />
  )
}
