import { Navigate, useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { useEffect, useContext } from 'react'
import UserDataContext from '../context/UserDataContext'

const PrivateRoute = ({ children }: any) => {
  const [cookies] = useCookies(['altio_auth'])
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies(['altio_auth'])
  const { dataUser, setDataUser } = useContext(UserDataContext)

  let idTimer: ReturnType<typeof setTimeout>

  const checkInactivity = () => {
    clearTimeout(idTimer)
    if (dataUser !== undefined) {
      idTimer = setTimeout(() => {
        setDataUser(undefined)
        navigate('/')
      }, 300000)
    }
  }

  /* useEffect(() => {
    let isMounted = true
    if (isMounted) {
      checkInactivity()
      window.onclick = () => checkInactivity()
      window.onkeypress = () => checkInactivity()
      window.addEventListener('mousemove', checkInactivity)
    }

    return () => {
      isMounted = false
    }
  }, []) */

  if (!dataUser) {
    return <Navigate to={'/'} replace />
  }
  return children
}

export default PrivateRoute
