import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Divider,
  Tabs,
  Tab,
  Box
} from '@mui/material'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLayoutEffect, useState } from 'react'
import useClient from '../../hooks/useClient'
import QuotaSubscriptionForm from './downloaddocumentsmodal/QuotaSubscriptionForm'
import QuotaSubscriptionFrameworkContract from './downloaddocumentsmodal/QuotaSubscriptionFrameworkContract'
import Bill from './downloaddocumentsmodal/Bill'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function ariaProps(document: string) {
  return {
    id: `tab-${document}`,
    'aria-controls': `tabpanel-${document}`
  }
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2, px: 2, pb: 4 }}>{children}</Box>}
    </div>
  )
}

const DownloadDocumentsModal = ({ dataClient }: any) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm({
    mode: 'onChange'
  })

  /*   useLayoutEffect(() => {
    if (data === undefined) {
      loadData && loadData(id)
    }
  }, []) */

  // Función para guardar la pestaña seleccionada en la variable global
  const saveTab = (tab: any) => {
    setSelectedTab(tab)
  }

  const handleChangeTab = (event: any, newValue: any) => {
    // Guarda el valor de la pestaña seleccionada en la variable global
    setSelectedTab(newValue)
  }

  const handleDownloadQuotaSubscriptionFrameworkContract = () => {
    console.log('Decaraga Contrato Marco de Suscripción de Cuotas')
  }

  return (
    <>
      <DialogTitle
        id="alert-dialog-title"
        sx={{ pt: 2, px: 4, borderBottom: 1, borderColor: 'divider' }}
      >
        Descarga documentos
      </DialogTitle>
      <DialogContent
        sx={{
          pr: 4,
          pl: 0,
          pb: 0,
          //display: 'flex'
          display: 'grid',
          gridTemplateColumns: 'repeat( auto-fit, minmax(150px, 1fr) )'
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={selectedTab}
            onChange={handleChangeTab}
            aria-label="customer tabs"
            orientation="vertical"
            variant="scrollable"
            sx={{ borderRight: 1, borderColor: 'divider', height: '100%' }}
          >
            <Tab
              label="Formulario de Suscripción de Cuotas"
              {...ariaProps('single-upload-customer-docs')}
              sx={{
                alignItems: 'flex-start',
                textAlign: 'left'
              }}
            />
            <Tab
              label="Contrato Marco de Suscripción de Cuotas"
              {...ariaProps('single-upload-customer-docs')}
              sx={{
                alignItems: 'flex-start',
                textAlign: 'left'
              }}
            />
            <Tab
              label="Minuta"
              {...ariaProps('single-upload-customer-docs')}
              sx={{
                alignItems: 'flex-start',
                textAlign: 'left'
              }}
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={selectedTab} index={0}>
          <QuotaSubscriptionForm dataClient={dataClient} />
          {/* <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{
                  '& label': {
                    transform: 'translate(0px, -9px) scale(0.75)'
                  }
                }}
              >
                <InputLabel>Moneda</InputLabel>
                <Select
                  {...register('currency')}
                  label="Representante legal"
                  variant="standard"
                  defaultValue={'DOP'}
                >
                  <MenuItem value="DOP">Peso Dominicano (DOP)</MenuItem>
                  <MenuItem value="USD">Dolar Estadounidense (USD)</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={handleDownloadQuotaSubscriptionFrameworkContract}
              >
                Descargar
              </Button>
            </Grid>
          </Grid> */}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={1}>
          <QuotaSubscriptionFrameworkContract dataClient={dataClient} />
          {/*  <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                name={'code'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Nombre del inversionista"
                    type="text"
                    variant="standard"
                    fullWidth
                    value={value}
                    {...register('investor_name')}
                    //error={errors.code && Boolean(errors.code)}
                    //helperText={errors.code && errors.code.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                size="small"
                sx={{
                  '& label': {
                    marginLeft: '-14px'
                  }
                }}
              >
                <InputLabel id="driver-select-label">Representante legal</InputLabel>
                <Select
                  {...register('legal_representative')}
                  label="Representante legal"
                  variant="standard"
                  fullWidth
                >
                  <MenuItem value="Eduardo José Turrull Leyba">Eduardo José Turrull Leyba</MenuItem>
                  <MenuItem value="Eliza Rafaelevna Sanchez Lomakina">
                    Eliza Rafaelevna Sanchez Lomakina
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
              <Button
                variant="contained"
                onClick={handleDownloadQuotaSubscriptionFrameworkContract}
              >
                Descargar
              </Button>
            </Grid>
          </Grid> */}
        </CustomTabPanel>
        <CustomTabPanel value={selectedTab} index={2}>
          <Bill dataClient={dataClient} />
        </CustomTabPanel>
      </DialogContent>
    </>
  )
}

export default DownloadDocumentsModal
