import { useState, useEffect } from 'react'
import {
  Autocomplete,
  TextField,
  Stack,
  Grid,
  Divider,
  IconButton,
  Button,
  CircularProgress
} from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import ButtonGradient from '../../components/ButtonGradient'
import { datos } from '../../data/data'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationEconomicActivityCompany } from './validation'
import { EconomicActivityCompanyType } from '../../types/types'
import { saveProfile, updateProfile } from '../../services/Services'

const EconomicActivity = () => {
  const { data, allData, allDataJ, address, handleNext, loadTypeProfile, loadData } = useClient()
  const [loadingSendData, setLoadingSendData] = useState(false)
  const miarray: any = []
  const userID = data.body?.userId
  const countryASC =
    allData &&
    allData.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1
      }
    })

  const findData = data.body?.fields

  const defaultValues: EconomicActivityCompanyType = {
    nonfinancial_activities: findData.nonfinancial_activities
      ? datos.non_financial_activities.find((f: any) => f.id === findData.nonfinancial_activities)
      : [],
    other_economic_activity: findData.other_economic_activity
      ? allDataJ.al_ta_other_economic_activity.find(
          (f: any) => f.id === Number(findData.other_economic_activity)
        )
      : [],
    financialactivities: findData.financialactivities
      ? datos.financial_activities.find((f: any) => f.id === findData.financialactivities)
      : [],
    economic_sector: findData.economic_sector ? findData.economic_sector : '',
    entity_have_links_with_altio: findData.entity_have_links_with_altio
      ? datos.selectBoolean.find((f: any) => f.id === findData.entity_have_links_with_altio)
      : [],
    entity_have_links_with_altio_data: (findData.entity_have_links_with_altio_data &&
      JSON.parse(findData.entity_have_links_with_altio_data)) ?? [
      {
        name_or_social_denomination: '',
        relation_with_entity: ''
      }
    ],
    annual_income: findData.annual_income
      ? allDataJ.al_ta_annual_income.find((f: any) => f.id === Number(findData.annual_income))
      : [],
    annual_expenses: findData.annual_expenses
      ? allDataJ.al_ta_annual_income.find((f: any) => f.id === Number(findData.annual_expenses))
      : [],
    total_assets: findData.total_assets
      ? allDataJ.al_ta_annual_income.find((f: any) => f.id === Number(findData.total_assets))
      : [],
    total_liabilities: findData.total_liabilities
      ? allDataJ.al_ta_annual_income.find((f: any) => f.id === Number(findData.total_liabilities))
      : [],
    subscribed_paid_Capital: findData.subscribed_paid_Capital
      ? allDataJ.al_ta_annual_income.find(
          (f: any) => f.id === Number(findData.subscribed_paid_Capital)
        )
      : [],
    total_employees: findData.total_employees ? findData.total_employees : '',
    average_annual_amount: findData.average_annual_amount
      ? allDataJ.al_ta_initial_investment_amount.find(
          (f: any) => f.id === Number(findData.average_annual_amount)
        )
      : [],
    total_heritage: findData.total_heritage
      ? allDataJ.al_ta_total_heritage.find((f: any) => f.id === Number(findData.total_heritage))
      : [],
    investment_goals: findData.investment_goals
      ? allData &&
        allData.al_ta_investment_goals.find((f: any) => f.id === Number(findData.investment_goals))
      : [],
    main_sources_funds: findData.main_sources_funds ? findData.main_sources_funds : '',
    main_clients_entity: (findData.main_clients_entity &&
      JSON.parse(findData.main_clients_entity)) ?? [
      {
        social_name: '',
        identification_number: '',
        economic_sector: [],
        other_economic_sector: '',
        contact: ''
      }
    ]
  }

  const { register, handleSubmit, reset, resetField, formState, control, watch, getValues } =
    useForm<EconomicActivityCompanyType>({
      defaultValues,
      resolver: yupResolver(validationEconomicActivityCompany)
    })
  const { errors, isDirty, dirtyFields } = formState

  const {
    fields: entity_have_links_with_altio_dataFields,
    append: entity_have_links_with_altio_dataAppend,
    remove: entity_have_links_with_altio_dataRemove
  } = useFieldArray({
    control,
    name: 'entity_have_links_with_altio_data',
    rules: {
      minLength: 1
    }
  })
  const {
    fields: main_clients_entityFields,
    append: main_clients_entityAppend,
    remove: main_clients_entityRemove
  } = useFieldArray({
    control,
    name: 'main_clients_entity',
    rules: {
      minLength: 3
    }
  })

  const watch_non_financial_activities: any = watch('nonfinancial_activities')
  const watch_financial_activities: any = watch('financialactivities')

  const watch_entity_have_links_with_altio: any = watch('entity_have_links_with_altio')
  const watch_main_clients_entity: any = watch('main_clients_entity')

  useEffect(() => {
    reset(defaultValues)
  }, [findData])

  useEffect(() => {
    if (watch_non_financial_activities?.id !== '6' || watch_financial_activities?.id !== '9') {
      resetField('other_economic_activity')
    }

    console.log(watch_financial_activities)
  }, [watch_financial_activities, watch_non_financial_activities])

  useEffect(() => {
    if (watch_entity_have_links_with_altio?.id !== '1') {
      resetField('entity_have_links_with_altio_data')
    }
  }, [watch_entity_have_links_with_altio])

  useEffect(() => {
    if (
      findData !== undefined &&
      dirtyFields.main_clients_entity &&
      findData?.step_two_complete === 'true'
    ) {
      if (watch_main_clients_entity?.[0]?.economic_sector?.id !== '15') {
        resetField(`main_clients_entity.${0}.other_economic_sector`, {
          defaultValue: ''
        })
      }
      if (watch_main_clients_entity?.[1]?.economic_sector?.id !== '15') {
        resetField(`main_clients_entity.${1}.other_economic_sector`, {
          defaultValue: ''
        })
      }
      if (watch_main_clients_entity?.[2]?.economic_sector?.id !== '15') {
        resetField(`main_clients_entity.${2}.other_economic_sector`, {
          defaultValue: ''
        })
      }
    }
  }, [watch_main_clients_entity])

  return (
    <form>
      <TitleForm>Actividad económica de la empresa</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="nonfinancial_activities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.non_financial_activities}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="a) Actividades no financieras"
                      variant="standard"
                      error={!!errors.nonfinancial_activities}
                      helperText={
                        errors.nonfinancial_activities && errors.nonfinancial_activities.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="financialactivities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.financial_activities}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="b) Actividades financieras"
                      variant="standard"
                      error={!!errors.financialactivities}
                      helperText={errors.financialactivities && errors.financialactivities.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {(data.body?.fields?.financialactivities === '9' ||
          data.body?.fields?.nonfinancial_activities === '6') && (
          <Grid item xs={12}>
            <FancyInput
              required
              type={'text'}
              disabled={true}
              name="other_economic_activity"
              label="Si marca otro favor indicar ¿Cuál?"
              value={
                data.body?.fields?.other_economic_activity &&
                allDataJ &&
                allDataJ.al_ta_other_economic_activity.find(
                  (f: any) => f.id === Number(data.body?.fields?.other_economic_activity)
                )?.description
              }
            />
            <Controller
              control={control}
              name="other_economic_activity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue)
                  }}
                  options={allDataJ && allDataJ.al_ta_other_economic_activity}
                  getOptionLabel={(option: any) => option.description || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps
                    inputProps.autoComplete = 'new-password'
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Otra Actividad Económica"
                        variant="standard"
                        error={!!errors.other_economic_activity}
                        helperText={
                          errors.other_economic_activity && errors.other_economic_activity.message
                        }
                      />
                    )
                  }}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <Grid container sx={{ pt: 3 }} rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="economic_sector"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.economic_sector}
                getOptionLabel={(option: any) => option || ''}
                isOptionEqualToValue={(option: any, value: any) => option === value}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Sector económico"
                      variant="standard"
                      error={!!errors.economic_sector}
                      helperText={errors.economic_sector && errors.economic_sector.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Personas Vinculadas
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3 }} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="entity_have_links_with_altio"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee la entidad vínculos con ALTIO, sus accionistas, directores o empleados?"
                      variant="standard"
                      error={!!errors.entity_have_links_with_altio}
                      helperText={
                        errors.entity_have_links_with_altio &&
                        errors.entity_have_links_with_altio.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {/* {JSON.parse(data.body?.fields?.entity_have_links_with_altio_data).map( */}
      {entity_have_links_with_altio_dataFields.map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`entity_have_links_with_altio_data.${index}.name_or_social_denomination`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombres / Denominación social"
                      type="text"
                      variant="standard"
                      {...register(
                        `entity_have_links_with_altio_data.${index}.name_or_social_denomination` as const
                      )}
                      error={
                        errors?.entity_have_links_with_altio_data?.[index]
                          ?.name_or_social_denomination && true
                      }
                      helperText={
                        errors?.entity_have_links_with_altio_data?.[index]
                          ?.name_or_social_denomination?.message
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: 'flex' }}>
                <Controller
                  name={`entity_have_links_with_altio_data.${index}.relation_with_entity`}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Relación"
                      type="text"
                      variant="standard"
                      {...register(
                        `entity_have_links_with_altio_data.${index}.relation_with_entity` as const
                      )}
                      error={
                        errors?.entity_have_links_with_altio_data?.[index]?.relation_with_entity &&
                        true
                      }
                      helperText={
                        errors?.entity_have_links_with_altio_data?.[index]?.relation_with_entity
                          ?.message
                      }
                    />
                  )}
                />
                {index !== 0 && (
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => {
                      entity_have_links_with_altio_dataRemove(index)
                    }}
                  >
                    <RemoveCircleIcon fontSize="medium" />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </div>
        )
      })}
      {entity_have_links_with_altio_dataFields.length < 4 && (
        <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => {
              entity_have_links_with_altio_dataAppend({
                name_or_social_denomination: '',
                relation_with_entity: ''
              })
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar persona
          </Button>
        </Grid>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información financiera
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ingresos anuales (RD$/US$)"
                      variant="standard"
                      error={!!errors.annual_income}
                      autoComplete="off"
                      helperText={errors.annual_income && errors.annual_income.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="annual_expenses"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Obligaciones Financieras (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.annual_expenses}
                      helperText={errors.annual_expenses && errors.annual_expenses.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_assets"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total activos (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.total_assets}
                      helperText={errors.total_assets && errors.total_assets.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_liabilities"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total pasivos (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.total_liabilities}
                      helperText={errors.total_liabilities && errors.total_liabilities.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="subscribed_paid_Capital"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Capital suscrito y pagado (RD$/US$)"
                      variant="standard"
                      autoComplete="off"
                      error={!!errors.subscribed_paid_Capital}
                      helperText={
                        errors.subscribed_paid_Capital && errors.subscribed_paid_Capital.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_employees"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.total_employees}
                getOptionLabel={(option: any) => option || ''}
                isOptionEqualToValue={(option: any, value: any) => option === value}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total empleados"
                      variant="standard"
                      error={!!errors.total_employees}
                      helperText={errors.total_employees && errors.total_employees.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="average_annual_amount"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_initial_investment_amount}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Monto de Inversión Inicial (RD$/US$)"
                      variant="standard"
                      error={!!errors.average_annual_amount}
                      helperText={
                        errors.average_annual_amount && errors.average_annual_amount.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allDataJ && allDataJ.al_ta_total_heritage}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio total"
                      variant="standard"
                      error={!!errors.total_heritage}
                      helperText={errors.total_heritage && errors.total_heritage.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_goals"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue)
                }}
                options={allData.al_ta_investment_goals}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                //@ts-ignore
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'investment_goals'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Propósito de la inversión"
                      variant="standard"
                      error={!!errors.investment_goals}
                      helperText={errors.investment_goals && errors.investment_goals.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="main_sources_funds"
            label="Indicar las principales fuentes de los fondos de los aportes a invertir"
            value={data.body?.fields?.main_sources_funds && data.body?.fields?.main_sources_funds}
          />
          <Controller
            control={control}
            name="main_sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue)
                }}
                options={datos.al_ta_main_sources_funds}
                getOptionLabel={(option: any) => option || ''}
                isOptionEqualToValue={(option: any, value: any) => option === value}
                //@ts-ignore
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'main_sources_funds3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Indicar las principales fuentes de los fondos de los aportes a invertir"
                      variant="standard"
                      error={!!errors.main_sources_funds}
                      helperText={errors.main_sources_funds && errors.main_sources_funds.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales clientes de la entidad
      </TitleForm>
      {JSON.parse(data.body?.fields?.main_clients_entity).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={3} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_clients_entity.${index}.social_name`}
                  label="Nombres / Denominación social"
                  value={field.social_name}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_clients_entity.${index}.identification_number`}
                  label="Número de Identificación"
                  value={field.identification_number}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_clients_entity.${index}.economic_sector`}
                  label="Sector Económico"
                  value={field.economic_sector.description}
                />
              </Grid>
              {field.economic_sector?.id === '15' && (
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`main_clients_entity.${index}.other_economic_sector`}
                    label="Otro sector económico"
                    value={field.other_economic_sector}
                  />
                </Grid>
              )}
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_clients_entity.${index}.contact`}
                  label="Contacto"
                  value={field.contact}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
    </form>
  )
}

export default EconomicActivity
