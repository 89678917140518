import { ChangeEvent, useState, useEffect } from 'react'
import { IconButton, InputAdornment, StandardTextFieldProps, TextField } from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

interface validationTypes {
  validate: () => boolean
  msg: string
}

interface FancyInputTypes extends StandardTextFieldProps {
  validation?: validationTypes[]
  customErrorPass?: boolean
}

const FancyInput = ({
  label,
  name,
  value,
  variant,
  required,
  validation,
  type,
  error,
  customErrorPass,
  onChange,
  ...rest
}: FancyInputTypes) => {
  const [touched, setTouched] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const isNumber = () => type === 'number'
  // const isEmail = () => type === 'email'

  const isEmptyString = (str: string) => {
    return str === ''
  }

  function isValidEmail(email: string) {
    return /\S+@\S+\.\S+/.test(email)
  }

  const stringIsOnlyDigits = (str: string) => {
    return /^[0-9]+$/.test(str)
  }

  const onChangeCustom = (e: ChangeEvent<HTMLInputElement>) => {
    setTouched(true)
    if (isNumber() && !isEmptyString(e.target.value) && !stringIsOnlyDigits(e.target.value)) return
    if (onChange) onChange(e)
  }

  const getMessageError = () => {
    if (validation) {
      for (const v of validation) {
        if (!v.validate()) return v.msg
      }
      return ''
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  //const requiredCondition = /* touched && */ !value && required
  const requiredCondition = touched && !value && required
  const showCustomError = touched && validation && !!getMessageError()
  //const requiredCondition = touched || (!value && required)
  //const showCustomError = touched || (validation && !!getMessageError())
  const hasError = (requiredCondition && error) || showCustomError
  const errorPass = touched && customErrorPass && typeof value === 'string' && value.length < 5
  //console.log('errorPass: ', errorPass)

  return (
    <>
      {type !== 'password' ? (
        <TextField
          fullWidth
          error={hasError}
          helperText={
            requiredCondition || error
              ? 'Campo Requerido'
              : showCustomError
              ? getMessageError()
              : ''
          }
          variant={variant ? variant : 'standard'}
          name={name}
          label={label}
          value={value}
          type={type ?? 'text'}
          required={required ?? false}
          onChange={onChangeCustom}
          {...rest}
        />
      ) : (
        <TextField
          fullWidth
          error={hasError}
          helperText={
            requiredCondition || error
              ? 'Campo Requerido'
              : //: error && typeof value === 'string' && value.length < 5
              //showCustomError /* && typeof value === 'string' && value.length < 5 */
              errorPass
              ? 'Mínimo 6 caracteres'
              : //getMessageError()
                ''
          }
          variant={variant ? variant : 'standard'}
          name={name}
          label={label}
          value={value}
          data-error={error}
          data-showCustomError={showCustomError}
          data-errorPass={errorPass}
          data-ana={typeof value === 'string' && value.length}
          required={required ?? false}
          type={showPassword ? 'text' : 'password'}
          onChange={onChangeCustom}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  sx={{ backgroundColor: 'transparent' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            inputProps: {
              min: 6
            }
          }}
          {...rest}
        />
      )}
    </>
  )
}

export default FancyInput
