const styles = {
  width: '100%',
  height: '85vh',
  minWidth: '450px',
  border: 'none'
}

const ApiUsers = () => {
  return (
    <div style={{ overflowX: 'auto' }}>
      <iframe
        title="api-users"
        src={process.env.REACT_APP_API_USER + 'swagger-ui/#/'}
        style={styles}
      ></iframe>
    </div>
  )
}

export default ApiUsers
