import { useParams, Outlet, useNavigate } from 'react-router-dom'
import { useEffect, useLayoutEffect, useState } from 'react'
import useClient from '../hooks/useClient'
import Progress from '../components/Progress'
import { Paper, Typography } from '@mui/material'

const DashboardClientProfile = () => {
  const navigate = useNavigate()
  const { data, loadData, loadedData, loadTypeProfile } = useClient()
  const { id } = useParams()

  useLayoutEffect(() => {
    if (data === undefined) {
      loadData && loadData(id)
      loadTypeProfile && loadTypeProfile(id)
    }
  }, [])

  return data && data ? (
    data.status === 200 ? (
      <Paper sx={{ width: '100%', overflow: 'hidden', p: 3, pb: 4 }}>
        <Outlet />
      </Paper>
    ) : (
      <Typography>Usuario no ha guardado perfil</Typography>
    )
  ) : (
    <Progress />
  )
}

export default DashboardClientProfile
