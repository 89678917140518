import { ReactNode, useContext, useState } from 'react'
import useClient from '../hooks/useClient'
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  styled,
  Typography,
  Container,
  Hidden,
  Collapse
} from '@mui/material'
import {
  Home,
  Person,
  Logout,
  Settings,
  ManageAccounts,
  MenuBook,
  ExpandMore,
  ExpandLess,
  PermDataSetting,
  Circle
} from '@mui/icons-material'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied'
import { AltioLogo } from '../iconsSvg/AltioSvgIcons'
import { grey } from '@mui/material/colors'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import UserDataContext from '../context/UserDataContext'

const IconWithLink = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  background: 'transparent!important',
  '& svg': {
    transition: 'all 0.2s linear'
  },
  '&:hover svg': {
    transform: 'translateY(-2px)',
    filter: 'drop-shadow(1px 1px 3px rgb(0 0 0 / 0.3))'
  }
}))

const AvatarLink = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  background:
    'linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)',
  '&:hover': {
    background:
      'linear-gradient(to bottom, rgba(53,165,68,1) 0%, rgba(98,188,68,1)61%, rgba(99,188,68,1) 62%, rgba(138,196,63,1) 100%)'
  },
  [theme.breakpoints.down('sm')]: {
    width: 30,
    height: 30
  }
}))

const NavBarStyled = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: theme.palette.common.white,
  boxShadow: `0px 5px 10px 0px ${grey[400]}`,
  zIndex: 100
}))

const subMenuItems = [
  { label: 'Auditoría', url: '/api-audit' },
  { label: 'Mantenimiento', url: '/api-maintenance' },
  { label: 'Perfil del Inversionista', url: '/api-investment' },
  { label: 'Usuarios', url: '/api-users' },
  { label: 'Notificaciones', url: '/api-notification' }
]

const subMenuItemsRubros = [
  { label: 'Persona Física', url: '/physical-person' },
  { label: 'Persona Jurídica', url: '/legal-person' }
]

const Dashboard = ({ children }: { children: ReactNode }) => {
  const { setLoadedData, setData, setInvestmentProfile, setActiveStep } = useClient()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isSettingsMenu, setIsSettingsMenu] = useState<boolean>(false)
  const [collapseApiItem, setCollapseApiItem] = useState(false)
  const [collapseRubro, setCollapseRubro] = useState(false)
  const { dataUser, setDataUser } = useContext(UserDataContext)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()

  const handleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseMenu = () => {
    setAnchorEl(null)
  }
  const [, , removeCookie] = useCookies(['altio_auth', 'a_user'])

  const closeSession = () => {
    removeCookie('altio_auth')
    removeCookie('a_user')
    localStorage.removeItem('allDataTables')
    localStorage.removeItem('addressData')
    localStorage.removeItem('allDataTablesJ')
    localStorage.removeItem('allDataTablesF')
    setDataUser(undefined)
    navigate('/')
  }

  const removeData = async () => {
    setData && setData([])
    setInvestmentProfile && setInvestmentProfile([])
    setLoadedData && setLoadedData(false)
    setActiveStep && setActiveStep(0)
  }

  const returnHome = () => {
    removeData()
    navigate('/dashboard-client')
  }

  return (
    <Box sx={{ backgroundColor: '#f8f5f3', minHeight: '100vh', pb: 4 }}>
      <NavBarStyled>
        <Container maxWidth="xl" sx={{ maxWidth: { lg: '1360px' } }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <IconButton onClick={returnHome} disableRipple>
              <AltioLogo />
            </IconButton>
            <Hidden smDown>
              <IconWithLink aria-label="home" onClick={returnHome} disableRipple>
                <Home color="secondary" sx={{ fontSize: { xs: '30px', sm: '40px' } }} />
                <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>Inicio</Typography>
              </IconWithLink>
            </Hidden>
            <Stack direction={'row'}>
              {dataUser.userRole !== 'Lector' && (
                <IconButton
                  onClick={(e) => {
                    setIsSettingsMenu(true)
                    handleMenu(e)
                  }}
                  sx={{ pr: { xs: 0, sm: 1 } }}
                  disableRipple
                >
                  <AvatarLink>
                    <Settings sx={{ fontSize: { xs: '22px', sm: '30px' } }} />
                  </AvatarLink>
                </IconButton>
              )}
              <IconButton
                onClick={(e) => {
                  setIsSettingsMenu(false)
                  handleMenu(e)
                }}
                disableRipple
              >
                <AvatarLink>
                  <Person sx={{ fontSize: { xs: '22px', sm: '30px' } }} />
                </AvatarLink>
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {isSettingsMenu ? (
                <Box sx={{ minWidth: 180 }}>
                  <MenuItem onClick={returnHome}>
                    <ListItemIcon>
                      <SensorOccupiedIcon fontSize="small" />
                    </ListItemIcon>
                    Clientes
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setCollapseRubro((prevState) => !prevState)
                    }}
                  >
                    <ListItemIcon>
                      <AutoAwesomeMotionIcon fontSize="small" />
                    </ListItemIcon>
                    Rubros
                    <Box sx={{ ml: 'auto', mt: 0.5 }}>
                      {collapseRubro ? <ExpandLess /> : <ExpandMore />}
                    </Box>
                  </MenuItem>
                  <Collapse in={collapseRubro} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
                    {subMenuItemsRubros.map((item, i) => (
                      <MenuItem
                        key={i}
                        onClick={() => {
                          navigate(item.url)
                          setAnchorEl(null)
                          setCollapseRubro(false)
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: '16px!important' }}>
                          <Circle sx={{ fontSize: 8 }} />
                        </ListItemIcon>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Collapse>
                  {dataUser.userRole === 'Administrador' && (
                    <>
                      <MenuItem
                        onClick={() => {
                          navigate('/userManage')
                          setAnchorEl(null)
                        }}
                      >
                        <ListItemIcon>
                          <ManageAccounts fontSize="small" />
                        </ListItemIcon>
                        Usuarios
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setCollapseApiItem((prevState) => !prevState)
                        }}
                      >
                        <ListItemIcon>
                          <MenuBook fontSize="small" />
                        </ListItemIcon>
                        API
                        <Box sx={{ ml: 'auto', mt: 0.5 }}>
                          {collapseApiItem ? <ExpandLess /> : <ExpandMore />}
                        </Box>
                      </MenuItem>
                      <Collapse in={collapseApiItem} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
                        {subMenuItems.map((item, i) => (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              navigate(item.url)
                              setAnchorEl(null)
                              setCollapseApiItem(false)
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: '16px!important' }}>
                              <Circle sx={{ fontSize: 8 }} />
                            </ListItemIcon>
                            {item.label}
                          </MenuItem>
                        ))}
                      </Collapse>
                    </>
                  )}
                  <MenuItem
                    onClick={() => window.open(`${process.env.REACT_APP_RISK_ENGINE}`, '_blank')}
                  >
                    <ListItemIcon>
                      <PermDataSetting fontSize="small" />
                    </ListItemIcon>
                    Risk Engine
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate('/metaMap')
                      setAnchorEl(null)
                    }}
                  >
                    <ListItemIcon>
                      <ManageAccounts fontSize="small" />
                    </ListItemIcon>
                    Verificación en listas
                  </MenuItem>
                </Box>
              ) : (
                <MenuItem onClick={closeSession}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Cerrar sesión
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Container>
      </NavBarStyled>
      <Container maxWidth="xl" sx={{ paddingTop: 15, maxWidth: { lg: '1360px' } }}>
        {children}
      </Container>
    </Box>
  )
}

export default Dashboard
