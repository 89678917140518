import axios from 'axios'
import { CONSTANT } from '../utils/Constants'

const apiUser = process.env.REACT_APP_API_USER
const apiMaintenance = process.env.REACT_APP_API_MAINTENANCE
const apiInvestmentprofile = process.env.REACT_APP_API_MAINTENANCE_INVESMENTPROFILE
const apiDocuments = process.env.REACT_APP_API_DOCUMENTS

const apis = {
  investmentprofile: {
    QuotasSuscForm: `${apiInvestmentprofile}documents/pdf/suscription-quotas-form/`,
    QuotasSuscFrameworkContract: `${apiInvestmentprofile}documents/pdf/suscription-quotas-contract/`,
    bill: `${apiInvestmentprofile}documents/pdf/bill/`
  }
}

//https://altio-dispatcher.bitcode-enterprise.dev/v1/investmentprofile/documents/pdf/bill/5/USD/50

export const getQuotaSubscriptionForm = async (id: string, currency: string) => {
  await fetch(`${apis.investmentprofile.QuotasSuscForm}${id}/${currency}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Formulario-de-Suscripción-de-Cuotas.pdf'
      alink.click()
    })
  })
}

export const getQuotaSubscriptionFrameworkContract = async (id: string, data: any) => {
  await fetch(`${apis.investmentprofile.QuotasSuscFrameworkContract + id}`, {
    method: 'POST',
    headers: CONSTANT.HEADER_TYPE_PDF,
    body: JSON.stringify(data)
  }).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Contrato-Marco-de-Suscripción-de-Cuotas.pdf'
      alink.click()
    })
  })
}

export const getBill = async (id: string, data: any) => {
  await fetch(
    `${apis.investmentprofile.bill}/${id}/${data.currency}/${data.total_managed_fund}`
  ).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Minuta.pdf'
      alink.click()
    })
  })
}
