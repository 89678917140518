import {
  Grid,
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Container,
  useTheme,
  Link,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useState, useEffect, SyntheticEvent } from 'react'
import FancyInput from '../components/FancyInput'
import { useNavigate, useParams } from 'react-router-dom'
import { getChangePassword } from '../services/Services'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

interface forgotPassInitTypes {
  id?: string
  password: string
  repeatPassword: string
}

const ForgotPassword = () => {
  const { code, username } = useParams()
  const [apiResponse, setApiResponse] = useState<any>({ status: 0, statusText: '', body: '' })

  const [password1, setPassword1] = useState<string>('')
  const [errorPassword1, setErrorPassword1] = useState<string>('')
  const [password2, setPassword2] = useState<string>('')
  /*  const [forgotPassData, setForgotDataPass] = useState<forgotPassInitTypes>({
    id: code,
    password: password2
  }) */
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(undefined)
  const navigate = useNavigate()
  const theme = useTheme()

  const validationRegister = Yup.object().shape({
    password: Yup.string()
      .min(6, 'Contraseña debe tener al menos 6 caracteres')
      .test(
        'password',
        `Formato no es correcto, debe contener al menos 1 mayúscula, 1 dígito y máximo 10 caracteres`,
        (value: any) => value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,10}$/)?.length > 0
        //value.match(/^(?=(?:.*\d){2})(?=(?:.*[A-Z]){2})(?=(?:.*[a-z]){2})\S{6,20}$/)?.length > 0
      ),
    repeatPassword: Yup.string()
      .oneOf([Yup.ref('password'), ''], 'Contraseñas no coinciden')
      .required('Repetir contraseña es requerido')
  })

  const formOptions = { resolver: yupResolver(validationRegister) }
  const { register, handleSubmit, formState, control } = useForm<forgotPassInitTypes>(formOptions)
  const { errors } = formState

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const onSubmit = async (data: any) => {
    const resquest = {
      id: code,
      password: data.password
    }
    setLoading(true)
    setResponse(undefined)
    const nuevaData = await getChangePassword(resquest)
    setResponse(nuevaData)
    setLoading(false)
  }
  return (
    <Grid container spacing={0} sx={{ backgroundColor: '#f8f5f3', minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Stack justifyContent={'center'} alignItems={'center'} minHeight={'100vh'}>
            <img src="/logos/altio.svg" alt="ALTIO" width={250} style={{ marginBottom: 20 }} />
            <Box
              component={'form'}
              onSubmit={onSubmit}
              width={'100%'}
              maxWidth={450}
              sx={{
                background: '#fff',
                borderRadius: '25px',
                padding: theme.spacing(3),
                mb: theme.spacing(4),
                boxShadow:
                  '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
              }}
            >
              <Typography variant="h5" color="primary" align="center" sx={{ mb: 2 }}>
                Cambiar contraseña
              </Typography>
              <Controller
                name={'password'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    label="Contraseña"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    autoComplete="password"
                    {...register('password')}
                    error={!!errors.password}
                    helperText={!!errors.password && errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              <Controller
                name={'repeatPassword'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Confirmar Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    autoComplete="repeatPassword"
                    {...register('repeatPassword')}
                    error={errors.repeatPassword && Boolean(errors.repeatPassword)}
                    helperText={errors.repeatPassword && errors.repeatPassword.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
              {response && (
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ margin: `${theme.spacing(2)} 0` }}
                >
                  {response.estatus === 400 ? response.body.fieldErrors[0] : response.body.info}
                </Typography>
              )}
              <Stack justifyContent={'center'} direction={'row'} sx={{ mt: 2 }}>
                <Button variant="contained" fullWidth onClick={handleSubmit(onSubmit)}>
                  {loading && (
                    <CircularProgress size={24} sx={{ marginRight: '5px', color: '#6fc555' }} />
                  )}
                  Cambiar Contraseña
                </Button>
              </Stack>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Link sx={{ color: '#00000080', textDecorationColor: '#00000080', mt: 1 }} href="/">
                  Regresar a Login
                </Link>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

export default ForgotPassword
