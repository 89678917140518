import { Alert, AlertColor, Snackbar, SnackbarProps } from '@mui/material'

interface SnackbarCustomTypes extends SnackbarProps {
  type: AlertColor | undefined
  message: string
  onClose: any
}

const SnackbarCustom = ({ open, onClose, type, message }: SnackbarCustomTypes) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Alert
        onClose={onClose}
        severity={type}
        variant="filled"
        elevation={10}
        sx={{ width: '100%', fontSize: 18 }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default SnackbarCustom
