import { useLocation } from 'react-router-dom'
import AppRoutes from './routes/AppRoutes'
import { ClientProvider } from './context/ClientProvider'
import DashboardLayout from './layouts/DashboardLayout'
import './resources/custom.css'

function App() {
  let { pathname } = useLocation()
  return pathname === '/' ||
    pathname.includes('/active-register') ||
    pathname.includes('/forgot-password') ||
    pathname.includes('/recover-password') ? (
    <AppRoutes />
  ) : (
    <ClientProvider>
      <DashboardLayout>
        <AppRoutes />
      </DashboardLayout>
    </ClientProvider>
  )
}

export default App
