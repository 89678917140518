import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Divider,
  FormControl,
  Box,
  Chip,
  Select,
  InputLabel,
  styled
} from '@mui/material'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'

const LabelForm = styled(InputLabel)(({ theme }) => ({
  left: '-14px'
}))

const LegalRepresentativeInformation = () => {
  const { data, allData, allDataJ, address } = useClient()

  return (
    <div>
      <TitleForm sx={{ mb: 4 }}>Perfil del inversionista</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="ability_absorb_losses"
            label="Capacidad de absorber pérdidas de capital"
            value={
              allDataJ &&
              allDataJ.al_ta_ability_absorb_losses.find(
                (f: any) => f.id === Number(data.body?.fields?.ability_absorb_losses)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="risk_tolerance"
            label="Tolerancia al Riesgo"
            value={
              allDataJ &&
              allDataJ.al_ta_risk_tolerance.find(
                (f: any) => f.id === Number(data.body?.fields?.risk_tolerance)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="desired_profitability"
            label="Rentabilidad Deseada"
            value={
              allDataJ &&
              allDataJ.al_ta_desired_profitability.find(
                (f: any) => f.id === Number(data.body?.fields?.desired_profitability)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="investment_time_horizon"
            label="Horizonte de Tiempo de la Inversión"
            value={
              allDataJ &&
              allDataJ.al_ta_investment_time_horizon.find(
                (f: any) => f.id === Number(data.body?.fields?.investment_time_horizon)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="channel_catchment_client"
            label="Canal de Captacion del Cliente"
            value={
              allData &&
              allData.al_ta_channel_catchment_client.find(
                (f: any) => f.id === Number(data.body?.fields?.channel_catchment_client)
              )?.description
            }
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Productos Utilizados o Deseados
      </TitleForm>
      {/* <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox checked={data.body?.fields?.notHaveExperience} />}
            label="No poseo experiencia en ninguno de estos productos"
            disabled={true}
          />
        </Grid>
      </Grid> */}
      {data.body?.fields?.notHaveExperience !== 'true' && (
        <>
          <Grid container spacing={4} sx={{ mb: 2 }}>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth disabled={true}>
                <LabelForm>Producto de renta fija</LabelForm>
                <Select
                  variant="standard"
                  value={
                    data.body?.fields?.fixedIncomeSecurities
                      ? JSON.parse(data.body.fields.fixedIncomeSecurities)
                      : []
                  }
                  multiple
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.fixedIncomeSecurities.find((item: any) => item.id === value)
                              .description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: '2px' }}
                        />
                      ))}
                    </Box>
                  )}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth disabled={true}>
                <LabelForm>Fondos de Inversión</LabelForm>
                <Select
                  variant="standard"
                  value={
                    data.body?.fields?.investmentFunds
                      ? JSON.parse(data.body.fields.investmentFunds)
                      : []
                  }
                  multiple
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.investmentFunds.find((item: any) => item.id === value).description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: '2px' }}
                        />
                      ))}
                    </Box>
                  )}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth disabled={true}>
                <LabelForm>Operaciones de Liquidez</LabelForm>
                <Select
                  variant="standard"
                  value={
                    data.body?.fields?.liquidityOperations
                      ? JSON.parse(data.body.fields.liquidityOperations)
                      : []
                  }
                  multiple
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.liquidityOperations.find((item: any) => item.id === value)
                              .description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: '2px' }}
                        />
                      ))}
                    </Box>
                  )}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth disabled={true}>
                <LabelForm>Valores de renta Variable</LabelForm>
                <Select
                  variant="standard"
                  value={
                    data.body?.fields?.variableIncomeSecurities
                      ? JSON.parse(data.body.fields.variableIncomeSecurities)
                      : []
                  }
                  multiple
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.variableIncomeSecurities.find((item: any) => item.id === value)
                              ?.description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: '2px' }}
                        />
                      ))}
                    </Box>
                  )}
                ></Select>
              </FormControl>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormControl fullWidth disabled={true}>
                <LabelForm>Otros Servicios</LabelForm>
                <Select
                  variant="standard"
                  value={
                    data.body?.fields?.otherServices
                      ? JSON.parse(data.body.fields.otherServices)
                      : []
                  }
                  multiple
                  renderValue={(selected: any) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value: any) => (
                        <Chip
                          key={value}
                          label={
                            datos.otherServices.find((item: any) => item.id === value)?.description
                          }
                          color="primary"
                          size="small"
                          sx={{ mb: '2px' }}
                        />
                      ))}
                    </Box>
                  )}
                ></Select>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Conocimiento General del Mercado
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="knowledge_market_values"
            label="Nivel de Conocimiento"
            value={
              allDataJ &&
              allDataJ.al_ta_knowledge_market_values.find(
                (f: any) => f.id === Number(data.body?.fields?.knowledge_market_values)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="information_media"
            label="Medios de Información utilizados sobre los mercados Financieros"
            value={
              allDataJ &&
              allDataJ.al_ta_information_media.find(
                (f: any) => f.id === Number(data.body?.fields?.information_media)
              )?.description
            }
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="professionalInvestor"
            label="Se considera Inversionista Profesional"
            value={
              allDataJ &&
              allDataJ.al_ta_professional_investor.find(
                (f: any) => f.id === Number(data.body?.fields?.professionalInvestor)
              )?.description
            }
          />
        </Grid>
        {data.body?.fields?.professionalInvestor === '4' && (
          <>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mb: 2 }}
                disabled={true}
                control={
                  <Checkbox
                    //@ts-ignore
                    checked={data.body?.fields?.expcStockMarketLeastThree}
                  />
                }
                label="Cuenta con experiencia, comprobable mediante la realización de operaciones en el mercado de valores nacional o extranjero, con un volumen de al menos tres millones de pesos dominicanos (DOP 3,000,000) o su equivalente en cualquier otra moneda por año, con una frecuencia media de más de tres (3) operaciones por trimestre en el año previo a su clasificación como cliente profesional"
              />
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mb: 2 }}
                disabled={true}
                control={
                  <Checkbox
                    //@ts-ignore
                    checked={data.body?.fields?.equityCapacityLeastTwentyFive}
                  />
                }
                label="Cuenta con Capacidad patrimonial, comprobable mediante la determinación de que sus activos en efectivo o inversiones en instrumentos financieros sean de al menos veinticinco millones de pesos dominicanos (DOP 25,000,000) o su equivalente en cualquier otra moneda, depositados en una entidad de intermediación financiera o inversiones locales o extranjeras"
              />
              <FormControlLabel
                sx={{ alignItems: 'flex-start', mb: 2 }}
                disabled={true}
                control={
                  <Checkbox
                    //@ts-ignore
                    checked={data.body?.fields?.knowledgeVerified}
                  />
                }
                label="Cuenta con Conocimiento, verificado, al menos, uno de los siguientes aspectos: (i) que ha laborado en cargos de dirección en el área de negocios o áreas afines de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (ii) que es o ha sido miembro del consejo de administración de una entidad que opere en el sistema financiero, por un período de al menos dos (2) años, o; (iii) que cuenta con la certificación de corredor de valores vigente o su equivalente de otro país, al momento de su calificación; o, (iv) Que tenga o haya tenido un cargo de dirección o gerencial en el área de tesorería, inversiones, riesgos, finanzas o contraloría por un periodo de al menos dos (2) años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (v) Que sea o haya sido miembro del consejo de administración por un periodo de al menos dos años, en una entidad calificada en el mercado de valores como inversionista profesional o institucional, en el país o en el extranjero, o; (vi) Que haya laborado en cargos de dirección o gerencial en entidades financieras extranjeras u organismos multilaterales de los cuales la República Dominicana sea miembro por un período de al menos dos (2) años, o; (vii) Que sea o haya sido funcionario las Superintendencias del Mercado de Valores, de Bancos, de Seguros o de Pensiones, del Banco Central de la República Dominicana o de la Junta Monetaria"
              />
            </Grid>
          </>
        )}
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Instrumentos financieros negociados en últimos 2 años
      </TitleForm>
      {data.body?.fields?.financialInstrumentsYear &&
        JSON.parse(data.body?.fields?.financialInstrumentsYear).map((field: any, index: number) => {
          return (
            <div key={index}>
              {index !== 0 && (
                <Divider
                  sx={{
                    pt: 3,
                    mb: 3,
                    borderColor: '#efe5df',
                    width: '50%',
                    ml: 'auto',
                    mr: 'auto'
                  }}
                />
              )}
              <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`financialInstrumentsYear.${index}.financialInstruments`}
                    label="Instrumentos"
                    value={field.financialInstruments.description}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`financialInstrumentsYear.${index}.experienceTime`}
                    label="Tiempo de experiencia"
                    value={field.experienceTime.description}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`financialInstrumentsYear.${index}.numTransactionsYear`}
                    label="Cantidad de Operaciones al año"
                    value={field.numTransactionsYear.description}
                  />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`financialInstrumentsYear.${index}.levelknowInstRisk`}
                    label="Nivel de conocimiento Instrumento y Riesgo"
                    value={field.levelknowInstRisk.description}
                  />
                </Grid>
              </Grid>
            </div>
          )
        })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {data.body?.fields?.liquid_assets_distribution_form &&
        JSON.parse(data.body?.fields?.liquid_assets_distribution_form).map(
          (field: any, index: number) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 3,
                      borderColor: '#efe5df',
                      width: '50%',
                      ml: 'auto',
                      mr: 'auto'
                    }}
                  />
                )}
                <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <FancyInput
                      required
                      type={'text'}
                      disabled={true}
                      name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                      label="Seleccione Activo Líquido"
                      value={field.liquid_assets_distribution_id.description}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FancyInput
                      required
                      type={'text'}
                      disabled={true}
                      name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_description`}
                      label="Valor Activo Líquido"
                      value={field.liquid_assets_distribution_description.description}
                    />
                  </Grid>
                </Grid>
              </div>
            )
          }
        )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Tarjeta de Firmas
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="social_name_signature"
            label="Nombre o Denominación Social"
            value={
              data.body?.fields?.social_name_signature && data.body?.fields?.social_name_signature
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="instructions_signature"
            label="Instrucciones de firmas"
            value={
              data.body?.fields?.instructions_signature && data.body?.fields?.instructions_signature
            }
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Firmas Autorizadas
      </TitleForm>
      {JSON.parse(data.body?.fields?.authorized_signatures).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`authorized_signatures.${index}.name`}
                  label="Nombres y Apellidos"
                  value={field.name}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`authorized_signatures.${index}.document_number`}
                  label="Número de documento de identidad"
                  value={field.document_number}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`authorized_signatures.${index}.function`}
                  label="Función"
                  value={field.function}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
    </div>
  )
}

export default LegalRepresentativeInformation
