import { Navigate } from 'react-router-dom'
import { useContext } from 'react'
import UserDataContext from '../context/UserDataContext'

const PrivateRoute = ({ children }: any) => {
  const { dataUser } = useContext(UserDataContext)
  if (dataUser) {
    return <Navigate to={'/dashboard-client'} replace />
  }
  return children
}

export default PrivateRoute
