import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'

const Gradient = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  background:
    'linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)',
  '&:hover': {
    background:
      'linear-gradient(to bottom, rgba(53,165,68,1) 0%, rgba(98,188,68,1)61%, rgba(99,188,68,1) 62%, rgba(138,196,63,1) 100%)'
  }
}))

const ButtonGradient = ({ type, children, ...props }: any) => {
  return (
    <Gradient type={type} variant="contained" {...props}>
      {children}
    </Gradient>
  )
}

export default ButtonGradient
