import Tables, { TableHeadItem } from '../components/Tables'
import { useEffect, useLayoutEffect, useState } from 'react'
import useClient from '../hooks/useClient'
import { useNavigate } from 'react-router-dom'
import { TableCell, TableRow, Typography, Stack, IconButton, Button, Dialog } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import SearchIcon from '@mui/icons-material/Search'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { getProfileUsers } from '../services/Services'
import Progress from '../components/Progress'
import FancyInput from '../components/FancyInput'
import DownloadDocumentsModal from '../components/utils/DownloadDocumentsModal'

interface dataTables {
  tableName: string
  numberData: string
  modified: string
  endPointName: string
}

const DashboardClient = () => {
  const navigate = useNavigate()
  const {
    data,
    setData,
    loadData,
    loadedData,
    setLoadedData,
    investmentProfile,
    loadTypeProfile,
    getAllDataTables,
    getAddress
  } = useClient()
  const [apiResponse, setApiResponse] = useState<any>(undefined)
  const [uid, setUid] = useState<any>(undefined)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [tables, setTables] = useState<any>(undefined)
  const [inputSearch, setInputSearch] = useState<string>('')
  const [btnSort, setBtnSort] = useState<string>('')
  const [sortAsc, setSortAsc] = useState(true)
  const [btnSearch, setBtnSearch] = useState<string>('')
  const [modalOpen, setModalOpen] = useState(false)

  useLayoutEffect(() => {
    let isMounted = true
    setApiResponse(false)
    setBtnSort('')
    const asyncEffect = async () => {
      if (isMounted) {
        const getData =
          inputSearch === ''
            ? await getProfileUsers(`users?page=${page}&size=${rowsPerPage}`)
            : await getProfileUsers(
                `search/${encodeURI(inputSearch)}?page=${page}&size=${rowsPerPage}`
              )
        setApiResponse(getData)
        setTables(getData.body.content)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [page, rowsPerPage, btnSearch])

  const sortTable = async (allData: any, nameCol: string, order: boolean) => {
    setTables([])
    setBtnSort(nameCol)
    const sortedData = await allData.sort((a: any, b: any) => {
      if (b[nameCol] < a[nameCol]) {
        return order ? 1 : -1
      }
      if (b[nameCol] > a[nameCol]) {
        return order ? -1 : 1
      }
      return 0
    })
    setSortAsc(order)
    setTables(sortedData)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const navigateClient = async (id: string) => {
    setUid(id)
    loadData && loadData(id)
    loadTypeProfile && loadTypeProfile(id)
  }

  useLayoutEffect(() => {
    if (!localStorage.getItem('allDataTablesF') && !localStorage.getItem('allDataTablesJ')) {
      getAllDataTables && getAllDataTables()
    }
    if (!localStorage.getItem('addressData')) {
      getAddress && getAddress()
    }
  }, [])

  useEffect(() => {
    if (uid !== undefined && loadedData === true) {
      if (data?.body?.formType === 'F') {
        navigate(`/client/physical/${uid}`)
      } else {
        navigate(`/client/legal/${uid}`)
      }
    }
  }, [loadedData])

  const handleOpenModalDownloadDocuments = async (id: string) => {
    loadData && loadData(id)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setLoadedData && setLoadedData(false)
    setData && setData([])
  }

  return (
    <>
      {apiResponse ? (
        apiResponse.status === 200 ? (
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
              mb={2}
            >
              <Typography variant="h5" gutterBottom>
                Tabla de Clientes
              </Typography>
              <Stack direction={'row'} alignItems={'flex-end'}>
                <FancyInput
                  fullWidth={false}
                  variant="standard"
                  value={inputSearch}
                  label={'Buscar...'}
                  autoFocus
                  onChange={(e) => {
                    setInputSearch(e.target.value)
                    if (e.target.value === '') {
                      setBtnSearch(e.target.value)
                    }
                  }}
                  onKeyDown={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`)
                    if (ev.key === 'Enter') {
                      setBtnSearch(inputSearch)
                    }
                  }}
                />
                <IconButton aria-label="search" onClick={() => setBtnSearch(inputSearch)}>
                  <SearchIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Tables
              hiddenPagination={btnSearch !== ''}
              count={
                apiResponse.body.totalElements
                  ? apiResponse.body.totalElements
                  : apiResponse.body.content?.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowHead={['firsname', 'username', 'formtype', 'stepForm', 'Acciones'].map(
                (item, i) => (
                  <TableHeadItem
                    key={i}
                    ml={i !== 0 ? 'auto' : 0}
                    sx={{ pr: item === btnSort ? 0 : 3, cursor: 'pointer', userSelect: 'none' }}
                    name={item}
                    arrowsHidden={item !== btnSort}
                    modeSort={sortAsc}
                    onClick={() => sortTable(tables, item, btnSort === item ? !sortAsc : sortAsc)}
                  />
                )
              )}
              alignTable="left"
            >
              {tables !== null && tables.length > 0 ? (
                tables.map((item: any, i: any) => (
                  <TableRow
                    hover
                    //tabIndex={-1}
                    key={i}
                    //onClick={() => navigateClient(item.id)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  >
                    <TableCell align="left" onClick={() => navigateClient(item.id)}>
                      {item.firstname} {''}
                      {item.lastname}
                    </TableCell>
                    <TableCell align="right" onClick={() => navigateClient(item.id)}>
                      {item.username}
                    </TableCell>
                    <TableCell align="right" onClick={() => navigateClient(item.id)}>
                      {item.formtype === 'F' ? 'Físico' : 'Jurídico'}
                    </TableCell>
                    <TableCell align="right" onClick={() => navigateClient(item.id)}>
                      {item.stepForm}
                    </TableCell>
                    <TableCell align="center">
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleOpenModalDownloadDocuments(item.id)}
                      >
                        Documentos
                      </Button> */}
                      {/*   <IconButton
                        aria-label="download documents"
                        color="primary"
                        onClick={() => navigateClient(item.id)}
                      >
                        <VisibilityIcon />
                      </IconButton> */}
                      <IconButton
                        aria-label="download documents"
                        color="primary"
                        onClick={() => handleOpenModalDownloadDocuments(item.id)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                >
                  <TableCell colSpan={3}>El elemento buscado no existe</TableCell>
                </TableRow>
              )}
            </Tables>
          </>
        ) : (
          <Typography variant="h5" color={'error'} align={'center'} my={10}>
            {apiResponse.status
              ? `Error ${apiResponse.status}: ${apiResponse.error}`
              : apiResponse.statusText}
          </Typography>
        )
      ) : (
        <Progress />
      )}
      <Dialog open={modalOpen} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DownloadDocumentsModal dataClient={data?.body} cancelModal={handleCloseModal} />
      </Dialog>
    </>
  )
}

export default DashboardClient
