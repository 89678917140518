import { Delete, Edit } from '@mui/icons-material'
import { IconButton, styled } from '@mui/material'
import { useContext } from 'react'
import UserDataContext from '../context/UserDataContext'

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  width: 30,
  height: 30,
  background: 'linear-gradient(to bottom, #39b34a 0%, #62bc44 51%, #63bc44 52%, #8ac43f 100%)',
  '&:hover': {
    background: 'linear-gradient(to bottom, #35a544 0%, #62bc44 61%, #63bc44 62%, #8ac43f 100%)'
  },
  marginRight: theme.spacing(1),
  '& .MuiSvgIcon-root': {
    color: theme.palette.common.white,
    fontSize: 18
  },
  '&.deleteIcon': {
    background: theme.palette.error.main
  }
}))

interface RowActionsTypes {
  actionEdit: React.MouseEventHandler<HTMLButtonElement>
  actionDelete: React.MouseEventHandler<HTMLButtonElement>
}

const RowActions = ({ actionEdit, actionDelete }: RowActionsTypes) => {
  const { dataUser } = useContext(UserDataContext)
  return (
    <>
      <IconButtonStyled onClick={actionEdit}>
        <Edit />
      </IconButtonStyled>
      {dataUser.userRole === 'Administrador' && (
        <IconButtonStyled className="deleteIcon" onClick={actionDelete}>
          <Delete />
        </IconButtonStyled>
      )}
    </>
  )
}

export default RowActions
