import { CssBaseline, ThemeProvider } from '@mui/material'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import theme from './theme/theme'
import { CookiesProvider } from 'react-cookie'
import { UserDataProvider } from './context/UserDataContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <CookiesProvider>
      <BrowserRouter>
        <UserDataProvider>
          <App />
        </UserDataProvider>
      </BrowserRouter>
    </CookiesProvider>
  </ThemeProvider>
)
