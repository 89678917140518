import { useParams } from 'react-router-dom'
import { useState, useLayoutEffect } from 'react'
import useClient from '../../hooks/useClient'
import {
  Container,
  Stack,
  Tooltip,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  MobileStepper,
  styled,
  useTheme,
  useMediaQuery,
  Button
} from '@mui/material'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import FeedIcon from '@mui/icons-material/Feed'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import SensorOccupiedIcon from '@mui/icons-material/SensorOccupied'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import BussinessIdentification from './BussinessIdentification'
import EconomicActivity from './EconomicActivity'
import AdditionalLegalInformation from './AdditionalLegalInformation'
import LegalRepresentativeInformation from './LegalRepresentativeInformation'
import InvestorTypeResults from './InvestorTypeResults'
import Headroom from 'react-headroom'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #14475294 100%)'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #14475294 100%)'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 60,
  height: 60,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'inherit',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, #39b34a 0%, #39b355 30%,  #39b37e 50%, #144752 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.35)'
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)'
  })
}))

const WrappMobileStepper = styled(MobileStepper)(({ theme }) => ({
  background:
    'linear-gradient(to bottom, rgba(57,179,74,1) 0%, rgba(98,188,68,1) 51%, rgba(99,188,68,1) 52%, rgba(138,196,63,1) 100%)',
  color: '#fff',
  fontFamily: 'Mulish'
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <AssignmentIndIcon sx={{ fontSize: 30 }} />,
    2: <CreditScoreIcon sx={{ fontSize: 30 }} />,
    3: <FeedIcon sx={{ fontSize: 30 }} />,
    4: <PeopleAltIcon sx={{ fontSize: 30 }} />,
    5: <SensorOccupiedIcon sx={{ fontSize: 30 }} />
  }

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={{ cursor: 'pointer' }}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}

interface StepsType {
  label: string
  component: JSX.Element
}

const steps: StepsType[] = [
  {
    label: 'Información General',
    component: <BussinessIdentification />
  },
  {
    label: 'Actividad económica de la empresa',
    component: <EconomicActivity />
  },
  {
    label: 'Información Adicional',
    component: <AdditionalLegalInformation />
  },
  {
    label: 'Perfil del Inversionista',
    component: <LegalRepresentativeInformation />
  },
  {
    label: 'Resultados del Inversionista',
    component: <InvestorTypeResults />
  }
]

const LegalClient = () => {
  const {
    activeStep,
    setActiveStep,
    data,
    loadTypeProfile,
    handleBack,
    handleNext,
    stepsComplete
  } = useClient()
  const theme = useTheme()
  const maxSteps = steps.length
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [apiResponse, setApiResponse] = useState<any>({ status: 0, statusText: '', body: '' })
  const { id } = useParams()

  const handleStep = (step: number) => () => {
    setActiveStep && setActiveStep(step)
  }

  return (
    <Container maxWidth="xl" sx={{ pt: 5, px: { xs: 0, sm: 2 } }}>
      <Stack sx={{ width: '100%' }} spacing={4}>
        {!isMobile ? (
          <Stepper
            alternativeLabel
            activeStep={activeStep}
            connector={<ColorlibConnector />}
            className={`${activeStep}`}
          >
            {steps.map((step, i) => (
              <Step key={i}>
                {stepsComplete && stepsComplete[i] !== true && activeStep !== steps.length - 2 ? (
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    title="Cliente no ha completado este paso"
                    placement="top"
                  >
                    <StepLabel StepIconComponent={ColorlibStepIcon} sx={{ cursor: 'pointer' }}>
                      {step.label}
                    </StepLabel>
                  </Tooltip>
                ) : (
                  <StepLabel
                    StepIconComponent={ColorlibStepIcon}
                    onClick={handleStep && handleStep(i)}
                    sx={{ cursor: 'pointer' }}
                  >
                    {step.label}
                  </StepLabel>
                )}
              </Step>
            ))}
          </Stepper>
        ) : (
          <Headroom downTolerance={10} pinStart={80}>
            <WrappMobileStepper
              variant="text"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext && handleNext}
                  disabled={
                    activeStep >= maxSteps - 1 ||
                    (stepsComplete &&
                      stepsComplete[activeStep + 1] !== true &&
                      activeStep !== steps.length - 2)
                  }
                  sx={{ color: '#fff' }}
                >
                  Continuar
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack && handleBack}
                  disabled={activeStep === 0}
                  sx={{ color: '#fff' }}
                >
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                  Regresar
                </Button>
              }
            />
          </Headroom>
        )}
        <>{steps[activeStep].component}</>
      </Stack>
      {!isMobile && (
        <Stack
          justifyContent="flex-end"
          alignItems="flex-end"
          direction="row"
          spacing={2}
          sx={{ mt: 2 }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={handleBack}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            disabled={activeStep === 0}
          >
            Regresar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            sx={{ width: { xs: '100%', sm: 'auto' } }}
            disabled={
              activeStep === steps.length - 1 ||
              (stepsComplete &&
                stepsComplete[activeStep + 1] !== true &&
                activeStep !== steps.length - 2)
            }
          >
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Continuar'}
          </Button>
        </Stack>
      )}
    </Container>
  )
}

export default LegalClient
