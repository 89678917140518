import { InputLabel, styled } from '@mui/material'

const InputLabelForm = styled(InputLabel)(({ theme }) => ({
  left: '-14px'
}))

const InputLabelToForm = (props: any) => {
  return <InputLabelForm {...props}>{props.children}</InputLabelForm>
}

export default InputLabelToForm
