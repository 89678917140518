import { useState, FC, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { activateUsers } from '../services/Services'
import Progress from '../components/Progress'

const ActiveRegister: FC = () => {
  const { code, username } = useParams()
  const [loading, setLoading] = useState(true)
  const [apiResponse, setApiResponse] = useState<any>({ status: 0, statusText: '', body: '' })

  useEffect(() => {
    let isMounted = true
    const asyncEffect = async () => {
      if (isMounted) {
        const getData = await activateUsers(code, username)
        setApiResponse(getData)
        setLoading(false)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Stack justifyContent={'space-between'} minHeight={'100vh'}>
      <Box mt={15}>
        <Stack alignItems={'center'}>
          <img src="/logos/altio.svg" alt="ALTIO" width={250} style={{ marginBottom: 20 }} />
        </Stack>
        {loading ? (
          <Progress />
        ) : (
          <Stack justifyContent="center" alignItems="center" p={2}>
            {apiResponse.status === 200 ? (
              <>
                <Typography variant="h5" align="center" color="primary" sx={{ mb: 5, mt: 10 }}>
                  ¡Ha activado su cuenta correctamente!
                </Typography>
                <Button variant="contained" component={Link} to="/">
                  Iniciar sesión
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant="h5"
                  color={'error'}
                  align={'center'}
                  my={10}
                  mx={'auto'}
                  maxWidth={700}
                >
                  ¡Ha ocurrido un error al activar tu usuario!
                </Typography>
                <Typography variant="body1">
                  error {apiResponse.status}: {apiResponse.statusText}
                </Typography>
              </>
            )}
          </Stack>
        )}
      </Box>
    </Stack>
  )
}

export default ActiveRegister
