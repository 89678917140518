import { useState, createContext, useLayoutEffect, Dispatch, SetStateAction } from 'react'
import {
  getProfile,
  getCalculateProfile,
  getAllData,
  getAddressData,
  getAddressSector
} from '../services/Services'

interface InvestmentProfileResults {
  userId: number
  result: {
    SCORE: string
    INVESTOR_TYPE: string
    INVESTOR_CATEGORY: string
  }[]
}

interface initialStateType {
  investmentProfile: any
  setInvestmentProfile?: Dispatch<SetStateAction<any>>
  data: any
  setData?: Dispatch<SetStateAction<any>>
  activeStep: number
  setActiveStep?: Dispatch<SetStateAction<number>>
  loadData?: (value: string | undefined) => void
  getAllDataTables?: () => void
  getAddress?: () => void
  getSectorAddress: (provinceID: string) => void
  loadTypeProfile?: (value: string | undefined) => void
  handleBack?: () => void
  handleNext?: () => void
  loadedData?: boolean
  setLoadedData?: Dispatch<SetStateAction<boolean>>
  stepsComplete?: any[]
  setStepsComplete?: Dispatch<SetStateAction<any[]>>
  allData?: any
  setAllData?: Dispatch<SetStateAction<any>>
  allDataJ?: any
  setAllDataJ?: Dispatch<SetStateAction<any>>
  address?: any
  setAddres?: Dispatch<SetStateAction<any>>
  loaderAllData?: boolean
}

const initialState = {
  investmentProfile: [],
  data: undefined,
  activeStep: 0,
  getSectorAddress: () => {}
}

const ClientContext = createContext<initialStateType>(initialState)

const ClientProvider = ({ children }: any) => {
  const [investmentProfile, setInvestmentProfile] = useState(undefined)
  const [data, setData] = useState<any>(undefined)
  const [loadedData, setLoadedData] = useState(false)
  const [activeStep, setActiveStep] = useState(initialState.activeStep)
  const [stepsComplete, setStepsComplete] = useState<any>([])

  const [allData, setAllData] = useState<any>(
    localStorage.getItem('allDataTablesF')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('allDataTablesF'))
      : []
  )
  const [allDataJ, setAllDataJ] = useState<any>(
    localStorage.getItem('allDataTablesJ') &&
      //@ts-ignore
      JSON.parse(localStorage.getItem('allDataTablesJ'))
  )
  const [address, setAddres] = useState(
    localStorage.getItem('addressData')
      ? //@ts-ignore
        JSON.parse(localStorage.getItem('addressData'))
      : {
          provinces: {},
          municipality: {},
          sector: {}
        }
  )

  const loadData = async (id: string | undefined) => {
    const getData: any = await getProfile(id)
    setData(getData)
    setLoadedData(true)
    setStepsComplete([
      !!getData.body.fields.step_one_complete,
      !!getData.body.fields.step_two_complete,
      !!getData.body.fields.step_three_complete,
      !!getData.body.fields.step_four_complete,
      !!getData.body.fields.all_steps_complete
    ])
  }

  const loadTypeProfile = async (id: string | undefined) => {
    const getData: any = await getCalculateProfile(id)
    setInvestmentProfile(getData)
  }

  const handleBack = () => {
    setActiveStep && setActiveStep((prevActiveStep: any) => prevActiveStep - 1)
  }

  const handleNext = () => {
    setActiveStep && setActiveStep((prevActiveStep: any) => prevActiveStep + 1)
  }

  const getAddress = async () => {
    const response = await getAddressData()
    if (response?.statusP === 200 && response?.statusM === 200 && response?.statusS === 200) {
      setAddres({
        ...address,
        provinces: response.provinces.content,
        municipality: response.municipality.content,
        sector: response.sector.content
      })
      localStorage.setItem(
        'addressData',
        JSON.stringify({
          provinces: response.provinces.content,
          municipality: response.municipality.content,
          sector: response.sector.content
        })
      )
    }
  }

  const getAllDataTables = async () => {
    const responseF = await getAllData('F')
    if (responseF?.status === 200) {
      setAllData(responseF.body)
      localStorage.setItem('allDataTablesF', JSON.stringify(responseF.body))
    }

    const responseJ = await getAllData('J')
    if (responseJ?.status === 200) {
      setAllDataJ(responseJ.body)
      localStorage.setItem('allDataTablesJ', JSON.stringify(responseJ.body))
    }
  }

  const getSectorAddress = async (provinceID: string) => {
    const response: any = await getAddressSector(provinceID)
    if (response) {
      return response.content
    }
  }

  const formContextValues: initialStateType = {
    investmentProfile,
    setInvestmentProfile,
    data,
    setData,
    loadedData,
    setLoadedData,
    setActiveStep,
    loadData,
    loadTypeProfile,
    activeStep,
    handleBack,
    handleNext,
    stepsComplete,
    getAllDataTables,
    getAddress,
    allData,
    address,
    allDataJ,
    getSectorAddress
  }

  return <ClientContext.Provider value={formContextValues}>{children}</ClientContext.Provider>
}

export { ClientProvider }

export default ClientContext
