import { useEffect } from 'react'
import { Button, Grid, TextField, InputLabel, FormControl, MenuItem, Select } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getBill } from '../../../services/Documents'

const Bill = ({ dataClient }: any) => {
  const defaultValues = {
    total_managed_fund: '',
    currency:
      dataClient?.fields?.refAddBankAccounts &&
      JSON.parse(dataClient.fields.refAddBankAccounts)[0]?.currency_preference?.id === '1'
        ? 'DOP'
        : 'USD'
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues,
    mode: 'onChange'
  })

  useEffect(() => {
    reset(defaultValues)
  }, [dataClient])

  const onSubmit = async (data: any) => {
    await getBill(dataClient?.userId, data)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Controller
          name={'total_managed_fund'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              label="Total de inversiones de fondos administrados"
              type="text"
              variant="standard"
              fullWidth
              value={value}
              {...register('total_managed_fund', { required: true })}
              error={errors.total_managed_fund && Boolean(errors.total_managed_fund)}
              helperText={errors.total_managed_fund && 'Campo es requerido'}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={'currency'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl
              fullWidth
              sx={{
                '& label': {
                  transform: 'translate(0px, -9px) scale(0.75)'
                }
              }}
            >
              <InputLabel>Moneda</InputLabel>
              <Select
                {...register('currency', { required: true })}
                label="Moneda"
                variant="standard"
                value={value}
              >
                <MenuItem value="DOP">Peso Dominicano (DOP)</MenuItem>
                <MenuItem value="USD">Dolar Estadounidense (USD)</MenuItem>
              </Select>
            </FormControl>
          )}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 4, textAlign: 'center' }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Descargar
        </Button>
      </Grid>
    </Grid>
  )
}

export default Bill
