import { useState, useEffect } from 'react'
import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  Select,
  MenuItem,
  IconButton,
  TextField,
  InputAdornment,
  DialogContent,
  FormControl,
  Autocomplete
} from '@mui/material'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { newUser, newUserAdmin, editUser } from '../../services/Services'
import { options } from '../../types/types'
import { formSchemaOptions } from '../../utils'
import { datos } from '../../data/data'

interface registerTypes {
  id: number
  idType: options
  identification: string
  firstName: string
  lastName: string
  username: string
  password?: string
  status: string
  phone: string
  userRole: string
}

interface DialogProps {
  actionButton?: any
  cancelModal: any
  setSnackBar: any
  data: any
  openModalUpdUser: boolean
}

const validationRegister = Yup.object().shape({
  idType: Yup.object().shape(formSchemaOptions).typeError('Campo es requerido'),
  identification: Yup.string().required('Campo es requerido'),
  firstName: Yup.string().required('Campo es requerido'),
  lastName: Yup.string().required('Campo es requerido'),
  phone: Yup.string(),
  username: Yup.string().required('Campo es requerido').email('Email es invalido'),
  userRole: Yup.string().required('Campo es requerido'),
  password: Yup.string().when('openModalUpdUser', ([openModalUpdUser]) => {
    return openModalUpdUser === false
      ? Yup.string()
          .min(6, 'Contraseña debe tener al menos 6 caracteres')
          .test(
            'password',
            `Formato no es correcto, debe contener al menos 1 mayúscula y 1 dígito`,
            (value: any) => value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,20}$/)?.length > 0
          )
      : Yup.string()
  })
  /* password: Yup.string()
    .min(6, 'Contraseña debe tener al menos 6 caracteres')
    .test(
      'password',
      `Formato no es correcto, debe contener al menos 1 mayúscula y 1 dígito`,
      (value: any) => value.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,20}$/)?.length > 0
    ) */
})

const AddOrEditUser = ({ cancelModal, setSnackBar, data, openModalUpdUser }: DialogProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [loading, setLoading] = useState(false)

  const defaultValues: registerTypes = {
    //idType: {},
    id: data?.id ?? 0,
    idType:
      data !== null && data?.idType
        ? datos.id_type.find((f: any) => f.id === JSON.parse(data?.idType)?.id)
        : {},
    identification: data?.identification ?? '',
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    username: data?.username ?? '',
    status: data?.status ?? '',
    phone: data?.phone ?? '',
    userRole: data?.userRole ?? ''
  }

  useEffect(() => {
    /* if (data !== null && data?.idType !== null) {
      const a = datos.id_type.find((f: any) => f.id === JSON.parse(data?.idType)?.id)
      console.log(JSON.parse(data?.idType))
      console.log(a)
    } */
    console.log(data)
  }, [data])

  const { register, handleSubmit, formState, control, reset } = useForm<registerTypes>({
    defaultValues,
    resolver: yupResolver(validationRegister)
  })
  const { errors } = formState

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  const onSubmit = async (data: any) => {
    console.log(data)
    setLoading(true)
    const dataRegister = {
      idType: JSON.stringify(data?.idType),
      identification: data.identification,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      username: data.username,
      userRole: data.userRole,
      phone: data.phone
    }
    const dataUpdUser = {
      id: data.id,
      idType: JSON.stringify(data?.idType),
      identification: data.identification,
      firstName: data.firstName,
      lastName: data.lastName,
      username: data.username,
      userRole: data.userRole,
      phone: data.phone
    }
    try {
      let response
      if (data.id !== 0) {
        response = await editUser(dataUpdUser)
        if (response.status === 200) {
          setSnackBar({ open: true, type: 'success', message: 'Usuario agregado con exito' })
          cancelModal()
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            message: response.body.message
          })
        }
      } else {
        if (data.userRole === 'Cliente') {
          response = await newUser(dataRegister)
        } else {
          response = await newUserAdmin(dataRegister)
        }

        if (response.status === 200) {
          setSnackBar({ open: true, type: 'success', message: 'Usuario agregado con exito' })
          cancelModal()
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            message: response.body.message
          })
        }
      }
    } catch (e: any) {
      setSnackBar({
        open: true,
        type: 'error',
        message: e.response.data.message
      })
      setLoading(false)
    }
  }

  return (
    <Box minWidth={350} py={3}>
      <DialogTitle sx={{ px: 7, mb: 2 }}>Nuevo Usuario</DialogTitle>
      <DialogContent sx={{ px: 4, pb: 0 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} sx={{ mb: 1.5, px: 3 }}>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Correo electrónico
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'username'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    //label="Email"
                    type="email"
                    variant="standard"
                    autoComplete="email"
                    {...register('username')}
                    error={errors.username && Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Nombre
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'firstName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    //label="Nombre"
                    type="text"
                    variant="standard"
                    autoComplete="firstName"
                    {...register('firstName')}
                    error={errors.firstName && Boolean(errors.firstName)}
                    helperText={errors.firstName && errors.firstName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Apellido
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'lastName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    //label="Apellido"
                    type="text"
                    variant="standard"
                    autoComplete="lastName"
                    {...register('lastName')}
                    error={errors.lastName && Boolean(errors.lastName)}
                    helperText={errors.lastName && errors.lastName.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Tipo de Identificación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                control={control}
                name="idType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue)
                    }}
                    options={datos.id_type}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        variant="standard"
                        error={!!errors.idType}
                        helperText={errors.idType && errors.idType.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Nro. de Identificación
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'identification'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    //label="Cédula"
                    type="text"
                    variant="standard"
                    autoComplete="identification"
                    {...register('identification')}
                    error={errors.identification && Boolean(errors.identification)}
                    helperText={errors.identification && errors.identification.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Teléfono
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'phone'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    //label="Cédula"
                    type="text"
                    variant="standard"
                    autoComplete="phone"
                    {...register('phone')}
                    error={errors.phone && Boolean(errors.phone)}
                    helperText={errors.phone && errors.phone.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Contraseña
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'password'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    //label="Contraseña"
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    variant="standard"
                    autoComplete="password"
                    {...register('password')}
                    error={!!errors.password}
                    helperText={!!errors.password && errors.password?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <IconButton onClick={handleClickShowPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography variant="body1" color="initial">
                Rol de Usuario
              </Typography>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name={'userRole'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth className={'disabled-select'}>
                    <Select
                      defaultValue="Lector"
                      variant="standard"
                      value={value}
                      {...register('userRole')}
                    >
                      <MenuItem value="Administrador">Administrador</MenuItem>
                      <MenuItem value="Editor">Editor</MenuItem>
                      <MenuItem value="Lector">Lectura</MenuItem>
                      <MenuItem value="Cliente">Cliente</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-evenly', pb: 3 }}>
        <Button variant="outlined" color="error" onClick={cancelModal} autoFocus>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          Aceptar
        </Button>
      </DialogActions>
    </Box>
  )
}

export default AddOrEditUser
