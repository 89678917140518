import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import type {} from '@mui/x-date-pickers/themeAugmentation';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: '#144752',
        light: '#6fc555',
        dark: '#103a43',
        contrastText: '#ffffff'
      },
      secondary: {
        main: '#39b34a'
      },
      text: {
        primary: '#666666'
      }
    },
    typography: {
      fontFamily: ['Mulish'].join(','),
      h5: {
        fontSize: '1.7rem',
        fontWeight: 800,
        '@media (max-width:600px)': {
          fontSize: '1.5rem'
        }
      },
      button: {
        textTransform: 'none',
        fontSize: '1rem'
      },
      body1: {
        color: '#666666'
      },
      body2: {
        color: '#666666'
      }
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100%",
            marginBottom: 16,
            padding: '4px 0 2px',
            '& .MuiInput-underline:before': {
              //borderBottom: '2px solid #CACACA'
            },
            '& label': {
              color: '#666666'
            },
            /* "&:before": {
              borderBottom: '2px solid #CACACA',
              left: '0',
              bottom: '0',
              content: `""`,
              position: 'absolute',
              right: '0',
              WebkitTransition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              pointerEvents: 'none'
            }, */
            fieldset: {
              border: 0
            }
          }
        }
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #CACACA'
            },
          },
          underline: {
            '&:before': {
              borderBottom: '2px solid #CACACA',
            }
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: '4px 0 2px',
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #CACACA'
            },
            '&:before': {
              borderBottom: '2px solid #CACACA',
              left: '0',
              bottom: '0',
              content: `""`,
              position: 'absolute',
              right: '0',
              WebkitTransition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              pointerEvents: 'none'
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: '2px solid #666666'
            }
          },
          input: {
            padding: ' 4px 0 2px!important',
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #CACACA'
            },
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0px 1000px white inset',
            }
          }

        }
      },
      MuiInputLabel: {},
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow:
              '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)',
              borderRadius: '15px'
          }
        }
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: '0px',
            color: '#d32f2f'
          }
        }
      },
      MuiSelect: {
        styleOverrides: {
          select: {
            padding: ' 4px 0 2px',
            '& .MuiInput-underline:before': {
              borderBottom: '2px solid #CACACA'
            },
           /*  '&.Mui-disabled': {
              WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
            } */
           "&.MuiSelect-multiple.Mui-disabled": {
              WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
           }
          }

        }
      },
      MuiButton: {
        styleOverrides: {
          containedPrimary: {
            background:
              'linear-gradient(to bottom, #39b34a 0%, #62bc44 51%, #63bc44 52%, #8ac43f 100%)',
            '&:hover': {
              background:
                'linear-gradient(to bottom, #35a544 0%, #62bc44 61%, #63bc44 62%, #8ac43f 100%)'
            },
            '&.Mui-disabled': {
              color: 'inherit!important',
              opacity: '0.5'
            }
          }
        }
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: 'red',
          },
        }
      }
    }
  })
)

export default theme
