import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Grid,
  Typography,
  Divider,
  Button,
  Stack,
  CircularProgress,
  Autocomplete,
  TextField
} from '@mui/material'
import { datos } from '../../data/data'
import { getKYC_juridico, getPerfil, getMatriz } from '../../services/Services'

const InvestorTypeResults = () => {
  const { data, investmentProfile, allData, allDataJ } = useClient()
  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false)
  const [loadedPDFPerfil, setLoadedPDFPerfil] = useState(false)
  const [loadeContract, setLoadeContract] = useState(false)
  const [loadedPDFMatriz, setLoadedPDFMatriz] = useState(false)
  const [value, setValue] = useState<any>()
  const [dataContract, setDataContract] = useState({
    userId: data.body.userId,
    idAltioAgent: ''
  })
  const [selectAgent, setSelectAgent] = useState(false)
  const { id } = useParams()

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoadeContract(true)
    alert(JSON.stringify(dataContract))

    setLoadeContract(false)
  }

  useEffect(() => {
    setDataContract({
      ...dataContract,
      idAltioAgent: value ? value.id : ''
    })
    value ? setSelectAgent(true) : setSelectAgent(false)
  }, [value])

  const handleDownloadKYC = async () => {
    setLoadedPDFKYC(true)
    await getKYC_juridico(id)
    setLoadedPDFKYC(false)
  }

  /* const handleDownloadPerfil = async () => {
    setLoadedPDFPerfil(true)
    await getPerfil(id)
    setLoadedPDFPerfil(false)
  }*/

  const handleDownloadMatriz = async () => {
    setLoadedPDFMatriz(true)
    await getMatriz(id)
    setLoadedPDFMatriz(false)
  }

  return (
    <div>
      <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography align="center" variant="h5" sx={{ mb: 4 }} color="primary">
            Tipo de Inversionista
          </Typography>
          <Typography sx={{ mb: 2 }}>
            Según las selecciones del cliente, el resultado es:
          </Typography>
          <Typography align="center" variant="h6" color="primary">
            {
              //@ts-ignore
              investmentProfile && investmentProfile?.body?.result?.INVESTOR_TYPE
            }
          </Typography>
          {investmentProfile?.body?.result?.INVESTOR_CATEGORY && (
            <>
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: 'secondary.main',
                  width: '50%',
                  maxWidth: '450px',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />

              <Typography
                align="center"
                variant="h5"
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  backgroundColor: 'primary.main',
                  color: '#fff',
                  pb: '4px',
                  margin: '0 auto',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.9]
                  }
                }}
                color="light.contrastText"
              >
                {investmentProfile?.body?.result?.INVESTOR_CATEGORY}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Divider
        sx={{
          pt: 3,
          mb: 3,
          borderColor: 'secondary.main',
          width: '50%',
          maxWidth: '450px',
          ml: 'auto',
          mr: 'auto'
        }}
      />
      {data.body?.fields?.agree_cat_investor_profile === 'Sí' ||
        (data.body?.fields?.agree_cat_investor_profile === 'Si' && (
          <Typography sx={{ mb: 4 }} align="center" variant="h5">
            Cliente esta de acuerdo con la categorización de su perfil
          </Typography>
        ))}
      {data.body?.fields?.agree_cat_investor_profile === 'No' && (
        <>
          <Typography
            sx={{ mt: 4, mb: 4, fontSize: '1.25rem!important' }}
            align="center"
            variant="h5"
          >
            Sin embargo, el cliente solicitó considerarse de la siguiente manera:
          </Typography>
          <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
            {data.body?.fields?.agree_cat_investor_profile === 'No' && (
              <Grid item xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name="type_investor_considered"
                  label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                  value={
                    data.body?.fields?.type_investor_considered &&
                    allDataJ &&
                    allDataJ.al_ta_type_investor_considered.find(
                      (f: any) => f.id === Number(data.body?.fields?.type_investor_considered)
                    )?.description
                  }
                />
              </Grid>
            )}
            {/* {data.body?.fields?.type_investor_considered !== '1' && ( */}
            {data.body?.fields?.type_investor_considered &&
              allDataJ &&
              allDataJ.al_ta_type_investor_considered.find(
                (f: any) => f.id === Number(data.body?.fields?.type_investor_considered)
              )?.description === 'No profesional' && (
                <Grid item xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name="what_cat_investor_considered"
                    label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
                    value={
                      data.body?.fields?.what_cat_investor_considered &&
                      allDataJ &&
                      allDataJ.al_ta_what_cat_investor_considered.find(
                        (f: any) => f.id === Number(data.body?.fields?.what_cat_investor_considered)
                      )?.description
                    }
                  />
                </Grid>
              )}
          </Grid>
        </>
      )}
      <TitleForm sx={{ mt: 4, mb: 4 }}> Descarga de Documentos</TitleForm>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        alignItems="center"
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Button variant="outlined" onClick={handleDownloadKYC}>
          {loadedPDFKYC && (
            <CircularProgress size={24} sx={{ marginRight: '5px', color: '#6fc555' }} />
          )}
          Descargar
        </Button>
        {/*  <Button variant="outlined" onClick={handleDownloadPerfil}>
          {loadedPDFPerfil && <CircularProgress size={24} sx={{ mr: 1, color: '#6fc555' }} />}
          Descargar Evaluación del Perfil
        </Button>*/}
        <Button variant="outlined" onClick={handleDownloadMatriz}>
          {loadedPDFMatriz && <CircularProgress size={24} sx={{ mr: 1, color: '#6fc555' }} />}
          Descargar Matriz de Riesgo
        </Button>
      </Stack>
      {/* <Divider
        sx={{
          pt: 3,
          mb: 3,
          borderColor: 'secondary.main',
          width: '50%',
          maxWidth: '450px',
          ml: 'auto',
          mr: 'auto'
        }}
      /> */}
      {/*  <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="center"
        spacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Autocomplete
          id="altio_agents"
          value={value}
          options={datos.AltioAgents}
          onChange={(event: any, newValue: string | null) => {
            setValue(newValue)
          }}
          getOptionLabel={(option: any) => option.label}
          isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
          sx={{ width: 300, mb: 0 }}
          renderInput={(params) => (
            <TextField {...params} sx={{ mb: 0 }} variant="standard" label="Agente de Altio" />
          )}
        />
        <Button variant="outlined" onClick={handleSubmit} disabled={!selectAgent}>
          {loadeContract && (
            <CircularProgress size={24} sx={{ marginRight: '5px', color: '#6fc555' }} />
          )}
          Descargar Contrato
        </Button>
      </Stack> */}
      {/* <TitleForm sx={{ mt: 6, mb: 4 }}>Confirmación de Perfil</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="agree_cat_investor_profile"
            label="¿Cliente está de acuerdo con la categorización de su
            perfil de inversionista?"
            value={
              data.body?.fields?.agree_cat_investor_profile &&
              data.body?.fields?.agree_cat_investor_profile
            }
          />
        </Grid>
        {data.body?.fields?.agree_cat_investor_profile === 'No' && (
          <Grid item xs={12}>
            <FancyInput
              required
              type={'text'}
              disabled={true}
              name="type_investor_considered"
              label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
              value={
                data.body?.fields?.type_investor_considered &&
                allData &&
                allData.al_ta_type_investor_considered.find(
                  (f: any) => f.id === Number(data.body?.fields?.type_investor_considered)
                )?.description
              }
            />
          </Grid>
        )}
        {data.body?.fields?.type_investor_considered === '2' && (
          <Grid item xs={12}>
            <FancyInput
              required
              type={'text'}
              disabled={true}
              name="what_cat_investor_considered"
              label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
              value={
                data.body?.fields?.what_cat_investor_considered &&
                allData &&
                allData.al_ta_what_cat_investor_considered.find(
                  (f: any) => f.id === Number(data.body?.fields?.what_cat_investor_considered)
                )?.description
              }
            />
          </Grid>
        )}
      </Grid> */}
    </div>
  )
}

export default InvestorTypeResults
