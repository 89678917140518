import * as Yup from "yup";

export const formatDate = (day:Date) => {
  const newDay = new Date(day)
  const options:any = {
     year: 'numeric',
     month: 'numeric',
     day: '2-digit'
  }

  return newDay.toLocaleDateString('es-ES', options)
}

export const phoneRegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{2,6}$/;

export const numberRegExp = /^[0-9]*$/;

export const urlRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const formSchemaOptions = {
  id: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};

export const formSchemaCountry = {
  id: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
};
