export const en_es: any = {
  description: 'Descripción',
  riskTolerance: 'Tolerancia a Riegos',
  weight: 'Peso',
  transactionKnowledge: 'Conocimiento de Transacción',
  economicCapacity: 'Capacidad Económica',
  comprehension: 'Comprensión de Operación',
  typeRiskAssume: 'Tipo de Riesgo dispuesto a asumir',
  clasification: 'Clasificación',
  capacityFinancialUnderstanding: 'Capacidad comprensión Financiera',
  capacityEconomicIntegral: 'Capacidad Económica Integral',
  name: 'Nombre',
  elements: 'Elementos',
  createDate: 'Fecha de Creacion',
  updateDate: 'Fecha de Actualización',
  tipeRiskAssume: 'Tipo de Riesgo Asumido',
  typeRiskWillingAssume: 'Tipo Riesgo Dispuesto Asumir',
  capacityFinanceUnderstanding: 'Capacidad de comprensión Financiera',
  provinceCode: 'Código de Provincias',
  username: 'Correo electrónico',
  email: 'Correo electrónico',
  firstName: 'Nombre',
  lastName: 'Apellido',
  identification: 'Cédula',
  phone: 'Teléfono',
  activationCode: 'Codigo de Activación',
  password: 'Contraseña',
  country: 'País',
  probability	: 'Probabilidad',
  impact: 'Impacto',
  inherentRisk: 'Riesgo inherente',
  userRole: 'Rol de Usuario',
  status: 'Estado',
  municipalityCode: 'Código de municipio',
  countryCode: 'Código de País',
  sectorCode: 'Código de Sector',
  createdAt: 'Fecha de Creación',
  tableName: 'Nombre',
  numberData: 'Elementos',
  modified: 'Fecha de Actualización',
  firsname: 'Nombre',
  formtype: 'Tipo de Cuenta',
  stepForm: 'Paso completado',
  risk: 'Leyenda de Riesgo',
  category: 'Categoría',
  code: 'RMV'
}
