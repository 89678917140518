import { useState, useEffect } from 'react'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Grid,
  TextField,
  FormLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Stack,
  Button
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import InputLabelToForm from '../../components/InputLabelToForm'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'
import { PhysycalCustomerIdentificationType } from '../../types/types'
import { validationCustomerIdentification } from '../legalClient/validation'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateProfile } from '../../services/Services'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'
import ButtonGradient from '../../components/ButtonGradient'

const CustomerIdenfication = () => {
  const { data, allData, address, handleNext, loadTypeProfile, loadData, getSectorAddress } =
    useClient()

  const [loadingSendData, setLoadingSendData] = useState(false)
  const [disabledStep1, setDisabledStep1] = useState(true)
  const miarray: any = []
  const userID = data.body?.userId
  const countryASC =
    allData &&
    allData.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1
      }
    })

  const findData = data.body?.fields

  const defaultValues: PhysycalCustomerIdentificationType = {
    investmentPromoter: findData.investmentPromoter
      ? allData &&
        allData.al_ta_investment_promoter.find(
          (f: any) => f.id === Number(findData.investmentPromoter)
        )
      : {},
    numberIdentity: findData.numberIdentity ? findData.numberIdentity : '',
    IDType: findData.IDType ? datos.id_type.find((f: any) => f.id === findData.IDType) : [],

    expirationDateID: findData.expirationDateID ? new Date(findData.expirationDateID) : null,

    expedition_place: findData.expedition_place ? findData.expedition_place : '',
    name: findData.name ? findData.name : '',
    lastName: findData.lastName ? findData.lastName : '',
    gender: findData.gender ? findData.gender : '',
    dateOfBirth: findData.dateOfBirth ? new Date(findData.dateOfBirth) : null,
    birthPlace: findData.birthPlace
      ? data && countryASC.find((f: any) => f.id === Number(findData.birthPlace))
      : [],
    maritalStatus: findData.maritalStatus
      ? datos.maritalStatus.find((f: any) => f.id === findData.maritalStatus)
      : [],
    academic_level: findData.academic_level
      ? allData &&
        allData.al_ta_academic_level?.find((f: any) => f.id === Number(findData.academic_level))
      : [],
    profession: findData.profession
      ? allData && allData.al_ta_profession.find((f: any) => f.id === Number(findData.profession))
      : [],
    email: findData.email ? findData.email : '',
    phone: findData.phone ? findData.phone : '',
    since_when_is_contributor_altio: findData.since_when_is_contributor_altio
      ? allData &&
        allData.al_ta_relationship_duration.find(
          (f: any) => f.id === Number(findData.since_when_is_contributor_altio)
        )
      : [],
    maritalIDType: findData.maritalIDType
      ? datos.id_type.find((f: any) => f.id === findData.maritalIDType)
      : {},
    maritalNumberIdentity: findData.maritalNumberIdentity ? findData.maritalNumberIdentity : '',
    maritalIDexpirationDate: findData.maritalIDexpirationDate
      ? new Date(findData.maritalIDexpirationDate)
      : null,
    maritalName: findData.maritalName ? findData.maritalName : '',
    maritalLastName: findData.maritalLastName ? findData.maritalLastName : '',
    marital_expedition_place: findData.expedition_place ? findData.expedition_place : '',
    nationality: findData.nationality
      ? allData && countryASC.find((f: any) => f.id === Number(findData.nationality))
      : [],
    doubleNationality: findData.doubleNationality
      ? findData.doubleNationality === 'true' && true
      : false,
    secondNationality: findData.secondNationality
      ? data && countryASC.find((f: any) => f.id === Number(findData.secondNationality))
      : {},
    dominican_resident: findData.dominican_resident
      ? findData.dominican_resident === 'true' && true
      : false,
    /* dominican_resident: findData.dominican_resident
      ? findData.dominican_resident === 'true' && true
      : false, */
    country: findData.country
      ? allData && countryASC.find((f: any) => f.id === Number(findData.country))
      : [],
    province: findData.province
      ? address && address.provinces.find((f: any) => f.id === Number(findData.province))
      : [],
    sector: findData.sector
      ? address && address.sector?.find((f: any) => f.id === Number(findData.sector))
      : [],
    street: findData.street ? findData.street : '',
    houseApartNumber: findData.houseApartNumber ? findData.houseApartNumber : '',
    building: findData.building ? findData.building : '',
    telephoneResidence: findData.telephoneResidence ? findData.telephoneResidence : '',
    politicallyExposedPerson: findData.politicallyExposedPerson
      ? findData.politicallyExposedPerson
      : '',
    positionPoliticExposedPerson: findData.positionPoliticExposedPerson
      ? findData.positionPoliticExposedPerson
      : '',
    appointmentDatePoliticExposedPerson: findData.appointmentDatePoliticExposedPerson
      ? new Date(findData.appointmentDatePoliticExposedPerson)
      : null,
    removal_date_politic_exposed_person: findData.removal_date_politic_exposed_person
      ? new Date(findData.removal_date_politic_exposed_person)
      : null,
    partnerPoliticallyExposed: findData.partnerPoliticallyExposed
      ? findData.partnerPoliticallyExposed
      : '',
    PEPName: findData.PEPName ? findData.PEPName : '',
    affinity: findData.affinity
      ? datos.degrees_of_consanguinity.find((f: any) => f === findData.affinity)
      : '',
    positionPEP: findData.positionPEP ? findData.positionPEP : '',
    appointmentDate: findData.appointmentDate ? new Date(findData.appointmentDate) : null,
    removalDate: findData.removalDate ? new Date(findData.removalDate) : null
  }

  const {
    register,
    handleSubmit,
    resetField,
    reset,
    formState,
    control,
    watch,
    getValues,
    setValue
  } = useForm<PhysycalCustomerIdentificationType>({
    defaultValues,
    resolver: yupResolver(validationCustomerIdentification)
  })
  const { errors, isDirty, dirtyFields } = formState

  const watchMaritalStatus: any = watch('maritalStatus')
  const watchDoubleNationality = watch('doubleNationality')
  const watchPoliticallyExposedPerson = watch('politicallyExposedPerson')
  const watchPartnerPoliticallyExposed = watch('partnerPoliticallyExposed')
  const watch_country: any = watch('country')
  const watch_province: any = watch('province')
  const [sectorData, setSectorData] = useState([])

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  useEffect(() => {
    if (watchMaritalStatus.id !== '2' && watchMaritalStatus.id !== '3') {
      resetField('maritalIDType')
      resetField('maritalNumberIdentity')
      resetField('maritalIDexpirationDate')
      resetField('maritalName')
      resetField('maritalLastName')
    }
  }, [watchMaritalStatus])

  useEffect(() => {
    if (!watchDoubleNationality) {
      resetField('secondNationality')
    }

    if (watchPoliticallyExposedPerson === '2') {
      resetField('positionPoliticExposedPerson')
      resetField('appointmentDatePoliticExposedPerson')
    }

    if (watchPartnerPoliticallyExposed === '2') {
      resetField('PEPName')
      resetField('affinity')
      resetField('positionPEP')
      resetField('appointmentDate')
      resetField('removalDate')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDoubleNationality, watchPoliticallyExposedPerson, watchPartnerPoliticallyExposed])

  useEffect(() => {
    if (data !== undefined && dirtyFields.country) {
      resetField('province', { defaultValue: [] })

      resetField('sector', { defaultValue: [] })
    } else if (watch_country?.id !== 63) {
      resetField('province')
      resetField('sector')
    }
  }, [watch_country])

  useEffect(() => {
    if (data !== undefined && dirtyFields.province) {
      resetField('sector', { defaultValue: [] })
    } else {
      resetField('sector')
    }
    const getSector = async () => {
      const s: any = await getSectorAddress(watch_province.provinceCode)
      //console.log("s", s);
      const filterSector = s?.find((f: any) => f.id === Number(findData.sector))
      setValue('sector', filterSector)
      setSectorData(s)
    }

    if (watch_province?.provinceCode) {
      getSector()
    }
  }, [watch_province])

  useEffect(() => {
    if (watchPartnerPoliticallyExposed !== '1') {
      resetField('PEPName', { defaultValue: '' })
      resetField('affinity', { defaultValue: '' })
      /* resetField("affinity", { defaultValue: [] }); */
      resetField('positionPEP', { defaultValue: '' })
      resetField('appointmentDate', { defaultValue: null })
      resetField('removalDate', { defaultValue: null })
    }
  }, [watchPartnerPoliticallyExposed])

  useEffect(() => {
    if (watchPoliticallyExposedPerson !== '1') {
      resetField('positionPoliticExposedPerson', { defaultValue: '' })
      resetField('appointmentDatePoliticExposedPerson', { defaultValue: null })
    }
  }, [watchPoliticallyExposedPerson])

  const onSubmit = async (data: any) => {
    setLoadingSendData(true)
    let key: string | any
    let value: { id: string; description: string }[] | any = []
    let age: string = ''

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value })
      if (!value) {
        delete data[key]
      } else if (typeof value == 'object') {
        //data[key] = value !== null && value.id ? value.id : `"${value}"`;
        data[key] =
          value !== null && value.id
            ? typeof value.id !== 'string'
              ? JSON.stringify(value.id)
              : value.id
            : `"${value}"`
      }
    }

    let birth = new Date(data.dateOfBirth)
    let birth_year = birth.getFullYear()
    let current_date = new Date()
    let current_year = current_date.getFullYear()
    let age_customer = current_year - birth_year
    if (age_customer > 55) {
      age = '1'
    } else if (age_customer >= 35 && age_customer <= 55) {
      age = '2'
    } else {
      age = '3'
    }
    data.age = age

    let update = {}
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key)
      if (typeof value == 'object') {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== 'string'
                ? JSON.stringify(value.id)
                : value.id
              : `"${value}"`
        })
      } else {
        Object.assign(update, { [key]: value })
      }
      if (dirtyFields.dateOfBirth) {
        Object.assign(update, { age: age })
      }
      if (key === 'country') {
        if (value !== 63) {
          Object.assign(update, { province: '' })
          Object.assign(update, { municipality: '' })
          Object.assign(update, { sector: '' })
        }
      }
      if (key === 'partnerPoliticallyExposed') {
        if (value !== 2) {
          Object.assign(update, { PEPName: '' })
          Object.assign(update, { affinity: '' })
          Object.assign(update, { positionPEP: '' })
          Object.assign(update, { appointmentDate: '' })
          Object.assign(update, { removalDate: '' })
        }
      }

      if (key === 'politicallyExposedPerson') {
        if (value !== 2) {
          Object.assign(update, { positionPoliticExposedPerson: '' })
          Object.assign(update, { appointmentDatePoliticExposedPerson: '' })
        }
      }
    }

    const handleResponse = () => {
      handleNext && handleNext()
    }

    if (isDirty) {
      let fullUpData = {
        fields: {
          ...update
        },
        formType: 'F',
        userId: userID,
        stepForm: 'Confirmación de Perfil'
      }
      const updateData = await updateProfile(fullUpData)
      if (updateData.status === 200) {
        loadData && loadData(userID)
        loadTypeProfile && loadTypeProfile(userID)
        handleResponse()
      }
    } else {
      setLoadingSendData(false)
      handleResponse()
    }
  }

  return (
    <div>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <ButtonGradient type="button" onClick={() => setDisabledStep1(!disabledStep1)}>
          Editar datos
        </ButtonGradient>
      </Stack>
      <TitleForm sx={{ mb: 4 }}>Identificación del Cliente</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="investmentPromoter"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue)
                }}
                options={allData?.al_ta_investment_promoter}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Selecciona tu promotor de inversión"
                    variant="standard"
                    error={!!errors.investmentPromoter}
                    helperText={errors.investmentPromoter && errors.investmentPromoter.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="IDType"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue)
                }}
                options={datos.id_type}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Tipo de Identificación"
                    variant="standard"
                    error={!!errors.IDType}
                    helperText={errors.IDType && errors.IDType.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'numberIdentity'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Documento de Identidad"
                type="text"
                variant="standard"
                value={value}
                disabled={disabledStep1}
                {...register('numberIdentity')}
                error={errors.numberIdentity && Boolean(errors.numberIdentity)}
                helperText={errors.numberIdentity && errors.numberIdentity.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} className="datepicker">
          <Controller
            name={'expirationDateID'}
            control={control}
            defaultValue={null}
            render={({ field, ...props }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                <DatePicker
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date)
                  }}
                  disabled={disabledStep1}
                  className={errors.expirationDateID && 'Mui-error'}
                  label="Fecha de Expiración"
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                  maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                />
                {errors.expirationDateID && (
                  <FormHelperText>{errors.expirationDateID.message}</FormHelperText>
                )}
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'expedition_place'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Lugar de Expedición"
                type="text"
                variant="standard"
                autoComplete="expedition_place"
                disabled={disabledStep1}
                value={value}
                {...register('expedition_place')}
                error={errors.expedition_place && Boolean(errors.expedition_place)}
                helperText={errors.expedition_place && errors.expedition_place.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nombres"
                type="text"
                variant="standard"
                autoComplete="name"
                disabled={disabledStep1}
                value={value}
                {...register('name')}
                error={errors.name && Boolean(errors.name)}
                helperText={errors.name && errors.name.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'lastName'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Apellidos"
                type="text"
                variant="standard"
                disabled={disabledStep1}
                value={value}
                autoComplete="lastName"
                {...register('lastName')}
                error={errors.lastName && Boolean(errors.lastName)}
                helperText={errors.lastName && errors.lastName.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'gender'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={disabledStep1}>
                <InputLabelToForm error={errors.gender && Boolean(errors.gender)}>
                  Sexo
                </InputLabelToForm>
                <Select
                  label="Género"
                  variant="standard"
                  value={value}
                  {...register('gender')}
                  error={errors.gender && Boolean(errors.gender)}
                >
                  <MenuItem value="Femenino">Femenino</MenuItem>
                  <MenuItem value="Masculino">Masculino</MenuItem>
                </Select>
                {errors.gender && (
                  <FormHelperText color="error">{errors.gender.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12} className="datepicker">
          <Controller
            name={'dateOfBirth'}
            control={control}
            defaultValue={null}
            render={({ field, ...props }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                <DatePicker
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date)
                  }}
                  disabled={disabledStep1}
                  className={errors.dateOfBirth && 'Mui-error'}
                  label="Fecha de Nacimiento"
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 100))}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="birthPlace"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allData && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="País de Nacimiento"
                      variant="standard"
                      error={!!errors.birthPlace}
                      helperText={errors.birthPlace && errors.birthPlace.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="maritalStatus"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={datos.maritalStatus}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Estado Civil"
                      variant="standard"
                      error={!!errors.maritalStatus}
                      helperText={errors.maritalStatus && errors.maritalStatus.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="academic_level"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={
                  allData && allData.al_ta_academic_level ? allData.al_ta_academic_level : []
                }
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Nivel Académico"
                    variant="standard"
                    error={!!errors.academic_level}
                    helperText={errors.academic_level && errors.academic_level.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="profession"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allData && allData.al_ta_profession}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Profesión"
                    variant="standard"
                    error={!!errors.profession}
                    helperText={errors.profession && errors.profession.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'email'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Correo Electrónico"
                type="email"
                variant="standard"
                autoComplete="email"
                value={value}
                disabled={disabledStep1}
                {...register('email')}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'phone'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Celular"
                type="phone"
                variant="standard"
                value={value}
                disabled={disabledStep1}
                autoComplete="phone"
                {...register('phone')}
                error={errors.phone && Boolean(errors.phone)}
                helperText={errors.phone && errors.phone.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="since_when_is_contributor_altio"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                disabled={disabledStep1}
                options={allData && allData.al_ta_relationship_duration}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="¿Desde cuándo es aportante en ALTIO SAFI?"
                    variant="standard"
                    error={!!errors.since_when_is_contributor_altio}
                    helperText={
                      errors.since_when_is_contributor_altio &&
                      errors.since_when_is_contributor_altio.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="nationality"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                disabled={disabledStep1}
                options={allData && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Nacionalidad"
                      variant="standard"
                      error={!!errors.nationality}
                      helperText={errors.nationality && errors.nationality.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <FormLabel component="legend">¿Doble Nacionalidad?</FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="doubleNationality"
            render={({ field: { value, onChange } }) => {
              return (
                <Switch
                  value={value}
                  checked={value}
                  disabled={disabledStep1}
                  {...register('doubleNationality')}
                  onChange={(val: any) => {
                    return onChange(val)
                  }}
                />
              )
            }}
          />
        </Grid>
        {watchDoubleNationality && (
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              control={control}
              name="secondNationality"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue: any) => {
                    if (newValue === null) {
                      newValue = ''
                    }
                    onChange(newValue)
                  }}
                  disabled={disabledStep1}
                  options={allData && countryASC}
                  getOptionLabel={(option: any) => option.description || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps
                    inputProps.autoComplete = 'new-password'
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Indique 2da Nacionalidad"
                        variant="standard"
                        error={!!errors.secondNationality}
                        helperText={errors.secondNationality && errors.secondNationality.message}
                      />
                    )
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <FormLabel component="legend">¿Es Residente Dominicano?</FormLabel>
          <Controller
            rules={{ required: true }}
            control={control}
            name="dominican_resident"
            render={({ field: { value, onChange } }) => {
              return (
                <Switch
                  value={value}
                  checked={value}
                  disabled={disabledStep1}
                  {...register('dominican_resident')}
                  onChange={(val: any) => {
                    return onChange(val)
                  }}
                />
              )
            }}
          />
        </Grid>
      </Grid>
      {(watchMaritalStatus.description === 'Casado' ||
        watchMaritalStatus.description === 'Unión Libre') && (
        <>
          <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
            Datos del Cónyuge o Pareja en Unión Libre
          </TitleForm>
          <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="maritalIDType"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={datos.id_type}
                    disabled={disabledStep1}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de Identificación"
                        variant="standard"
                        error={!!errors.maritalIDType}
                        helperText={errors.maritalIDType && errors.maritalIDType.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'maritalNumberIdentity'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Documento de Identidad"
                    type="text"
                    variant="standard"
                    disabled={disabledStep1}
                    value={value}
                    {...register('maritalNumberIdentity')}
                    error={errors.maritalNumberIdentity && Boolean(errors.maritalNumberIdentity)}
                    helperText={
                      errors.maritalNumberIdentity && errors.maritalNumberIdentity.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'maritalIDexpirationDate'}
                control={control}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep1}
                      className={errors.maritalIDexpirationDate && 'Mui-error'}
                      label="Fecha de Expiración"
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'marital_expedition_place'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Lugar de Expedición"
                    type="text"
                    variant="standard"
                    autoComplete="marital_expedition_place"
                    disabled={disabledStep1}
                    value={value}
                    {...register('marital_expedition_place')}
                    error={
                      errors.marital_expedition_place && Boolean(errors.marital_expedition_place)
                    }
                    helperText={
                      errors.marital_expedition_place && errors.marital_expedition_place.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'maritalName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombres"
                    type="text"
                    variant="standard"
                    autoComplete="maritalName"
                    value={value}
                    disabled={disabledStep1}
                    {...register('maritalName')}
                    error={errors.maritalName && Boolean(errors.maritalName)}
                    helperText={errors.maritalName && errors.maritalName.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'maritalLastName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Apellidos"
                    type="text"
                    variant="standard"
                    autoComplete="maritalLastName"
                    value={value}
                    disabled={disabledStep1}
                    {...register('maritalLastName')}
                    error={errors.maritalLastName && Boolean(errors.maritalLastName)}
                    helperText={errors.maritalLastName && errors.maritalLastName.message}
                  />
                )}
              />
            </Grid>
          </Grid>
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Dirección Residencial
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="country"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={allData && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep1}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="País"
                      variant="standard"
                      error={!!errors.country}
                      helperText={errors.country && errors.country.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {watch_country?.id === '63' ||
          (watch_country?.id === 63 && (
            <>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="province"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={address.provinces}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) =>
                        option.provinceCode === value.provinceCode
                      }
                      value={value}
                      disabled={disabledStep1}
                      renderInput={(params) => {
                        const inputProps = params.inputProps
                        inputProps.autoComplete = 'new-password'
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Provincia"
                            variant="standard"
                            error={!!errors.province}
                            helperText={!!errors.province && 'Campo es requerido'}
                          />
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="sector"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={sectorData?.length > 0 ? sectorData : []}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      disabled={disabledStep1}
                      renderInput={(params) => {
                        const inputProps = params.inputProps
                        inputProps.autoComplete = 'new-password'
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Sector"
                            variant="standard"
                            error={!!errors.sector}
                            helperText={!!errors.sector && 'Campo es requerido'}
                          />
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </>
          ))}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'street'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Calle"
                type="street"
                variant="standard"
                autoComplete="street"
                value={value}
                disabled={disabledStep1}
                {...register('street')}
                error={errors.street && Boolean(errors.street)}
                helperText={errors.street && errors.street.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'houseApartNumber'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="N° Calle / Apto"
                type="houseApartNumber"
                variant="standard"
                autoComplete="houseApartNumber"
                value={value}
                disabled={disabledStep1}
                {...register('houseApartNumber')}
                error={errors.houseApartNumber && Boolean(errors.houseApartNumber)}
                helperText={errors.houseApartNumber && errors.houseApartNumber.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'building'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Edificio"
                type="building"
                variant="standard"
                autoComplete="building"
                value={value}
                disabled={disabledStep1}
                {...register('building')}
                error={errors.building && Boolean(errors.building)}
                helperText={errors.building && errors.building.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'telephoneResidence'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Teléfono de Residencia"
                type="telephoneResidence"
                variant="standard"
                value={value}
                disabled={disabledStep1}
                autoComplete="telephoneResidence"
                {...register('telephoneResidence')}
                error={errors.telephoneResidence && Boolean(errors.telephoneResidence)}
                helperText={errors.telephoneResidence && errors.telephoneResidence.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Persona Expuesta Políticamente
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12}>
          <Controller
            name={'politicallyExposedPerson'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={disabledStep1}>
                <InputLabelToForm
                  error={
                    errors.politicallyExposedPerson && Boolean(errors.politicallyExposedPerson)
                  }
                >
                  ¿Es o ha sido una persona expuesta políticamente (PEP) conforme la ley 155-17
                  contra el lavado de activos y su reglamento?
                </InputLabelToForm>
                <Select
                  variant="standard"
                  value={value}
                  {...register('politicallyExposedPerson')}
                  error={
                    errors.politicallyExposedPerson && Boolean(errors.politicallyExposedPerson)
                  }
                >
                  <MenuItem value="1">Sí</MenuItem>
                  <MenuItem value="2">No</MenuItem>
                </Select>
                {errors.politicallyExposedPerson && (
                  <FormHelperText>{errors.politicallyExposedPerson.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        {watchPoliticallyExposedPerson === '1' && (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'positionPoliticExposedPerson'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cargo"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={disabledStep1}
                    autoComplete="positionPoliticExposedPerson"
                    {...register('positionPoliticExposedPerson')}
                    error={
                      errors.positionPoliticExposedPerson &&
                      Boolean(errors.positionPoliticExposedPerson)
                    }
                    helperText={
                      errors.positionPoliticExposedPerson &&
                      errors.positionPoliticExposedPerson.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'appointmentDatePoliticExposedPerson'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep1}
                      className={errors.appointmentDatePoliticExposedPerson && 'Mui-error'}
                      label="Fecha de Designación"
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      maxDate={new Date()}
                    />
                    {errors.appointmentDatePoliticExposedPerson && (
                      <FormHelperText>
                        {errors.appointmentDatePoliticExposedPerson.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'removal_date_politic_exposed_person'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep1}
                      className={errors.removal_date_politic_exposed_person && 'Mui-error'}
                      label="Fecha de Cesación"
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      maxDate={new Date()}
                    />
                    {errors.removal_date_politic_exposed_person && (
                      <FormHelperText>
                        {errors.removal_date_politic_exposed_person.message}
                      </FormHelperText>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Controller
            name={'partnerPoliticallyExposed'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={disabledStep1}>
                <InputLabelToForm
                  error={
                    errors.partnerPoliticallyExposed && Boolean(errors.partnerPoliticallyExposed)
                  }
                >
                  ¿Tiene algún parentesco o es asociado cercano de una persona expuesta
                  políticamente (PEP) ?
                </InputLabelToForm>
                <Select
                  variant="standard"
                  value={value}
                  {...register('partnerPoliticallyExposed')}
                  error={
                    errors.partnerPoliticallyExposed && Boolean(errors.partnerPoliticallyExposed)
                  }
                >
                  <MenuItem value="1">Sí</MenuItem>
                  <MenuItem value="2">No</MenuItem>
                </Select>
                {errors.partnerPoliticallyExposed && (
                  <FormHelperText>{errors.partnerPoliticallyExposed.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
        {watchPartnerPoliticallyExposed === '1' && (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'PEPName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre del PEP"
                    type="PEPName"
                    variant="standard"
                    autoComplete="PEPName"
                    value={value}
                    disabled={disabledStep1}
                    {...register('PEPName')}
                    error={errors.PEPName && Boolean(errors.PEPName)}
                    helperText={errors.PEPName && errors.PEPName.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="affinity"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={datos.degrees_of_consanguinity}
                    getOptionLabel={(option: any) => option || ''}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    value={value}
                    disabled={disabledStep1}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Afinidad"
                          variant="standard"
                          error={!!errors.affinity}
                          helperText={errors.affinity && errors.affinity.message}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'positionPEP'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Cargo"
                    type="text"
                    variant="standard"
                    autoComplete="positionPEP"
                    value={value}
                    disabled={disabledStep1}
                    {...register('positionPEP')}
                    error={errors.positionPEP && Boolean(errors.positionPEP)}
                    helperText={errors.positionPEP && errors.positionPEP.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'appointmentDate'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep1}
                      label="Fecha de Designación"
                      className={errors.appointmentDate && 'Mui-error'}
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      maxDate={new Date()}
                    />
                    {errors.appointmentDate && (
                      <FormHelperText>{errors.appointmentDate.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'removalDate'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep1}
                      label="Fecha de Salida"
                      className={errors.removalDate && 'Mui-error'}
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
                    />
                    {errors.removalDate && (
                      <FormHelperText>{errors.removalDate.message}</FormHelperText>
                    )}
                  </LocalizationProvider>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
        *La ley contra el lavado de activos y el financiamiento del terrorismo (ley 155-17) califica
        como persona expuesta políticamente a: "cualquier individuo que desempeña o ha desempeñado,
        durante los últimos tres (3) años, altas funciones públicas, por elección o nombramientos
        ejecutivos, en un país extranjero o en territorio nacional, incluyendo altos funcionarios de
        organizaciones internacionales. Incluye, pero no se limita a, jefes de estado o de gobierno,
        funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de
        empresas estatales o funcionarios, así como aquellos que determine el comité nacional de
        lavado de activos previa consulta con el ministerio de la administración pública. Los cargos
        considerados PEP serán todos aquellos funcionarios obligados a presentar declaración jurada
        de bienes. Se asimilan todas aquellas personas que hayan desempeñado o desempeñen estas
        funciones o su equivalente para gobiernos extranjeros."
      </Typography>
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress size={24} sx={{ margin: '5px auto', color: '#6fc555' }} />
        </Stack>
      )}
      <Stack justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
        <ButtonGradient onClick={handleSubmit(onSubmit)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
          Continuar
        </ButtonGradient>
      </Stack>
    </div>
  )
}

export default CustomerIdenfication
