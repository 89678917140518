import { useState, useEffect } from 'react'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Grid,
  TextField,
  FormLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Autocomplete,
  Typography,
  Stack,
  Divider,
  Button
} from '@mui/material'
import InputLabelToForm from '../../components/InputLabelToForm'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'
import { PhysycalCustomerIdentificationType } from '../../types/types'
import { validationCustomerIdentification } from '../legalClient/validation'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { updateProfile } from '../../services/Services'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'
import { validationJobsInformation } from '../legalClient/validation'
import { PhysycalJobsInformation } from '../../types/types'
import ButtonGradient from '../../components/ButtonGradient'
import CircularProgress from '@mui/material/CircularProgress'

const JobsInformation = () => {
  const {
    data,
    allData,
    address,
    handleBack,
    handleNext,
    loadTypeProfile,
    loadData,
    getSectorAddress
  } = useClient()

  const [loadingSendData, setLoadingSendData] = useState(false)
  const [disabledStep2, setDisabledStep2] = useState(true)
  const miarray: any = []
  const userID = data.body?.userId
  const countryASC =
    allData &&
    allData.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1
      }
    })

  const findData = data.body?.fields

  const defaultValues: PhysycalJobsInformation = {
    employment_status: findData.employment_status
      ? allData.al_ta_employment_status.find(
          (f: any) => f.id === Number(findData.employment_status)
        )
      : [],
    ocupation: findData.ocupation ? findData.ocupation : '',
    economic_activity: findData.economic_activity
      ? allData.al_ta_economic_activity.find(
          (f: any) => f.id === Number(findData.economic_activity)
        )
      : {},
    financially_dependent_third_party: findData.financially_dependent_third_party
      ? datos.selectBooleanString?.find(
          (f: any) => f.id === findData.financially_dependent_third_party
        )
      : [],
    name_lastname_third_party: findData.name_lastname_third_party
      ? findData.name_lastname_third_party
      : '',
    id_type_third_party: findData.id_type_third_party
      ? datos.id_type.find((f: any) => f.id === findData.id_type_third_party)
      : {},
    number_identity_third_party: findData.number_identity_third_party
      ? findData.number_identity_third_party
      : '',
    relationship_third_party: findData.relationship_third_party
      ? datos.degrees_of_consanguinity.find((f: any) => f === findData.relationship_third_party)
      : '',
    companyName: findData.companyName ? findData.companyName : '',
    countryWorks: findData.countryWorks
      ? allData.al_ta_countries.find((f: any) => f.id === Number(findData.countryWorks))
      : [],
    provinceWorks: findData.provinceWorks
      ? address.provinces.find((f: any) => f.id === Number(findData.provinceWorks))
      : [],
    sectorWorks: {},
    streetWorks: findData.streetWorks ? findData.streetWorks : '',
    houseApartNumberWorks: findData.houseApartNumberWorks ? findData.houseApartNumberWorks : '',
    buildingWorks: findData.buildingWorks ? findData.buildingWorks : '',
    averageAnnualIncome: findData.averageAnnualIncome
      ? allData.al_ta_annual_income.find((f: any) => f.id === Number(findData.averageAnnualIncome))
      : [],
    liquid_assets_total: findData.liquid_assets_total
      ? allData.al_ta_total_liquid_assets.find(
          (f: any) => f.id === Number(findData.liquid_assets_total)
        )
      : [],
    total_passives: findData.total_passives
      ? allData.al_ta_total_passive.find((f: any) => f.id === Number(findData.total_passives))
      : [],
    financialObligations: findData.financialObligations
      ? allData.al_ta_financial_obligations.find(
          (f: any) => f.id === Number(findData.financialObligations)
        )
      : [],
    total_heritage: findData.total_heritage
      ? allData.al_ta_total_heritage.find((f: any) => f.id === Number(findData.total_heritage))
      : [],
    sources_funds: findData.sources_funds
      ? allData.al_ta_sources_income.find((f: any) => f.id === Number(findData.sources_funds))
      : [],
    other_sources_funds: findData.other_sources_funds ? findData.other_sources_funds : '',
    axempt_agent_withholding: findData.axempt_agent_withholding
      ? datos.selectBooleanString?.find((f: any) => f.id === findData.axempt_agent_withholding)
      : [],
    which_taxes: findData.which_taxes ? findData.which_taxes : '',
    declare_income: findData.declare_income
      ? datos.selectBooleanString.find((f: any) => f.id === findData.declare_income)
      : [],
    liquid_assets_distribution_form: (findData.liquid_assets_distribution_form &&
      JSON.parse(findData.liquid_assets_distribution_form)) ?? [
      {
        liquid_assets_distribution_id: []
      }
    ]
  }

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    resetField,
    getValues,
    reset,
    setValue
  } = useForm<PhysycalJobsInformation>({
    defaultValues,
    resolver: yupResolver(validationJobsInformation)
  })
  const { errors, isDirty, dirtyFields } = formState

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  const watch_employment_status: any = watch('employment_status')
  const watch_country: any = watch('countryWorks')
  const watch_province: any = watch('provinceWorks')
  const watch_financially_dependent_third_party: any = watch('financially_dependent_third_party')
  const watch_axempt_agent_withholding: any = watch('axempt_agent_withholding')
  const watch_sources_funds: any = watch('sources_funds')
  const [sectorData, setSectorData] = useState([])

  const {
    fields: liquid_assets_distributionField,
    append: liquid_assets_distributionAppend,
    remove: liquid_assets_distributionRemove
  } = useFieldArray({
    control,
    name: 'liquid_assets_distribution_form',
    rules: {
      minLength: 3
    }
  })

  useEffect(() => {
    if (data !== undefined && dirtyFields.sources_funds) {
      resetField('other_sources_funds', { defaultValue: '' })
    } else if (watch_sources_funds?.id !== 8) {
      resetField('other_sources_funds')
    }
  }, [watch_sources_funds])

  useEffect(() => {
    if (data !== undefined && dirtyFields.countryWorks) {
      resetField('provinceWorks', { defaultValue: {} })
      resetField('sectorWorks', { defaultValue: {} })
    } else if (watch_country?.id !== 63) {
      resetField('provinceWorks')
      resetField('sectorWorks')
    }
  }, [watch_country])

  useEffect(() => {
    if (data !== undefined && dirtyFields.provinceWorks) {
      resetField('sectorWorks', { defaultValue: {} })
    } else {
      resetField('sectorWorks')
    }
    const getSector = async () => {
      const s: any = await getSectorAddress(watch_province.provinceCode)
      const filterSector = s?.find((f: any) => f.id === Number(findData.sectorWorks))
      setValue('sectorWorks', filterSector)
      setSectorData(s)
    }
    if (watch_province?.provinceCode) {
      getSector()
    }
  }, [watch_province])

  useEffect(() => {
    if (data !== undefined && dirtyFields.employment_status) {
      setValue('economic_activity', {}, { shouldDirty: true })
    } else if (watch_employment_status?.id !== 2) {
      setValue('economic_activity', {})
    }
  }, [watch_employment_status])

  const onSubmit = async (data: any) => {
    setLoadingSendData(true)
    let key: string | any
    let value: { id: string; description: string }[] | any = []
    const liquid_assets_distribution: any[] = []

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value })
      if (!value) {
        delete data[key]
      } else if (typeof value == 'object') {
        data[key] =
          value !== null && value.id
            ? typeof value.id !== 'string'
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value)
      }
    }

    JSON.parse(data.liquid_assets_distribution_form).forEach((e: any) => {
      liquid_assets_distribution.push(Number(e.liquid_assets_distribution_id.id))
    })
    data.liquid_assets_distribution = JSON.stringify(liquid_assets_distribution)

    let update = {}
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key)
      if (typeof value == 'object') {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== 'string'
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value)
        })
      } else {
        Object.assign(update, { [key]: value })
      }
      Object.assign(update, {
        liquid_assets_distribution: JSON.stringify(liquid_assets_distribution)
      })
    }

    const handleResponse = () => {
      handleNext && handleNext()
    }

    if (isDirty) {
      let fullUpData = {
        fields: {
          ...update
        },
        formType: 'F',
        userId: userID,
        stepForm: 'Confirmación de Perfil'
      }
      const updateData = await updateProfile(fullUpData)
      if (updateData.status === 200) {
        loadData && loadData(userID)
        loadTypeProfile && loadTypeProfile(userID)
        handleResponse()
      }
    } else {
      setLoadingSendData(false)
      handleResponse()
    }
  }

  return (
    <div>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <ButtonGradient type="button" onClick={() => setDisabledStep2(!disabledStep2)}>
          Editar datos
        </ButtonGradient>
      </Stack>
      <TitleForm sx={{ mb: 4 }}>Información Laboral & Financiera</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="employment_status"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                disabled={disabledStep2}
                options={allData.al_ta_employment_status}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Estado Laboral"
                      variant="standard"
                      autoFocus
                      error={!!errors.employment_status}
                      helperText={errors.employment_status && errors.employment_status.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {watch_employment_status?.id === 1 && (
          <>
            <Grid item sm={6} xs={12} sx={{ mt: '-8px' }}>
              <Controller
                control={control}
                name="ocupation"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Ocupación en la empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={disabledStep2}
                    multiline
                    rows={1}
                    maxRows={6}
                    {...register('ocupation')}
                    error={errors.ocupation && Boolean(errors.ocupation)}
                    helperText={errors.ocupation && errors.ocupation.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                name={'companyName'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre de la empresa"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={disabledStep2}
                    {...register('companyName')}
                    error={errors.companyName && Boolean(errors.companyName)}
                    helperText={errors.companyName && errors.companyName.message}
                  />
                )}
              />
            </Grid>
          </>
        )}
        {watch_employment_status?.id === 2 && (
          <Grid item sm={6} xs={12}>
            <Controller
              control={control}
              name="economic_activity"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue)
                  }}
                  disabled={disabledStep2}
                  options={allData.al_ta_economic_activity}
                  getOptionLabel={(option: any) => option.description || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  value={value}
                  renderInput={(params) => {
                    const inputProps = params.inputProps
                    inputProps.autoComplete = 'new-password'
                    return (
                      <TextField
                        {...params}
                        fullWidth
                        label="Actividad Económica"
                        variant="standard"
                        autoFocus
                        error={!!errors.economic_activity}
                        helperText={errors.economic_activity && errors.economic_activity.message}
                      />
                    )
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="financially_dependent_third_party"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Depende económicamente de un tercero?"
                      variant="standard"
                      error={!!errors.financially_dependent_third_party}
                      helperText={
                        errors.financially_dependent_third_party &&
                        errors.financially_dependent_third_party.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {watch_financially_dependent_third_party?.id === 'Sí' && (
        <>
          <Divider
            sx={{
              pt: 3,
              mb: 3,
              borderColor: '#efe5df',
              width: '50%',
              ml: 'auto',
              mr: 'auto'
            }}
          />
          <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                name={'name_lastname_third_party'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre y Apellidos"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={disabledStep2}
                    {...register('name_lastname_third_party')}
                    error={
                      errors.name_lastname_third_party && Boolean(errors.name_lastname_third_party)
                    }
                    helperText={
                      errors.name_lastname_third_party && errors.name_lastname_third_party.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="id_type_third_party"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={datos.id_type}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    disabled={disabledStep2}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de Documento"
                          variant="standard"
                          error={!!errors.id_type_third_party}
                          helperText={
                            errors.id_type_third_party && errors.id_type_third_party.message
                          }
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                name={'number_identity_third_party'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de Documento"
                    type="text"
                    variant="standard"
                    value={value}
                    disabled={disabledStep2}
                    {...register('number_identity_third_party')}
                    error={
                      errors.number_identity_third_party &&
                      Boolean(errors.number_identity_third_party)
                    }
                    helperText={
                      errors.number_identity_third_party &&
                      errors.number_identity_third_party.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={3} sm={6} xs={12}>
              <Controller
                control={control}
                name="relationship_third_party"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={datos.degrees_of_consanguinity}
                    getOptionLabel={(option: any) => option || ''}
                    disabled={disabledStep2}
                    isOptionEqualToValue={(option: any, value: any) => option === value}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Parentesco o Relación"
                          variant="standard"
                          error={!!errors.relationship_third_party}
                          helperText={
                            errors.relationship_third_party &&
                            errors.relationship_third_party.message
                          }
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Divider
            sx={{
              pt: 3,
              mb: 6,
              borderColor: '#efe5df',
              width: '50%',
              ml: 'auto',
              mr: 'auto'
            }}
          />
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Dirección
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="countryWorks"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                disabled={disabledStep2}
                options={allData && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="País"
                    variant="standard"
                    error={!!errors.countryWorks}
                    helperText={errors.countryWorks && errors.countryWorks.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        {watch_country?.id === 63 && (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="provinceWorks"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={address.provinces}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.provinceCode === value.provinceCode
                    }
                    disabled={disabledStep2}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Provincia"
                          variant="standard"
                          error={!!errors.provinceWorks}
                          helperText={!!errors.provinceWorks && 'Campo es requerido'}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="sectorWorks"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={sectorData?.length > 0 ? sectorData : []}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    disabled={disabledStep2}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sector"
                          variant="standard"
                          error={!!errors.sectorWorks}
                          helperText={!!errors.sectorWorks && 'Campo es requerido'}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'streetWorks'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Calle"
                type="text"
                variant="standard"
                autoComplete="streetWorks"
                value={value}
                disabled={disabledStep2}
                {...register('streetWorks')}
                error={errors.streetWorks && Boolean(errors.streetWorks)}
                helperText={errors.streetWorks && errors.streetWorks.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'houseApartNumberWorks'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="N° Calle / Apto"
                type="text"
                variant="standard"
                autoComplete="houseApartNumberWorks"
                value={value}
                disabled={disabledStep2}
                {...register('houseApartNumberWorks')}
                error={errors.houseApartNumberWorks && Boolean(errors.houseApartNumberWorks)}
                helperText={errors.houseApartNumberWorks && errors.houseApartNumberWorks.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'buildingWorks'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Edificio"
                type="text"
                variant="standard"
                autoComplete="buildingWorks"
                value={value}
                disabled={disabledStep2}
                {...register('buildingWorks')}
                error={errors.buildingWorks && Boolean(errors.buildingWorks)}
                helperText={errors.buildingWorks && errors.buildingWorks.message}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información financiera
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="averageAnnualIncome"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_annual_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Promedio de Ingresos Anuales"
                      variant="standard"
                      error={!!errors.averageAnnualIncome}
                      helperText={errors.averageAnnualIncome && errors.averageAnnualIncome.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="liquid_assets_total"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_total_liquid_assets}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total Activos Líquidos"
                      variant="standard"
                      error={!!errors.liquid_assets_total}
                      helperText={errors.liquid_assets_total && errors.liquid_assets_total.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_passives"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_total_passive}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Total Pasivos"
                      variant="standard"
                      error={!!errors.total_passives}
                      helperText={errors.total_passives && errors.total_passives.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="financialObligations"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_financial_obligations}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Obligaciones Financieras"
                      variant="standard"
                      error={!!errors.financialObligations}
                      helperText={
                        errors.financialObligations && errors.financialObligations.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="total_heritage"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_total_heritage}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Patrimonio Total"
                      variant="standard"
                      error={!!errors.total_heritage}
                      helperText={errors.total_heritage && errors.total_heritage.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Origen de fondos
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="sources_funds"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={allData.al_ta_sources_income}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Los recursos que entregué o entregaré para la inversión en uno o más fondos de inversión abiertos administrados por ALTIO provienen de las siguientes fuentes."
                      variant="standard"
                      error={!!errors.sources_funds}
                      helperText={errors.sources_funds && errors.sources_funds.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {watch_sources_funds?.id === 8 && (
          <Grid item xs={12}>
            <Controller
              name={'other_sources_funds'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Otro"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={disabledStep2}
                  {...register('other_sources_funds')}
                  error={errors.other_sources_funds && Boolean(errors.other_sources_funds)}
                  helperText={errors.other_sources_funds && errors.other_sources_funds.message}
                />
              )}
            />
          </Grid>
        )}
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información tributaria
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="axempt_agent_withholding"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Es usted un agente exento de retención u otros impuestos?"
                      variant="standard"
                      error={!!errors.axempt_agent_withholding}
                      helperText={
                        errors.axempt_agent_withholding && errors.axempt_agent_withholding.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {watch_axempt_agent_withholding?.id === 'Sí' && (
          <Grid item sm={6} xs={12}>
            <Controller
              name={'which_taxes'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="¿Cuáles?"
                  type="text"
                  variant="standard"
                  value={value}
                  disabled={disabledStep2}
                  {...register('which_taxes')}
                  error={errors.which_taxes && Boolean(errors.which_taxes)}
                  helperText={errors.which_taxes && errors.which_taxes.message}
                />
              )}
            />
          </Grid>
        )}
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="declare_income"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  if (newValue === null) {
                    newValue = ''
                  }
                  onChange(newValue)
                }}
                options={datos.selectBooleanString}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep2}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Declara Renta?"
                      variant="standard"
                      error={!!errors.declare_income}
                      helperText={errors.declare_income && errors.declare_income.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Distribución de activos líquidos
      </TitleForm>
      {liquid_assets_distributionField.map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 2,
                  mb: 2,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name={`liquid_assets_distribution_form.${index}.liquid_assets_distribution_id`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={allData.al_ta_liquid_assets_distribution}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      disabled={disabledStep2}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Seleccione Activo Líquido"
                          variant="standard"
                          error={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id && true
                          }
                          helperText={
                            errors?.liquid_assets_distribution_form?.[index]
                              ?.liquid_assets_distribution_id?.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress size={24} sx={{ margin: '5px auto', color: '#6fc555' }} />
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ mt: 1 }}
      >
        <Button
          type="button"
          variant="outlined"
          onClick={handleBack}
          sx={{
            width: { xs: '100%', sm: 'auto' },
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}
        >
          Regresar
        </Button>
        <ButtonGradient onClick={handleSubmit(onSubmit)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
          Continuar
        </ButtonGradient>
      </Stack>
    </div>
  )
}

export default JobsInformation
