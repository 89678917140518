import { createContext, useMemo, useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { getProfile, getAllData } from '../services/Services'

const initialState = {
  isLoading: false,
  errorMsg: '',
  successMsg: '',
  existProfile: false
}

export type modalDataTypes = {
  modalOpen: boolean
  modalType: string
  modalObject: null
}

interface requestType {
  isLoading: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
  errorMsg?: string
  setErrorMsg?: Dispatch<SetStateAction<string>>
  successMsg: string
  setSuccessMsg?: Dispatch<SetStateAction<string>>
  resetErrorMsg?: () => void
  resetSuccessMsg?: () => void
  modalData?: modalDataTypes
  setModalData?: Dispatch<SetStateAction<modalDataTypes>>
}

//define context
const AppContext = createContext<requestType>(initialState)
const { Provider } = AppContext

const AppProvider = ({ children, authData }: any) => {
  const [authInfo, setAuthInfo] = useState(authData)
  const [isLoading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [existProfile, setExistProfile] = useState(false)
  const [clientProfile, setProfileData] = useState({})
  const [modalData, setModalData] = useState({
    modalOpen: false,
    modalType: '',
    modalObject: null
  })

  const values: requestType = useMemo(
    () => ({
      isLoading,
      successMsg,
      setLoading,
      setErrorMsg,
      setSuccessMsg,
      modalData,
      setModalData,
      errorMsg
    }),
    // eslint-disable-next-line
    [isLoading, errorMsg, successMsg, modalData]
  )

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>
}

export { AppProvider }

export default AppContext
