import {
  Grid,
  Box,
  Button,
  Stack,
  styled,
  Typography,
  Container,
  useTheme,
  Link
} from '@mui/material'
import { useState, SyntheticEvent } from 'react'
import FancyInput from '../components/FancyInput'
import { useNavigate } from 'react-router-dom'
import { getRecoverPassword } from '../services/Services'

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

const StyledStack = styled(Stack)(({ theme }) => ({
  maxWidth: 450,
  margin: 'auto',
  background: '#fff',
  borderRadius: '25px',
  padding: theme.spacing(3),
  mb: theme.spacing(4),
  boxShadow:
    '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
}))

interface loginFormInitTypes {
  username: string
}

const loginFormInit = {
  username: ''
}

const RecoverPassword = () => {
  const [username, setUsername] = useState<loginFormInitTypes>(loginFormInit)
  const [loading, setLoading] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(undefined)
  const navigate = useNavigate()
  const theme = useTheme()

  const onChangeEmail = (e: any) => {
    const { value, name } = e.target
    setUsername({
      ...username,
      [name]: value
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    setResponse(undefined)
    const nuevaData = await getRecoverPassword(username)
    setResponse(nuevaData)
    setLoading(false)
  }

  return (
    <Grid container spacing={0} sx={{ backgroundColor: '#f8f5f3', minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Stack justifyContent={'center'} alignItems={'center'} minHeight={'100vh'}>
            <img src="/logos/altio.svg" alt="ALTIO" width={250} style={{ marginBottom: 20 }} />
            <Box
              component={'form'}
              onSubmit={onSubmit}
              width={'100%'}
              maxWidth={450}
              sx={{
                background: '#fff',
                borderRadius: '25px',
                padding: theme.spacing(3),
                mb: theme.spacing(4),
                boxShadow:
                  '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
              }}
            >
              <Typography variant="h5" color="primary" align="center" sx={{ mb: 2 }}>
                Recuperar Contraseña
              </Typography>

              <FancyInput
                required
                type={'email'}
                name="username"
                label={'Email'}
                variant="standard"
                value={username.username}
                onChange={onChangeEmail}
                validation={[
                  {
                    validate: () => isValidEmail(username.username),
                    msg: 'correo invalido'
                  }
                ]}
              />
              {response && (
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ margin: `${theme.spacing(2)} 0` }}
                >
                  {response.estatus === 400 ? response.body.fieldErrors[0] : response.body.info}
                </Typography>
              )}
              <Stack justifyContent={'center'} direction={'row'} sx={{ mt: 2 }}>
                <Button type="submit" variant="contained" fullWidth>
                  Recuperar Contraseña
                </Button>
              </Stack>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Link sx={{ color: '#00000080', textDecorationColor: '#00000080', mt: 1 }} href="/">
                  Regresar a Login
                </Link>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

export default RecoverPassword
