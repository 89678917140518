import axios from 'axios'
import { basesURL } from '../utils/UrlConstant'
import { CONSTANT } from '../utils/Constants'

const publicFetch = basesURL.app_base_url + basesURL.api_context

const apiUser = process.env.REACT_APP_API_USER
const apiMaintenance = process.env.REACT_APP_API_MAINTENANCE
const apiInvestmentprofile = process.env.REACT_APP_API_MAINTENANCE_INVESMENTPROFILE
const apiDocuments = process.env.REACT_APP_API_DOCUMENTS

enum Method {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
  PATCH = 'PATCH'
}

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const HEADERS = new Headers({
  Accept: 'application/json',
  'Content-Type': 'application/json'
})

const user = 'user/'
const tables = 'dataTables/'
const tablesJuridic = 'dataTablesJuridic/'
let documents = 'documents/'
let profile = 'profile/'

export const apis = {
  user: {
    CRUD: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}`,
    create: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}createForm`,
    createAdmin: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}createAdmin`,
    login: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}login/twofactor`,
    autenticate: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}autenticate`,
    activate: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}activate`,
    recoverPassword: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}recoverPasswordAdmin`,
    changePassword: `${publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user}changePassword`,
    resendRecoverPassword: `${
      publicFetch + CONSTANT.DISPATCHER_USER_PREFIX + user
    }resend-notification`
  },
  tables: {
    getTables: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE + tables}`,
    getTablesJuridic: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE + tablesJuridic}`
  },
  table: {
    getTable: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}`
  },
  investment: {
    profile: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}profile/`,
    users: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}profile/users/`,
    calculate: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}profile/calculate/`,
    kyc: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents}pdf/formulario/`,
    perfil: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents}pdf/perfil/`,
    matriz: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents}pdf/matriz/`,
    contract: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents}pdf/contract/`,
    accountRegister: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents
    }pdf/account-register/`,
    authorizedSignatures: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents
    }pdf/authorized-signatures/`,
    questionnaireProfile: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + documents
    }pdf/questionnaire-profile/`
  },
  investmentProfile: {
    CRUD: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile}`,
    update: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile}update`,
    calculate: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE + profile}calculate/`,
    kyc: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}documents/pdf/formulario/`,
    perfil: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}documents/pdf/perfil/`,
    notification: `${publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE}notification/`,
    accountRegister: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/account-register/`,
    authorizedSignatures: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/authorized-signatures/`,
    questionnaireProfile: `${
      publicFetch + CONSTANT.DISPATCHER_INVESTMENT_PROFILE
    }documents/pdf/questionnaire-profile/`
  },
  maintenance: {
    allData: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}allData/`,
    provinces: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}provinces/?size=100`,
    municipality: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}municipality/`,
    sector: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}sectors/?size=600`,
    countries: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}countries/?page=0&size=238`,
    metamap: `${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}metamap/`
  },
  documents: {
    categoryDocuments: documents + `documents/category?page=0&size=10`,
    deleteDocuments: documents + `documents/delete`,
    downloadDocuments: publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + documents + `download`,
    viewdDocuments: publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + documents + `/view`,
    uploadDocuments: documents + `documents/upload`,
    documentsByClient: publicFetch + CONSTANT.DISPATCHER_DOCUMENTS + `documents`
  }
}

export const getTwoFactorAutenticate = async (userData: any) => {
  console.log(userData)
  let requestBody
  if (userData.code === '') {
    requestBody = {
      password: userData.password,
      userPortal: 'Administrativo',
      username: userData.username
    }
  } else {
    requestBody = {
      code: userData.code,
      password: userData.password,
      userPortal: 'Administrativo',
      username: userData.username
    }
  }
  try {
    const getApi = await fetch(userData.code === '' ? apis.user.login : apis.user.autenticate, {
      method: Method.POST,
      headers: HEADERS,
      //body: JSON.stringify(userData)
      body: JSON.stringify(requestBody)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
    //return response
  } catch (error) {
    console.log(error)
  }
}

export const getTables = async (pagination: string, tableName?: string | undefined) => {
  try {
    const getApi = await fetch(
      tableName
        ? `${apis.table.getTable}${tableName}/${pagination}`
        : `${apis.tables.getTables}${pagination}`
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error: any) {
    console.log(error)
    return { body: error.message, statusText: 'Error de conexión' }
  }
}

export const getTablesJuridic = async (pagination: string, tableName?: string | undefined) => {
  try {
    const getApi = await fetch(
      tableName
        ? `${apis.table.getTable}${tableName}/${pagination}`
        : /*  : `${apis.tables.getTables}${pagination}` */
          `${apis.tables.getTablesJuridic}${pagination}`
    )
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error: any) {
    console.log(error)
    return { body: error.message, statusText: 'Error de conexión' }
  }
}

export const editRubro = async (rubro: any, itemData: any) => {
  try {
    const getApi = await fetch(publicFetch + CONSTANT.DISPATCHER_MAINTENANCE + rubro + '/', {
      method: Method.PUT,
      headers: HEADERS,
      body: JSON.stringify(itemData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const newRubro = async (rubro: any, itemData: any) => {
  try {
    const getApi = await fetch(publicFetch + CONSTANT.DISPATCHER_MAINTENANCE + rubro + '/create', {
      method: Method.PUT,
      headers: HEADERS,
      body: JSON.stringify(itemData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const deleteRubro = async (rubro: any, id: any) => {
  try {
    const getApi = await fetch(`${publicFetch + CONSTANT.DISPATCHER_MAINTENANCE}${rubro}/${id}/`, {
      method: Method.DELETE,
      headers: HEADERS
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getUsers = async (pagination?: string | undefined) => {
  try {
    const getApi = await fetch(`${apis.user.CRUD}${pagination}`)
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getRecoverPassword = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.recoverPassword, {
      method: Method.POST,
      headers: HEADERS,
      body: JSON.stringify(userData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getChangePassword = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.changePassword, {
      method: Method.PUT,
      headers: HEADERS,
      body: JSON.stringify(userData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const newUser = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.create, {
      method: Method.POST,
      headers: HEADERS,
      body: JSON.stringify(userData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}
export const newUserAdmin = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.createAdmin, {
      method: Method.POST,
      headers: HEADERS,
      body: JSON.stringify(userData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const editUser = async (userData: any) => {
  try {
    const getApi = await fetch(apis.user.CRUD, {
      method: Method.PUT,
      headers: HEADERS,
      body: JSON.stringify(userData)
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const deleteUser = async (id: any) => {
  try {
    const getApi = await fetch(apis.user.CRUD + id, {
      method: Method.DELETE,
      headers: HEADERS
    })
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const activateUsers = async (code: any, username: any) => {
  try {
    const getApi = await fetch(`${apis.user.activate}?code=${code}&username=${username}`)
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getProfile = async (id?: string | undefined) => {
  try {
    const getApi = await fetch(`${apis.investment.profile}${id}`)
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getProfileUsers = async (pagination?: string | undefined) => {
  try {
    const getApi = await fetch(`${apis.investment.profile}${pagination}`)
    const response = await getApi.json()
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response,
      totalElements: response.totalElements
    }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getCalculateProfile = async (id?: string | undefined) => {
  try {
    const getApi = await fetch(`${apis.investment.calculate}${id}`)
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getAllDataProfile = async (id?: string | undefined) => {
  try {
    const getApi = await fetch(`${apis.investment.calculate}${id}`)
    const response = await getApi.json()
    return { status: getApi.status, statusText: getApi.statusText, body: response }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getAllData = async (type: string) => {
  try {
    const getApi = await fetch(`${apis.maintenance.allData}?type=${type}`)
    const response = await getApi.json()
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response
    }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getAddressData = async () => {
  try {
    const getApiP = await fetch(apis.maintenance.provinces)
    const provinces = await getApiP.json()

    const getApiM = await fetch(apis.maintenance.municipality)
    const municipality = await getApiM.json()

    const getApiS = await fetch(apis.maintenance.sector)
    const sector = await getApiS.json()

    return {
      statusP: getApiP.status,
      statusTextP: getApiP.statusText,
      provinces: provinces,

      statusM: getApiM.status,
      statusTextM: getApiM.statusText,
      municipality: municipality,

      statusS: getApiP.status,
      statusTextS: getApiP.statusText,
      sector: sector
    }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getAddressSector = async (provinceID: string) => {
  let responseData = {}
  const params = new URLSearchParams([
    ['provinceId', provinceID],
    ['page', '0'],
    ['size', '1000']
  ])
  await axios
    .get(apis.maintenance.sector, { params })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch((error) => {
      throw new Error('Error en consulta de sectores')
    })
  return responseData
}

export const getKYC_fisico = async (id?: string | undefined) => {
  await fetch(`${apis.investment.kyc}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'conozca-su-cliente-persona-fisica.pdf'
      alink.click()
    })
  })
}

export const getKYC_juridico = async (id?: string | undefined) => {
  await fetch(`${apis.investment.kyc}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'conozca-su-cliente-persona-juridica.pdf'
      alink.click()
    })
  })
}

export const getPerfil = async (id?: string | undefined) => {
  await fetch(`${apis.investment.perfil}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'evaluacion-del-perfil.pdf'
      alink.click()
    })
  })
}

export const getMatriz = async (id?: string | undefined) => {
  await fetch(`${apis.investment.matriz}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'matriz-de-riesgo.pdf'
      alink.click()
    })
  })
}

export const getContract = async (id?: string | undefined, idAltioAgent?: string | undefined) => {
  await fetch(`${apis.investment.contract}${id}/${idAltioAgent}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Contrato_Marco_Suscripción_Fondo_Abierto_ALTIO_PF.pdf'
      alink.click()
    })
  })
}

export const getCountries = async () => {
  try {
    const getApi = await fetch(`${apis.maintenance.countries}`)
    const response = await getApi.json()
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response
    }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}
export const getMetamap = async (data: any) => {
  try {
    const params = new URLSearchParams([
      ['birthYear', new Date(data.birthYear).getFullYear()],
      ['countryCode', data.countryCode.code],
      ['entityType', data.entityType],
      ['name', data.name]
    ])
    const getApi = await fetch(`${apis.maintenance.metamap}?${params}`)
    const response = await getApi.json()
    return {
      status: getApi.status,
      statusText: getApi.statusText,
      body: response
    }
  } catch (error) {
    console.log(error)
    return { body: error }
  }
}

export const getAccountRegister = async (id?: string | undefined) => {
  await fetch(`${apis.investment.accountRegister}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Registro-de-cuentas-bancarias.pdf'
      alink.click()
    })
  })
}

export const getAuthorizedSignatures = async (id?: string | undefined) => {
  await fetch(`${apis.investment.authorizedSignatures}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Tarjeta-de-firmas.pdf'
      alink.click()
    })
  })
}

export const getQuestionnaireProfile = async (id?: string | undefined) => {
  await fetch(`${apis.investment.questionnaireProfile}${id}`).then((response) => {
    response.blob().then((blob) => {
      const fileURL = window.URL.createObjectURL(blob)
      let alink = document.createElement('a')
      alink.href = fileURL
      alink.download = 'Cuestionario-perfil-de-riesgo.pdf'
      alink.click()
    })
  })
}

export const resendNotificationActivateUser = async (username: any) => {
  let responseData = {}
  console.log(username)

  await axios
    .post(`${apis.user.resendRecoverPassword}?username=${username}`, {
      headers: HEADERS
    })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error.response.data.message)
    })
  return responseData
}
//List Documents by Client
export const getRequestDocumentClient = async (id: any, categoryType?: string | null) => {
  let responseData = {}
  const params = new URLSearchParams([
    ['customer', id],
    [categoryType !== null && 'categoryType', categoryType]
  ])
  await axios
    .get(apis.documents.documentsByClient, { params })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch((error) => {
      throw new Error('Error en consulta de documentos por cliente')
    })
  return responseData
}
//Delete Documents
export const deleteDocuments = async (id: string, user: string) => {
  let responseData = {}
  const params = new URLSearchParams([
    ['id', id],
    ['user', user]
  ])
  await axios
    .delete(apis.documents.deleteDocuments, { params })
    .then(async (response) => {
      responseData = await response.data
    })
    .catch(async (error) => {
      throw new Error(error.response)
    })
  return responseData
}
//View Documents
export const getDocumentBlank = async (idDocument: string, nameDocument: string) => {
  let responseData: any = {}
  const params = new URLSearchParams([['id', idDocument]])
  await axios
    .get(apis.documents.downloadDocuments, {
      params,
      responseType: 'blob'
    })
    .then(async (response) => {
      responseData = await response.data
      const url = window.URL.createObjectURL(new Blob([responseData]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', nameDocument)
      document.body.appendChild(link)
      link.click()
    })
    .catch(async (error) => {
      throw new Error('Error al ver documento')
    })
  return responseData
}
//View Documents Image
export const getDocumentImage = async (idDocument: string, nameDocument: string) => {
  let responseData: any = {}
  const params = new URLSearchParams([['id', idDocument]])
  await axios
    .get(apis.documents.downloadDocuments, {
      params,
      responseType: 'blob'
    })
    .then(async (response) => {
      responseData = await response.data
      const url = window.URL.createObjectURL(new Blob([responseData]))
    })
    .catch(async (error) => {
      throw new Error('Error al ver documento')
    })
  return responseData
}
/*
Update Profile
***/
export const saveProfile = async (userData: any) => {
  try {
    const response = await axios.post(apis.investmentProfile.CRUD, userData, {
      headers
    })

    return {
      status: response.status,
      message: response.data.message,
      body: response.data
    }
  } catch (e: any) {
    return {
      messageError: e.response.data.message
    }
  }
}

export const updateProfile = async (userData: any) => {
  try {
    const response = await axios.post(apis.investmentProfile.update, userData, {
      headers
    })

    return {
      status: response.status,
      message: response.data.message,
      body: response.data
    }
  } catch (e: any) {
    return {
      messageError: e.response.data.message
    }
  }
}
