import { createContext, ReactNode, useState } from 'react'
import { useCookies } from 'react-cookie'

const UserDataContext = createContext<any>(undefined)

const UserDataProvider = ({ children }: { children: ReactNode }) => {
  const [cookies] = useCookies(['a_user'])
  const [dataUser, setDataUser] = useState<any>(cookies.a_user ? cookies.a_user : undefined)

  const globalState = { dataUser, setDataUser }

  return <UserDataContext.Provider value={globalState}>{children}</UserDataContext.Provider>
}

export { UserDataProvider }
export default UserDataContext
