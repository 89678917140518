import { useEffect, useState } from 'react'
import { DialogContent, DialogTitle, styled, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PdfView from './PdfView'
import { getDocumentBlank, getDocumentImage } from '../../services/Services'
import { useApp } from '../../hooks/useApp'
import { apis } from '../../services/Services'

const Image = styled('img')(() => ({
  maxWidth: '600px',
  margin: '0 auto',
  display: 'block'
}))

interface DialogProps {
  document: any
  cancelModal: any
}

const FileViewModal = ({ document, cancelModal }: DialogProps, props: any) => {
  const { setErrorMsg } = useApp()
  const [image, setImage] = useState<any>(null)

  //@ts-ignore
  const urlBase = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_API_CONTEXT //@ts-ignore

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchImage(document.id, document.nameD)
    }
    dataInit()
  }, [])

  const handleFetchImage = async (id: any, name?: any) => {
    try {
      let documentByClient: any = null
      documentByClient = await getDocumentImage(id, name)
      if (documentByClient) {
        setImage(documentByClient)
      }
    } catch (error: any) {
      setErrorMsg && setErrorMsg(error.message)
    }
  }

  return (
    <>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={cancelModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 8 }}>
        {document?.ext === 'docx' || document?.ext === 'doc' || document?.ext === 'xlsx' ? (
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${
              urlBase + apis.documents.downloadDocuments
            }?systemFileName${document.nameD}`}
            width="100%"
            height="800px"
          ></iframe>
        ) : document?.ext === 'pdf' ? (
          <PdfView pdf={`${urlBase + apis.documents.downloadDocuments}/?${document.nameD}`} />
        ) : document?.ext === 'jpg' || document?.ext === 'jpeg' || document?.ext === 'png' ? (
          image && <Image src={image} /* ref={imageRef} */ />
        ) : (
          <Typography variant="body1">Archivo no soportado por el visor</Typography>
        )}
      </DialogContent>
    </>
  )
}

export default FileViewModal
