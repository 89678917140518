import { Route, Routes } from 'react-router-dom'
import DashboardMaintenance from '../views/DashboardMaintenance'
import DashboardClient from '../views/DashboardClient'
import DashboardClientProfile from '../layouts/DashboardClientProfile'
import LegalClient from '../views/legalClient/LegalClient'
import PhysicalClient from '../views/physicalClient/PhysicalClient'
import Login from '../views/Login'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import Rubro from '../views/Rubro'
import UserManage from '../views/UserManage'
import ApiMaintenance from '../views/apis/ApiMaintenance'
import ApiInvestment from '../views/apis/ApiInvestment'
import ApiUsers from '../views/apis/ApiUsers'
import ApiAudit from '../views/apis/ApiAudit'
import ApiNotification from '../views/apis/ApiNotification'
import ActiveRegister from '../views/ActiveRegister'
import RecoverPassword from '../views/RecoverPassword'
import ForgotPassword from '../views/ForgotPassword'
import DashboardLayout from '../layouts/DashboardLayout'
import PhysicallPerson from '../views/PhysicallPersonTab'
import LegalPerson from '../views/LegalPersonTab'
import MetaMap from '../views/MetaMap'

const AppRoutes = () => (
  <Routes>
    <Route
      path="/"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
    <Route
      path="/dashboard-maintenance"
      element={
        <PrivateRoute>
          <DashboardMaintenance />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/physical-person"
      element={
        <PrivateRoute>
          <PhysicallPerson />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/legal-person"
      element={
        <PrivateRoute>
          <LegalPerson />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/:person/:rubro"
      element={
        <PrivateRoute>
          <Rubro />
        </PrivateRoute>
      }
    />
    <Route
      path="/dashboard-client"
      element={
        <PrivateRoute>
          <DashboardClient />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="client/"
      element={
        <PrivateRoute>
          <DashboardClientProfile />
        </PrivateRoute>
      }
    >
      <Route path="physical/:id" element={<PhysicalClient />} />
      <Route path="legal/:id" element={<LegalClient />} />
    </Route>

    <Route
      path="/usermanage"
      element={
        <PrivateRoute>
          <UserManage />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/metaMap"
      element={
        <PrivateRoute>
          <MetaMap />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/api-maintenance"
      element={
        <PrivateRoute>
          <ApiMaintenance />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/api-audit"
      element={
        <PrivateRoute>
          <ApiAudit />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/api-investment"
      element={
        <PrivateRoute>
          <ApiInvestment />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/api-users"
      element={
        <PrivateRoute>
          <ApiUsers />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/api-notification"
      element={
        <PrivateRoute>
          <ApiNotification />
        </PrivateRoute>
      }
    ></Route>
    <Route
      path="/active-register/:code/:username"
      element={
        <PublicRoute>
          <ActiveRegister />
        </PublicRoute>
      }
    ></Route>
    <Route
      path="/recover-password"
      element={
        <PublicRoute>
          <RecoverPassword />
        </PublicRoute>
      }
    ></Route>
    <Route
      path="/forgot-password/:code/:username"
      element={
        <PublicRoute>
          <ForgotPassword />
        </PublicRoute>
      }
    ></Route>
    <Route
      path="*"
      element={
        <PublicRoute>
          <Login />
        </PublicRoute>
      }
    />
  </Routes>
)

export default AppRoutes
