import Tables, { TableHeadItem } from '../components/Tables'
import {
  Box,
  Tabs,
  Tab,
  TableCell,
  TableRow,
  Stack,
  Typography,
  useTheme,
  IconButton
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useLayoutEffect, useState } from 'react'
import { getTables } from '../services/Services'
import Progress from '../components/Progress'
import FancyInput from '../components/FancyInput'
import { useSearcher } from '../hooks/useSearcher'

/* interface dataTables {
  tableName: string
  numberData: string
  modified: string
  endPointName: string
} */

interface LinkTabProps {
  label?: string
  href?: string
}

function ariaProps(person: string) {
  return {
    id: `tab-${person}`,
    'aria-controls': `tabpanel-${person}`
  }
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const Dashboard = (): JSX.Element => {
  const theme = useTheme()
  let url = useLocation()
  const [valueTab, setValueTab] = useState(0)

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setValueTab(newValue)
  }

  let location = url.pathname.replace('/', '')
  /* const [tables, setTables] = useState<any>(undefined)
  const [apiResponse, setApiResponse] = useState<any>(undefined)
  //const { searchData, InputSearcher, inputSearch } = useSearcher(tables, 'tableName')
  const navigate = useNavigate()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [inputSearch, setInputSearch] = useState<string>('')
  const [btnSort, setBtnSort] = useState<string>('')
  const [sortAsc, setSortAsc] = useState(true)
  const [btnSearch, setBtnSearch] = useState<string>('')

  useLayoutEffect(() => {
    let isMounted = true
    setApiResponse(false)
    setBtnSort('')
    const asyncEffect = async () => {
      if (isMounted) {
        const getData =
          inputSearch === ''
            ? await getTables(`?page=${page}&size=${rowsPerPage}`)
            : await getTables(
                `{tableName}?tableName=${encodeURI(inputSearch)}&page=${page}&size=${rowsPerPage}`
              )
        setApiResponse(getData)
        setTables(getData.body.content)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [page, rowsPerPage, btnSearch])

  const sortTable = async (allData: any, nameCol: string, order: boolean) => {
    setTables([])
    setBtnSort(nameCol)
    const sortedData = await allData.sort((a: any, b: any) => {
      if (b[nameCol] < a[nameCol]) {
        return order ? 1 : -1
      }
      if (b[nameCol] > a[nameCol]) {
        return order ? -1 : 1
      }
      return 0
    })
    setSortAsc(order)
    setTables(sortedData)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  } */

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={location !== 'physical-person' ? 1 : valueTab}
          onChange={handleChangeTab}
          aria-label="person tabs"
        >
          <Tab
            label="Persona Física"
            component={Link}
            to={'/physical-person'}
            {...ariaProps('physical-person')}
          />
          <Tab
            label="Persona Jurídica"
            component={Link}
            to={'/legal-person'}
            {...ariaProps('legal-person')}
          />
        </Tabs>
      </Box>
      {/* <Typography
        variant="body1"
        color="textSecondary"
        mb={2}
        sx={{ '& a': { color: theme.palette.text.secondary, textDecoration: 'none' } }}
      >
        <Link to={'/dashboard-maintenance'}>Inicio</Link> {'>'} Tabla de Rubros
      </Typography>
      {apiResponse ? (
        apiResponse.status === 200 ? (
          <>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
              mb={2}
            >
              <Typography variant="h5">Tabla de Rubros</Typography>
              <Stack direction={'row'} alignItems={'center'}>
                <FancyInput
                  fullWidth={false}
                  variant="standard"
                  value={inputSearch}
                  label={'Buscar...'}
                  autoFocus
                  onChange={(e) => {
                    setInputSearch(e.target.value)
                    if (e.target.value === '') {
                      setBtnSearch(e.target.value)
                    }
                  }}
                  onKeyDown={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`)
                    if (ev.key === 'Enter') {
                      setBtnSearch(inputSearch)
                    }
                  }}
                />
                <IconButton aria-label="search" onClick={() => setBtnSearch(inputSearch)}>
                  <SearchIcon />
                </IconButton>
              </Stack>
            </Stack>
            <Tables
              hiddenPagination={btnSearch !== ''}
              count={
                apiResponse.body.totalElements
                  ? apiResponse.body.totalElements
                  : apiResponse.body.content.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowHead={['tableName', 'numberData', 'modified'].map((item, i) => (
                <TableHeadItem
                  key={i}
                  ml={i !== 0 ? 'auto' : 0}
                  sx={{ pr: item === btnSort ? 0 : 3, cursor: 'pointer', userSelect: 'none' }}
                  name={item}
                  arrowsHidden={item !== btnSort}
                  modeSort={sortAsc}
                  onClick={() => sortTable(tables, item, btnSort === item ? !sortAsc : sortAsc)}
                />
              ))}
              alignTable="right"
            >
              {tables.length > 0 ? (
                tables.map((item: dataTables, i: any) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={i}
                    onClick={() => navigate(`/${item.endPointName}`)}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  >
                    <TableCell component="th" scope="row">
                      {item.tableName}
                    </TableCell>
                    <TableCell align="right">{item.numberData}</TableCell>
                    <TableCell align="right">
                      {new Date(item.modified).toLocaleString('en-GB', { timeZone: 'UTC' })}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                >
                  <TableCell colSpan={3}>El elemento buscado no existe</TableCell>
                </TableRow>
              )}
            </Tables>
          </>
        ) : (
          <Typography variant="h5" color={'error'} align={'center'} my={10}>
            {apiResponse.status
              ? `Error ${apiResponse.status}: ${apiResponse.error}`
              : apiResponse.statusText}
          </Typography>
        )
      ) : (
        <Progress />
      )} */}
    </>
  )
}

export default Dashboard
