import Tables, { TableHeadItem } from '../components/Tables'
import Typography from '@mui/material/Typography'
import { Stack, Box, TableCell, TableRow, useTheme, IconButton } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useNavigate } from 'react-router-dom'
import { useLayoutEffect, useState } from 'react'
import { getTablesJuridic } from '../services/Services'
import Progress from '../components/Progress'
import FancyInput from '../components/FancyInput'
import DashboardMaintenance from './DashboardMaintenance'

interface dataTables {
  tableName: string
  numberData: string
  modified: string
  endPointName: string
}

const LegalPersonTab = () => {
  const theme = useTheme()
  const [tables, setTables] = useState<any>(undefined)
  const [apiResponse, setApiResponse] = useState<any>(undefined)
  const [inputSearch, setInputSearch] = useState<string>('')
  const [btnSort, setBtnSort] = useState<string>('')
  const [sortAsc, setSortAsc] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [btnSearch, setBtnSearch] = useState<string>('')
  const navigate = useNavigate()

  useLayoutEffect(() => {
    let isMounted = true
    setApiResponse(false)
    setBtnSort('')
    const asyncEffect = async () => {
      if (isMounted) {
        const getData =
          inputSearch === ''
            ? await getTablesJuridic(`?page=${page}&size=${rowsPerPage}`)
            : await getTablesJuridic(
                `{tableName}?tableName=${encodeURI(inputSearch)}&page=${page}&size=${rowsPerPage}`
              )
        setApiResponse(getData)
        setTables(getData.body.content)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [page, rowsPerPage, btnSearch])

  const sortTable = async (allData: any, nameCol: string, order: boolean) => {
    setTables([])
    setBtnSort(nameCol)
    const sortedData = await allData.sort((a: any, b: any) => {
      if (b[nameCol] < a[nameCol]) {
        return order ? 1 : -1
      }
      if (b[nameCol] > a[nameCol]) {
        return order ? -1 : 1
      }
      return 0
    })
    setSortAsc(order)
    setTables(sortedData)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Typography
        variant="body1"
        color="textSecondary"
        mb={2}
        sx={{ '& a': { color: theme.palette.text.secondary, textDecoration: 'none' } }}
      >
        Inicio {'>'} Tabla de Rubros Persona Jurídica
      </Typography>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} mb={2}>
        <Typography variant="h5">Tabla de Rubros</Typography>
        <Stack direction={'row'} alignItems={'flex-end'}>
          <FancyInput
            fullWidth={false}
            variant="standard"
            value={inputSearch}
            label={'Buscar...'}
            onChange={(e) => {
              setInputSearch(e.target.value)
              if (e.target.value === '') {
                setBtnSearch(e.target.value)
              }
            }}
            onKeyDown={(ev) => {
              console.log(`Pressed keyCode ${ev.key}`)
              if (ev.key === 'Enter') {
                setBtnSearch(inputSearch)
              }
            }}
          />
          <IconButton aria-label="search" onClick={() => setBtnSearch(inputSearch)}>
            <SearchIcon />
          </IconButton>
        </Stack>
      </Stack>
      <DashboardMaintenance />
      {apiResponse ? (
        apiResponse.status === 200 ? (
          <>
            <Tables
              hiddenPagination={btnSearch !== ''}
              count={
                apiResponse.body.totalElements
                  ? apiResponse.body.totalElements
                  : apiResponse.body.content.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowHead={['tableName', 'numberData', 'modified'].map((item, i) => (
                <TableHeadItem
                  key={i}
                  ml={i !== 0 ? 'auto' : 0}
                  mr={i !== 0 ? 'auto' : 0}
                  sx={{ pr: 0, cursor: 'pointer', userSelect: 'none', position: 'relative' }}
                  name={item}
                  arrowsHidden={item !== btnSort}
                  modeSort={sortAsc}
                  onClick={() => sortTable(tables, item, btnSort === item ? !sortAsc : sortAsc)}
                />
              ))}
            >
              {tables.length > 0 ? (
                tables.map((item: dataTables, i: number) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={i}
                    onClick={() => navigate(`/legal/${item.endPointName}`)}
                    /* onClick={() => navigate(`/${item.endPointName}`)} */
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                  >
                    <TableCell component="th" scope="row">
                      {item.tableName}
                    </TableCell>
                    <TableCell align="center">{item.numberData}</TableCell>
                    <TableCell align="center">
                      {new Date(item.modified).toLocaleString('en-GB', { timeZone: 'UTC' })}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                >
                  <TableCell colSpan={3}>El elemento buscado no existe</TableCell>
                </TableRow>
              )}
            </Tables>
          </>
        ) : (
          <Typography variant="h5" color={'error'} align={'center'} my={10}>
            {apiResponse.status
              ? `Error ${apiResponse.status}: ${apiResponse.error}`
              : apiResponse.statusText}
          </Typography>
        )
      ) : (
        <Progress />
      )}
    </>
  )
}

export default LegalPersonTab
