import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  StackProps,
  Stack,
  Box
} from '@mui/material'
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import { en_es } from '../utils/I18n'

const TableHeadStyled = styled(TableHead)(({ theme }) => ({
  '& th': {
    fontWeight: 700,
    fontSize: 16
  }
}))

const TableBodyStyled = styled(TableBody)(({ theme }) => ({
  '& tr:hover': {
    backgroundColor: '#b9dbbd!important'
  }
}))

interface TablesTypes {
  rowHead: any
  count: number
  rowsPerPage: number
  page: number
  onPageChange: any
  onRowsPerPageChange: any
  children: any
  hiddenPagination: boolean
  alignTable?: any
  alignRow?: string
}

const Tables = ({
  rowHead,
  children,
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  hiddenPagination,
  alignTable,
  alignRow
}: TablesTypes) => {
  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 220px)' }}>
          <Table stickyHeader sx={{ minWidth: 700, padding: '0 20px ' }} aria-label="simple table">
            <TableHeadStyled>
              <TableRow>{rowHead}</TableRow>
            </TableHeadStyled>
            <TableBodyStyled>{children}</TableBodyStyled>
          </Table>
        </TableContainer>
      </Paper>
      {!hiddenPagination && (
        <TablePagination
          labelRowsPerPage={'Filas por páginas'}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
        />
      )}
    </>
  )
}

interface TableHeadItemTypes extends StackProps {
  name: any
  arrowsHidden: any
  modeSort: any
  onClick: any
}

export const TableHeadItem = ({
  name,
  arrowsHidden,
  modeSort,
  onClick,
  ...rest
}: TableHeadItemTypes) => {
  return (
    <TableCell>
      <Stack direction={'row'} width={'fit-content'} onClick={onClick} sx={{ pr: 0 }} {...rest}>
        {en_es[name] ? en_es[name] : name}
        <Box
          component={'span'}
          hidden={arrowsHidden}
          sx={{ lineHeight: 0, position: 'absolute', right: '-25px' }}
        >
          {modeSort ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </Box>
      </Stack>
    </TableCell>
  )
}

export default Tables
