import { FC, useEffect, useState } from 'react'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Stack,
  Grid,
  Button,
  IconButton,
  Typography,
  TextField,
  Divider,
  Autocomplete,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  FormLabel,
  Switch,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'
import InputLabelToForm from '../../components/InputLabelToForm'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationAdditionalDataPhysical } from '../legalClient/validation'
import { AdditionalDataType } from '../../types/types'
import * as Yup from 'yup'
import { phoneRegExp, formSchemaOptions } from '../../utils'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'
import { updateProfile } from '../../services/Services'
import ButtonGradient from '../../components/ButtonGradient'

const AdditionalData = () => {
  const {
    data,
    allData,
    address,
    handleBack,
    handleNext,
    loadTypeProfile,
    loadData,
    getSectorAddress
  } = useClient()
  const [loadingSendData, setLoadingSendData] = useState(false)
  const [disabledStep3, setDisabledStep3] = useState(true)
  const [errorRefAddBankAccounts, setErrorRefAddBankAccounts] = useState('')
  const miarray: any = []
  const userID = data.body?.userId
  const countryASC =
    allData &&
    allData.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1
      }
    })
  const findData = data.body?.fields

  const defaultValues: AdditionalDataType = {
    openInvestmentFund: findData.openInvestmentFund ?? '',
    refAddBankAccounts: (findData.refAddBankAccounts &&
      JSON.parse(findData.refAddBankAccounts)) ?? [
      {
        entity: [],
        accountType: [],
        accountNumber: '',
        currency_preference: []
      }
    ],
    nameContributor: findData.nameContributor ? findData.nameContributor : '',
    newOrModificationSignature: findData.newOrModificationSignature
      ? findData.newOrModificationSignature
      : '',
    authorizedSignatures: (findData.authorizedSignatures &&
      JSON.parse(findData.authorizedSignatures)) ?? [
      {
        name: findData?.name !== '' ? findData.name + ' ' + findData.lastName : '',
        identification: findData.numberIdentity
      }
    ],
    confirmSignatory:
      findData.confirmSignatory && findData.confirmSignatory === 'true' ? true : false,
    entity_have_links_with_altio: findData.entity_have_links_with_altio
      ? datos.selectBoolean.find((f: any) => f.id === findData.entity_have_links_with_altio)
      : [],
    entity_have_links_with_altio_data: (findData.entity_have_links_with_altio_data &&
      JSON.parse(findData.entity_have_links_with_altio_data)) ?? [
      {
        name_or_social_denomination: '',
        relation_with_entity: ''
      }
    ],

    entity_has_a_representative: findData.entity_has_a_representative
      ? findData.entity_has_a_representative
      : '',
    name_representative: findData.name_representative ? findData.name_representative : '',
    id_type_representative: findData.id_type_representative
      ? datos.id_type.find((f: any) => f.id === findData.id_type_representative)
      : {},
    altio_customer_representative: findData.altio_customer_representative
      ? findData.altio_customer_representative
      : '',
    document_number_representative: findData.document_number_representative
      ? findData.document_number_representative
      : '',
    expiration_date_representative: findData.expiration_date_representative
      ? new Date(JSON.parse(findData.expiration_date_representative))
      : null,
    expiration_place_representative: findData.expiration_place_representative
      ? findData.expiration_place_representative
      : '',
    marital_status_representative: findData.marital_status_representative
      ? datos.maritalStatus.find((f: any) => f.id === findData.marital_status_representative)
      : {},
    gender_representative: findData.gender_representative ? findData.gender_representative : '',
    country_or_city_of_birth_representative: findData.country_or_city_of_birth_representative
      ? findData.country_or_city_of_birth_representative
      : '',
    date_of_birth_representative: findData.date_of_birth_representative
      ? new Date(JSON.parse(findData.date_of_birth_representative))
      : null,
    dominican_resident_representative: findData.dominican_resident_representative
      ? findData.dominican_resident_representative
      : '',
    nationality_representative: findData.nationality_representative
      ? data && countryASC.find((f: any) => f.id === Number(findData.nationality_representative))
      : {},
    double_nationality_representative: findData.double_nationality_representative
      ? findData.double_nationality_representative === 'true' && true
      : false,
    second_nationality_representative: findData.second_nationality_representative
      ? data &&
        countryASC.find((f: any) => f.id === Number(findData.second_nationality_representative))
      : {}

    /* email1_correspondence:
      findData.email1_correspondence && findData.email1_correspondence === 'true' ? true : false,
    email2_correspondence: findData.email2_correspondence ? findData.email2_correspondence : '' */
  }

  const {
    register,
    handleSubmit,
    control,
    formState,
    getValues,
    watch,
    resetField,
    reset,
    setError,
    clearErrors
  } = useForm<AdditionalDataType>({
    defaultValues,
    resolver: yupResolver(validationAdditionalDataPhysical)
  })
  const { errors, isDirty, dirtyFields } = formState

  const {
    fields: refAddBankAccountsField,
    append: refAddBankAccountsAppend,
    remove: refAddBankAccountsRemove
  } = useFieldArray({
    control,
    name: 'refAddBankAccounts',
    rules: {
      minLength: 3
    }
  })

  const {
    fields: entity_have_links_with_altio_dataFields,
    append: entity_have_links_with_altio_dataAppend,
    remove: entity_have_links_with_altio_dataRemove
  } = useFieldArray({
    control,
    name: 'entity_have_links_with_altio_data',
    rules: {
      minLength: 1
    }
  })

  const {
    fields: authorizedSignatures_Fields,
    append: authorizedSignatures_Append,
    remove: authorizedSignatures_Remove
  } = useFieldArray({
    control,
    name: 'authorizedSignatures',
    rules: {
      minLength: 1
    }
  })

  const watch_entity_have_links_with_altio: any = watch('entity_have_links_with_altio')
  const watch_entity_has_a_representative: any = watch('entity_has_a_representative')
  const watch_double_nationality_representative = watch('double_nationality_representative')
  const watch_openInvestmentFund: any = watch('openInvestmentFund')
  const watch_refAddBankAccounts: any = watch('refAddBankAccounts')

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  useEffect(() => {
    if (!watch_double_nationality_representative) {
      resetField('second_nationality_representative')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch_double_nationality_representative])

  useEffect(() => {
    if (data !== undefined && dirtyFields.entity_has_a_representative) {
      resetField('name_representative', { defaultValue: '' })
      resetField('altio_customer_representative', { defaultValue: '' })
      resetField('document_number_representative', { defaultValue: '' })
      resetField('expiration_place_representative', { defaultValue: '' })
      resetField('gender_representative', { defaultValue: '' })
      resetField('country_or_city_of_birth_representative', {
        defaultValue: ''
      })
      resetField('dominican_resident_representative', { defaultValue: '' })
      resetField('expiration_date_representative', { defaultValue: null })
      resetField('date_of_birth_representative', { defaultValue: null })
      resetField('id_type_representative', { defaultValue: [] })
      resetField('marital_status_representative', { defaultValue: {} })
      resetField('nationality_representative', { defaultValue: {} })
      resetField('second_nationality_representative', { defaultValue: { id: 0, description: '' } })
      resetField('double_nationality_representative', { defaultValue: false })
    } else if (watch_entity_has_a_representative === 'No') {
      resetField('name_representative')
      resetField('altio_customer_representative')
      resetField('document_number_representative')
      resetField('expiration_place_representative')
      resetField('gender_representative')
      resetField('country_or_city_of_birth_representative')
      resetField('dominican_resident_representative')
      resetField('expiration_date_representative')
      resetField('date_of_birth_representative')
      resetField('id_type_representative')
      resetField('marital_status_representative')
      resetField('nationality_representative')
      resetField('second_nationality_representative')
      resetField('double_nationality_representative')
    }
  }, [watch_entity_has_a_representative])

  useEffect(() => {
    if (watch_openInvestmentFund === 'A') {
      const getCurrencyDOP: any = watch_refAddBankAccounts.map((item: any, i: number) =>
        Number(item.currency_preference.id) !== 1
          ? setError(`refAddBankAccounts.${i}.currency_preference`, {
              type: 'custom',
              message: 'Moneda de la cuenta debe ser: Pesos Dominicanos (DOP)'
            })
          : clearErrors(`refAddBankAccounts.${i}.currency_preference`)
      )
    } else if (watch_openInvestmentFund === 'B') {
      const getCurrencyUSD = watch_refAddBankAccounts.map((item: any, i: number) =>
        Number(item.currency_preference.id) !== 2
          ? setError(`refAddBankAccounts.${i}.currency_preference`, {
              type: 'custom',
              message: 'Moneda de la cuenta debe ser: Dolar Estadounidense (USD)'
            })
          : clearErrors(`refAddBankAccounts.${i}.currency_preference`)
      )
    } else if (watch_openInvestmentFund === 'C') {
      if (watch_refAddBankAccounts.length < 2) {
        setErrorRefAddBankAccounts(
          'Debe tener al menos dos(2) cuentas bancarias, una de ellas en Pesos Dominicanos (DOP) y otra en Dolares Estadounidenses (USD)'
        )
      } else {
        clearErrors()
        setErrorRefAddBankAccounts('')
      }
    } else {
      setErrorRefAddBankAccounts('')
    }
  }, [watch_openInvestmentFund, watch_refAddBankAccounts])

  function validateArray(array: any) {
    // Check for array length less than 2
    if (array.length < 2) {
      return false
    }

    // Initialize variables to track preferred currency IDs
    let hasCurrencyId1 = false
    let hasCurrencyId2 = false

    // Loop through each object in the array
    for (const obj of array) {
      const {
        currency_preference: { id }
      } = obj // Destructuring for clarity
      //console.log(id);
      // Check if the current object's currency preference ID is 1 or 2
      if (Number(id) === 1) {
        hasCurrencyId1 = true
      } else if (Number(id) === 2) {
        hasCurrencyId2 = true
      } else {
        // If neither ID is found, return false immediately
        return false
      }
    }

    // Return true if both currency IDs are present
    return hasCurrencyId1 && hasCurrencyId2
  }

  const onSubmit = async (data: any) => {
    let isValid = false
    if (data.openInvestmentFund === 'C') {
      isValid = validateArray(data.refAddBankAccounts)
      console.log('isValid: ', isValid)
      if (!isValid) {
        setErrorRefAddBankAccounts(
          'Debe tener al menos dos(2) cuentas bancarias, una de ellas en Pesos Dominicanos (DOP) y otra en Dolares Estadounidenses (USD)'
        )
        return false
      }
    }

    setLoadingSendData(true)
    let key: any
    let value: { id: string; description: string }[] | any = []

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value })
      if (!value || value.length === 0) {
        delete data[key]
      } else if (typeof value == 'object') {
        /* data[key] = JSON.stringify(
            value !== null && value.id ? value.id : value
          ); */
        data[key] =
          value !== null && value.id
            ? typeof value.id !== 'string'
              ? JSON.stringify(value.id)
              : value.id
            : JSON.stringify(value)
      }
    }
    data.step_three_complete = 'true'

    let update = {}
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key)
      if (typeof value == 'object') {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== 'string'
                ? JSON.stringify(value.id)
                : value.id
              : JSON.stringify(value)
        })
      } else {
        Object.assign(update, { [key]: value })
      }
    }

    const handleResponse = () => {
      handleNext && handleNext()
    }

    if (isDirty) {
      let fullUpData = {
        fields: {
          ...update
        },
        formType: 'F',
        userId: userID,
        stepForm: 'Confirmación de Perfil'
      }
      const updateData = await updateProfile(fullUpData)
      if (updateData.status === 200) {
        loadData && loadData(userID)
        loadTypeProfile && loadTypeProfile(userID)
        handleResponse()
      }
    } else {
      setLoadingSendData(false)
      handleResponse()
    }
  }

  return (
    <div>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        <ButtonGradient type="button" onClick={() => setDisabledStep3(!disabledStep3)}>
          Editar datos
        </ButtonGradient>
      </Stack>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Controller
            name={'openInvestmentFund'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={disabledStep3}>
                <InputLabelToForm
                  error={errors.openInvestmentFund && Boolean(errors.openInvestmentFund)}
                >
                  ¿En cuál de nuestros Fondos de Inversión Abiertos está interesado?
                </InputLabelToForm>
                <Select
                  variant="standard"
                  value={value}
                  {...register('openInvestmentFund')}
                  error={errors.openInvestmentFund && Boolean(errors.openInvestmentFund)}
                >
                  {datos.alta_openInvestmentFund.map((item: any, index: number) => (
                    <MenuItem value={item.id} key={index}>
                      {item.description}
                    </MenuItem>
                  ))}
                </Select>
                {errors.openInvestmentFund && (
                  <FormHelperText>{errors.openInvestmentFund.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Registro de Cuentas Bancarias
      </TitleForm>
      {refAddBankAccountsField.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`refAddBankAccounts.${index}.entity`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={datos.entity}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      disabled={disabledStep3}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          autoFocus
                          label="Entidad"
                          variant="standard"
                          error={errors?.refAddBankAccounts?.[index]?.entity && true}
                          helperText={errors?.refAddBankAccounts?.[index]?.entity?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  control={control}
                  name={`refAddBankAccounts.${index}.accountType`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={datos.accountType}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      disabled={disabledStep3}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Tipo de Cuenta"
                          variant="standard"
                          error={errors?.refAddBankAccounts?.[index]?.accountType && true}
                          helperText={errors?.refAddBankAccounts?.[index]?.accountType?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`refAddBankAccounts.${index}.accountNumber`}
                  control={control}
                  render={({}) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Número de Cuenta"
                      type="text"
                      disabled={disabledStep3}
                      variant="standard"
                      {...register(`refAddBankAccounts.${index}.accountNumber` as const)}
                      error={errors?.refAddBankAccounts?.[index]?.accountNumber && true}
                      helperText={errors?.refAddBankAccounts?.[index]?.accountNumber?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
                <Controller
                  control={control}
                  name={`refAddBankAccounts.${index}.currency_preference`}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      fullWidth
                      onChange={(event, newValue) => {
                        onChange(newValue)
                      }}
                      options={allData.al_ta_currency_preference}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      disabled={disabledStep3}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Moneda de la Cuenta"
                          variant="standard"
                          error={errors?.refAddBankAccounts?.[index]?.currency_preference && true}
                          helperText={
                            errors?.refAddBankAccounts?.[index]?.currency_preference?.message
                          }
                        />
                      )}
                    />
                  )}
                />
                {index !== 0 && (
                  <Button
                    type="button"
                    variant="outlined"
                    aria-label="delete"
                    disabled={disabledStep3}
                    sx={{
                      width: '100%',
                      maxWidth: '180px',
                      ml: 2,
                      fontSize: '0.75rem'
                    }}
                    onClick={() => {
                      refAddBankAccountsRemove(index)
                    }}
                  >
                    <RemoveCircleIcon fontSize="small" sx={{ mr: 1 }} />
                    Eliminar Cuenta
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )
      })}
      {refAddBankAccountsField.length < 3 && (
        <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
          <Button
            type="button"
            variant="outlined"
            disabled={disabledStep3}
            onClick={() => {
              refAddBankAccountsAppend({
                entity: [],
                accountType: [],
                accountNumber: '',
                currency_preference: []
              })
            }}
          >
            <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar otra cuenta
          </Button>
        </Grid>
      )}
      {errorRefAddBankAccounts !== '' ? (
        <Typography variant="body2" color="error">
          {errorRefAddBankAccounts}
        </Typography>
      ) : (
        <Typography variant="body2">* Mínimo una(1) cuenta bancaria.</Typography>
      )}
      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
        * El/la Titular de la cuenta debe ser el propio cliente.
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Tarjeta de Firmas
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            name={'nameContributor'}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Nombre del aportante"
                type="text"
                variant="standard"
                disabled={disabledStep3}
                autoComplete="nameContributor"
                {...register('nameContributor')}
                error={errors.nameContributor && Boolean(errors.nameContributor)}
                helperText={errors.nameContributor && errors.nameContributor.message}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name={'newOrModificationSignature'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl disabled={disabledStep3}>
                <RadioGroup
                  aria-labelledby="newOrModificationSignature"
                  defaultValue="Modificacion"
                  row
                >
                  <FormControlLabel
                    value="Nueva"
                    control={<Radio {...register('newOrModificationSignature')} />}
                    label="Nueva"
                  />
                  <FormControlLabel
                    value="Modificacion"
                    control={<Radio {...register('newOrModificationSignature')} />}
                    label="Modificación"
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Firmas Autorizadas
      </TitleForm>
      {authorizedSignatures_Fields.map((field, index) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorizedSignatures.${index}.name`}
                  control={control}
                  render={({}) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Nombre"
                      type="text"
                      disabled={disabledStep3}
                      variant="standard"
                      {...register(`authorizedSignatures.${index}.name` as const)}
                      error={errors?.authorizedSignatures?.[index]?.name && true}
                      helperText={errors?.authorizedSignatures?.[index]?.name?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Controller
                  name={`authorizedSignatures.${index}.identification`}
                  control={control}
                  render={({}) => (
                    <TextField
                      fullWidth
                      size="small"
                      label="Identificación"
                      type="text"
                      disabled={disabledStep3}
                      variant="standard"
                      {...register(`authorizedSignatures.${index}.identification` as const)}
                      error={errors?.authorizedSignatures?.[index]?.identification && true}
                      helperText={errors?.authorizedSignatures?.[index]?.identification?.message}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      <Grid container>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="confirmSignatory"
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <FormControlLabel
                disabled={disabledStep3}
                control={<Checkbox onChange={onChange} checked={value} {...field} />}
                label="¿Confirma utilizar estos datos en firma autorizada?"
              />
            )}
          />
          {errors?.confirmSignatory && (
            <FormHelperText>{errors.confirmSignatory.message}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 5 }}>
        *En caso de añadir un firmante adicional como apoderado, usted deberá formalizar un Poder de
        Representación a favor de la persona identificada como firmante adicional, el cual deberá
        estar legalizado por Notario Público. El poder lo puede gestionar a través de su Promotor de
        Inversión.
      </Typography>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Personas Vinculadas
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="entity_have_links_with_altio"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                disabled={disabledStep3}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Posee vínculos con ALTIO, sus accionistas, directores, empleados?"
                      variant="standard"
                      error={!!errors.entity_have_links_with_altio}
                      helperText={
                        errors.entity_have_links_with_altio &&
                        errors.entity_have_links_with_altio.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      {watch_entity_have_links_with_altio?.id === '1' && (
        <>
          {entity_have_links_with_altio_dataFields.map((field: any, index: number) => {
            return (
              <div key={index}>
                {index !== 0 && (
                  <Divider
                    sx={{
                      pt: 3,
                      mb: 3,
                      borderColor: '#efe5df',
                      width: '50%',
                      ml: 'auto',
                      mr: 'auto'
                    }}
                  />
                )}
                <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
                  <Grid item sm={6} xs={12}>
                    <Controller
                      name={`entity_have_links_with_altio_data.${index}.name_or_social_denomination`}
                      control={control}
                      render={({}) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Nombre Completo"
                          type="text"
                          variant="standard"
                          disabled={disabledStep3}
                          {...register(
                            `entity_have_links_with_altio_data.${index}.name_or_social_denomination` as const
                          )}
                          error={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.name_or_social_denomination && true
                          }
                          helperText={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.name_or_social_denomination?.message
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} sx={{ display: 'flex' }}>
                    <Controller
                      name={`entity_have_links_with_altio_data.${index}.relation_with_entity`}
                      control={control}
                      render={({}) => (
                        <TextField
                          fullWidth
                          size="small"
                          label="Relación"
                          type="text"
                          disabled={disabledStep3}
                          variant="standard"
                          {...register(
                            `entity_have_links_with_altio_data.${index}.relation_with_entity` as const
                          )}
                          error={
                            errors?.entity_have_links_with_altio_data?.[index]
                              ?.relation_with_entity && true
                          }
                          helperText={
                            errors?.entity_have_links_with_altio_data?.[index]?.relation_with_entity
                              ?.message
                          }
                        />
                      )}
                    />
                    {index !== 0 && (
                      <IconButton
                        aria-label="delete"
                        color="primary"
                        disabled={disabledStep3}
                        onClick={() => {
                          entity_have_links_with_altio_dataRemove(index)
                        }}
                      >
                        <RemoveCircleIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </div>
            )
          })}
          {entity_have_links_with_altio_dataFields.length < 2 && (
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ mb: 2 }}>
              <Button
                type="button"
                variant="outlined"
                disabled={disabledStep3}
                onClick={() => {
                  entity_have_links_with_altio_dataAppend({
                    name_or_social_denomination: '',
                    relation_with_entity: ''
                  })
                }}
              >
                <AddCircleIcon fontSize="small" sx={{ mr: 1 }} /> Agregar persona
              </Button>
            </Grid>
          )}
        </>
      )}
      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12}>
          <Controller
            name={'entity_has_a_representative'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth disabled={disabledStep3}>
                <InputLabelToForm
                  error={
                    errors.entity_has_a_representative &&
                    Boolean(errors.entity_has_a_representative)
                  }
                >
                  ¿Tiene un apoderado?
                </InputLabelToForm>
                <Select
                  variant="standard"
                  value={value}
                  {...register('entity_has_a_representative')}
                  error={
                    errors.entity_has_a_representative &&
                    Boolean(errors.entity_has_a_representative)
                  }
                >
                  <MenuItem value="Sí">Sí</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
                {errors.entity_has_a_representative && (
                  <FormHelperText>{errors.entity_has_a_representative.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      {watch_entity_has_a_representative === 'Sí' && (
        <>
          <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
            Datos del apoderado
          </TitleForm>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'name_representative'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Nombre y Apellido"
                    type="text"
                    disabled={disabledStep3}
                    variant="standard"
                    autoComplete="name_representative"
                    {...register('name_representative')}
                    error={errors.name_representative && Boolean(errors.name_representative)}
                    helperText={errors.name_representative && errors.name_representative.message}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="id_type_representative"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      onChange(newValue)
                    }}
                    options={datos.id_type}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    disabled={disabledStep3}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Tipo de Identificación"
                        variant="standard"
                        error={!!errors.id_type_representative}
                        helperText={
                          errors.id_type_representative && errors.id_type_representative.message
                        }
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'altio_customer_representative'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={disabledStep3}>
                    <InputLabelToForm
                      error={
                        errors.altio_customer_representative &&
                        Boolean(errors.altio_customer_representative)
                      }
                    >
                      ¿Es cliente de ALTIO?
                    </InputLabelToForm>
                    <Select
                      variant="standard"
                      value={value}
                      {...register('altio_customer_representative')}
                      error={
                        errors.altio_customer_representative &&
                        Boolean(errors.altio_customer_representative)
                      }
                    >
                      <MenuItem value="Sí">Sí</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    {errors.altio_customer_representative && (
                      <FormHelperText>
                        {errors.altio_customer_representative.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'document_number_representative'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Número de Documento"
                    type="text"
                    disabled={disabledStep3}
                    variant="standard"
                    autoComplete="document_number_representative"
                    {...register('document_number_representative')}
                    error={
                      errors.document_number_representative &&
                      Boolean(errors.document_number_representative)
                    }
                    helperText={
                      errors.document_number_representative &&
                      errors.document_number_representative.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'expiration_date_representative'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep3}
                      label="Fecha de Expiración"
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                      className={errors.expiration_date_representative && 'Mui-error'}
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'expiration_place_representative'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="Lugar de Expedición"
                    type="text"
                    variant="standard"
                    disabled={disabledStep3}
                    autoComplete="expiration_place_representative"
                    {...register('expiration_place_representative')}
                    error={
                      errors.expiration_place_representative &&
                      Boolean(errors.expiration_place_representative)
                    }
                    helperText={
                      errors.expiration_place_representative &&
                      errors.expiration_place_representative.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="marital_status_representative"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      if (newValue === null) {
                        newValue = ''
                      }
                      onChange(newValue)
                    }}
                    options={datos.maritalStatus}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    disabled={disabledStep3}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Estado Civil"
                          variant="standard"
                          error={!!errors.marital_status_representative}
                          helperText={
                            errors.marital_status_representative &&
                            errors.marital_status_representative.message
                          }
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'gender_representative'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={disabledStep3}>
                    <InputLabelToForm
                      error={errors.gender_representative && Boolean(errors.gender_representative)}
                    >
                      Sexo
                    </InputLabelToForm>
                    <Select
                      label="Sexo"
                      variant="standard"
                      value={value}
                      {...register('gender_representative')}
                      error={errors.gender_representative && Boolean(errors.gender_representative)}
                    >
                      <MenuItem value="Femenino">Femenino</MenuItem>
                      <MenuItem value="Masculino">Masculino</MenuItem>
                    </Select>
                    {errors.gender_representative && (
                      <FormHelperText color="error">
                        {errors.gender_representative.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'country_or_city_of_birth_representative'}
                control={control}
                render={() => (
                  <TextField
                    fullWidth
                    size="small"
                    label="País o Ciudad de Nacimiento"
                    type="text"
                    variant="standard"
                    autoComplete="country_or_city_of_birth_representative"
                    disabled={disabledStep3}
                    {...register('country_or_city_of_birth_representative')}
                    error={
                      errors.country_or_city_of_birth_representative &&
                      Boolean(errors.country_or_city_of_birth_representative)
                    }
                    helperText={
                      errors.country_or_city_of_birth_representative &&
                      errors.country_or_city_of_birth_representative.message
                    }
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12} className="datepicker">
              <Controller
                name={'date_of_birth_representative'}
                control={control}
                defaultValue={null}
                render={({ field, ...props }) => (
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                    <DatePicker
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      disabled={disabledStep3}
                      label="Fecha de Nacimiento"
                      minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 90))}
                      className={errors.date_of_birth_representative && 'Mui-error'}
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
                    />
                  </LocalizationProvider>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                name={'dominican_resident_representative'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth disabled={disabledStep3}>
                    <InputLabelToForm
                      error={
                        errors.dominican_resident_representative &&
                        Boolean(errors.dominican_resident_representative)
                      }
                    >
                      ¿Es residente Dominicano?
                    </InputLabelToForm>
                    <Select
                      variant="standard"
                      value={value}
                      {...register('dominican_resident_representative')}
                      error={
                        errors.dominican_resident_representative &&
                        Boolean(errors.dominican_resident_representative)
                      }
                    >
                      <MenuItem value="Sí">Sí</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    {errors.dominican_resident_representative && (
                      <FormHelperText>
                        {errors.dominican_resident_representative.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="nationality_representative"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue: any) => {
                      if (newValue === null) {
                        newValue = ''
                      }
                      onChange(newValue)
                    }}
                    disabled={disabledStep3}
                    options={data && countryASC}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Nacionalidad"
                          variant="standard"
                          error={!!errors.nationality_representative}
                          helperText={
                            errors.nationality_representative &&
                            errors.nationality_representative.message
                          }
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <FormLabel component="legend">¿Doble Nacionalidad?</FormLabel>
              <Controller
                rules={{ required: true }}
                control={control}
                name="double_nationality_representative"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Switch
                      disabled={disabledStep3}
                      value={value}
                      checked={value}
                      {...register('double_nationality_representative')}
                      onChange={(val: any) => {
                        return onChange(val)
                      }}
                    />
                  )
                }}
              />
            </Grid>
            {watch_double_nationality_representative && (
              <Grid item md={4} sm={6} xs={12}>
                <Controller
                  control={control}
                  name="second_nationality_representative"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      onChange={(event, newValue: any) => {
                        if (newValue === null) {
                          newValue = ''
                        }
                        onChange(newValue)
                      }}
                      disabled={disabledStep3}
                      options={data && countryASC}
                      getOptionLabel={(option: any) => option.description || ''}
                      isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                      value={value}
                      renderInput={(params) => {
                        const inputProps = params.inputProps
                        inputProps.autoComplete = 'new-password'
                        return (
                          <TextField
                            {...params}
                            fullWidth
                            label="Indique 2da Nacionalidad"
                            variant="standard"
                            error={!!errors.second_nationality_representative}
                            helperText={
                              errors.second_nationality_representative &&
                              errors.second_nationality_representative.message
                            }
                          />
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Dirección de envío de correspondencia y comunicaciones
      </TitleForm>
      <Typography variant="body2" sx={{ mt: 5 }}>
        A través del presente documento, autorizo el uso de medios electrónicos, para el manejo de
        mi relación con ALTIO. En ese sentido, acepto el uso de los correos electrónicos del
        promotor de inversión y servicios@altiosafi.com para remitir comprobantes, instrucciones y/o
        solicitudes o cualquier documentación de tipo comercial o legal que deba ser intercambiada
        con ALTIO. En particular, reconozco que las solicitudes de rescates deberán cumplir con las
        modalidades de firma establecidas en el formulario de tarjeta de firmas.
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        Así mismo, acepto que ALTIO utilizará el correo electrónico notificaciones@altiosafi.com
        para el envío de informes, extractos y/o estados de cuentas, y certificados de suscripciones
        o rescate de cuotas. En consecuencia, por esta vía designo el correo electrónico registrado
        en “información general” como correo electrónico de contacto.
      </Typography>
      {/* <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={6} xs={12}>
          <Controller
            control={control}
            name="email1_correspondence"
            rules={{ required: true }}
            render={({ field: { onChange, value, ...field } }) => (
              <FormControlLabel
                disabled={disabledStep3}
                control={<Checkbox onChange={onChange} checked={value} {...field} />}
                label="Utilizar Correo electrónico registrado en “información general”"
              />
            )}
          />
          {errors?.email1_correspondence && (
            <FormHelperText>{errors.email1_correspondence.message}</FormHelperText>
          )}
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name={'email2_correspondence'}
            control={control}
            render={() => (
              <TextField
                fullWidth
                size="small"
                label="Otro Correo Electrónico"
                type="email"
                disabled={disabledStep3}
                variant="standard"
                autoComplete="email2_correspondence"
                {...register('email2_correspondence')}
                error={errors.email2_correspondence && Boolean(errors.email2_correspondence)}
                helperText={errors.email2_correspondence && errors.email2_correspondence.message}
              />
            )}
          />
        </Grid>
      </Grid> */}
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress size={24} sx={{ margin: '5px auto', color: '#6fc555' }} />
        </Stack>
      )}
      <Stack
        direction="row"
        spacing={2}
        justifyContent="flex-end"
        alignItems="flex-end"
        sx={{ mt: 1 }}
      >
        <Button
          type="button"
          variant="outlined"
          onClick={handleBack}
          sx={{
            width: { xs: '100%', sm: 'auto' },
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}
        >
          Regresar
        </Button>
        <ButtonGradient onClick={handleSubmit(onSubmit)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
          Continuar
        </ButtonGradient>
      </Stack>
    </div>
  )
}

export default AdditionalData
