import DashboardLayout from '../layouts/DashboardLayout'
import Tables, { TableHeadItem } from '../components/Tables'
import Typography from '@mui/material/Typography'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Box,
  Dialog,
  DialogTitle,
  Stack,
  TableCell,
  TableRow,
  TablePagination,
  Grid,
  DialogActions,
  Button,
  useTheme,
  IconButton
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useLayoutEffect, useState, useContext } from 'react'
import { deleteRubro, editRubro, getTables, newRubro } from '../services/Services'
import Progress from '../components/Progress'
import FancyInput from '../components/FancyInput'
import { en_es } from '../utils/I18n'
import RowActions from '../components/RowActions'
import SnackbarCustom from '../components/SnackbarCustom'
import { useSearcher } from '../hooks/useSearcher'
import UserDataContext from '../context/UserDataContext'

const Rubro = (): JSX.Element => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [apiResponse, setApiResponse] = useState<any>({ status: 0, statusText: '', body: '' })
  const [table, setTable] = useState<any>(null)
  const [headTable, setHeadTable] = useState<any>(null)
  const [emptyTable, setEmptyTable] = useState<string>('')
  const [tableName, setTableName] = useState<string>('')
  const [openEdit, setOpenEdit] = useState<boolean>(false)
  const [formEdit, setFormEdit] = useState<any>()
  const [isNewRubro, setIsNewRubro] = useState<boolean>(false)
  const [snackBar, setSnackBar] = useState<any>({ open: false, type: 'success', message: '' })
  const [newItemRubro, setnewItemRubro] = useState<any>(undefined)

  const [btnSort, setBtnSort] = useState<string>(' ')
  const [inputSearch, setInputSearch] = useState<string>('')
  const [sortAsc, setSortAsc] = useState(true)
  const [btnSearch, setBtnSearch] = useState<string>('')

  const { dataUser } = useContext(UserDataContext)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const { person, rubro } = useParams()
  const typePerson = person === 'physical' ? 'F' : 'J'

  const [contentDialog, setContentDialog] = useState<any>('')
  /*const { searchData, InputSearcher, inputSearch } = useSearcher(
    table && table.body.content,
    'description'
  )*/

  const newRubroBtn = () => {
    const newObj = {}
    table !== null
      ? Object.keys(table[0]).forEach((e) => {
          Object.assign(newObj, { [e]: '' })
        })
      : Object.keys(headTable).forEach((e) => {
          Object.assign(newObj, { [e]: '' })
        })

    setFormEdit(newObj)
    setIsNewRubro(true)
    setOpenEdit(true)
  }

  const confirmNewRubro = async () => {
    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Editar Item</DialogTitle>
        <Progress />
      </Box>
    )

    const response = await newRubro(rubro, formEdit)
    setnewItemRubro(response)
    setOpenEdit(false)
    setApiResponse(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: 'Item  agregado con exito' })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const editRubroBtn = (id: number) => {
    const find = table.find((item: any) => item.id === id)
    setFormEdit(find)
    setIsNewRubro(false)
    setOpenEdit(true)
  }

  const confirmEdit = async () => {
    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Editar Item</DialogTitle>
        <Progress />
      </Box>
    )
    const response = await editRubro(rubro, formEdit)
    setnewItemRubro(response)
    setOpenEdit(false)
    setApiResponse(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: 'Edición realizada con exito' })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const deleteRubroBtn = (id: number) => {
    setOpenEdit(true)
    setContentDialog(
      <Box>
        <DialogTitle mb={3}>¿Esta seguro que desea eliminar este item?</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-evenly', pb: 3 }}>
          <Button variant="text" color="error" onClick={() => setOpenEdit(false)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={() => confirmDeleteRubro(id)}>
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    )
  }

  const confirmDeleteRubro = async (id: number) => {
    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Eliminar Item</DialogTitle>
        <Progress />
      </Box>
    )
    const response = await deleteRubro(rubro, id)
    setnewItemRubro(response)
    setOpenEdit(false)
    setApiResponse(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: 'Item eliminado con exito' })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const fillDialog = (isNewItem: boolean) => {
    const newObj = Object.entries(formEdit)
    setContentDialog(
      <Box>
        <DialogTitle>{isNewItem ? 'Nuevo' : 'Editar'} Item</DialogTitle>
        {(table !== null ? newObj.slice(2) : newObj).map(([key, value]: any, i: number) => {
          if (key !== 'weight' && key !== 'type') {
            return (
              <Grid container spacing={2} sx={{ mb: 1.5, px: 3 }} key={i}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="body1" color="initial">
                    {en_es[key]}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <FancyInput
                    value={formEdit[key]}
                    placeholder={formEdit[key]?.toString()}
                    onChange={(e) =>
                      setFormEdit({ ...formEdit, [key]: e.target.value, type: typePerson })
                    }
                    disabled={key === 'idExternal' && dataUser.userRole === 'Editor' && !isNewItem}
                  />
                </Grid>
              </Grid>
            )
          }
        })}
        <DialogActions sx={{ justifyContent: 'space-evenly', pb: 3 }}>
          <Button variant="text" color="error" onClick={() => setOpenEdit(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isNewItem) {
                confirmNewRubro()
              } else {
                confirmEdit()
              }
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    )
  }

  useEffect(() => {
    setBtnSort('')
    if (formEdit) {
      fillDialog(isNewRubro)
    }
  }, [formEdit, isNewRubro])

  useLayoutEffect(() => {
    let isMounted = true
    setBtnSort('')
    setApiResponse(undefined)
    setEmptyTable('')
    const asyncEffect = async () => {
      if (isMounted) {
        const getData =
          inputSearch === ''
            ? await getTables(`?type=${typePerson}&page=${page}&size=${rowsPerPage}`, rubro)
            : await getTables(
                `{description}?description=${encodeURI(
                  inputSearch
                )}&type=${typePerson}&page=${page}&size=${rowsPerPage}`,
                rubro
              )
        setApiResponse(getData)
        let dataTables: any
        console.log(typeof getData.body.content)
        /* dataTables =
          typeof getData.body.content === 'object' &&
          getData.body.content?.map((m: any) => delete m.type) */
        if (getData?.body?.content && typeof getData.body.content === 'object') {
          //dataTables = getData.body.content?.map((m: any) => delete m.type)
          setTable(getData.body.content)
        } else {
          setEmptyTable(getData.body.content)
          setTable(null)
          const headNames = getData.body
          setTableName(headNames.tableName)
          delete headNames.id
          delete headNames.type
          delete headNames.tableName
          setHeadTable(headNames)
        }

        //typeof getData.body.content === 'string' && setEmptyTable(getData.body.content)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [newItemRubro, page, rowsPerPage, btnSearch])

  const sortTable = async (allData: any, nameCol: string, order: boolean) => {
    setTable([])
    setBtnSort(nameCol)
    const sortedData = await allData.sort((a: any, b: any) => {
      if (b[nameCol] < a[nameCol]) {
        return order ? 1 : -1
      }
      if (b[nameCol] > a[nameCol]) {
        return order ? -1 : 1
      }
      return 0
    })
    setSortAsc(order)
    setTable(sortedData)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <Typography
        variant="body1"
        color="textSecondary"
        sx={{ '& a': { color: theme.palette.text.secondary, textDecoration: 'none' } }}
      >
        <span onClick={() => navigate(-1)}>Inicio</span> {'>'}{' '}
        {/* {apiResponse?.body?.content ? apiResponse.body.content[0]?.tableName : ''} */}
        {table !== null
          ? apiResponse?.body?.content
            ? apiResponse.body.content[0]?.tableName
            : ''
          : tableName}
      </Typography>
      {apiResponse ? (
        apiResponse.status === 200 ? (
          table?.length !== 0 ? (
            <>
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'flex-end'}
                pb={3}
              >
                <Typography variant="h5">
                  Detalle Rubro{' '}
                  {table !== null
                    ? apiResponse &&
                      apiResponse.body.content &&
                      (apiResponse.body.content[0]?.tableName ?? '')
                    : tableName}
                </Typography>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <FancyInput
                      fullWidth={false}
                      variant="standard"
                      value={inputSearch}
                      label={'Buscar...'}
                      onChange={(e) => {
                        setInputSearch(e.target.value)
                        if (e.target.value === '') {
                          setBtnSearch(e.target.value)
                        }
                      }}
                      onKeyDown={(ev) => {
                        console.log(`Pressed keyCode ${ev.key}`)
                        if (ev.key === 'Enter') {
                          setBtnSearch(inputSearch)
                        }
                      }}
                    />
                    <IconButton aria-label="search" onClick={() => setBtnSearch(inputSearch)}>
                      <SearchIcon />
                    </IconButton>
                  </Stack>
                  {dataUser.userRole !== 'Lector' && (
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mr: 3 }}
                      onClick={() => newRubroBtn()}
                    >
                      Nuevo Item
                    </Button>
                  )}
                </Stack>
              </Stack>
              <Tables
                hiddenPagination={btnSearch !== ''}
                count={apiResponse.body.totalElements ?? apiResponse.body.content?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowHead={
                  table !== null
                    ? (dataUser.userRole !== 'Lector' || table.tableName !== 'Provincias'
                        ? Object.keys(table[0]).slice(2).concat(' ')
                        : Object.keys(table[0])
                      )?.map(
                        (item, i) =>
                          item !== 'type' && (
                            <TableHeadItem
                              key={i}
                              ml={i !== 0 ? 'auto' : 0}
                              mr={i !== 0 ? 'auto' : 0}
                              sx={{
                                pr: item === btnSort ? 0 : 3,
                                cursor: 'pointer',
                                userSelect: 'none',
                                position: 'relative'
                              }}
                              name={item}
                              arrowsHidden={item !== btnSort}
                              modeSort={sortAsc}
                              onClick={() =>
                                sortTable(table, item, btnSort === item ? !sortAsc : sortAsc)
                              }
                            />
                          )
                      )
                    : headTable !== null &&
                      Object.keys(headTable).map((item, i) => (
                        <TableHeadItem
                          key={i}
                          ml={i !== 0 ? 'auto' : 0}
                          mr={i !== 0 ? 'auto' : 0}
                          sx={{
                            pr: item === btnSort ? 0 : 3,
                            cursor: 'pointer',
                            userSelect: 'none',
                            position: 'relative'
                          }}
                          name={item}
                          arrowsHidden={item !== btnSort}
                          modeSort={sortAsc}
                          onClick={() =>
                            sortTable(table, item, btnSort === item ? !sortAsc : sortAsc)
                          }
                        />
                      ))
                }
              >
                {emptyTable !== '' ? (
                  <TableRow
                    hover
                    tabIndex={-1}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      cursor: 'pointer'
                    }}
                  >
                    <TableCell>{emptyTable}</TableCell>
                  </TableRow>
                ) : (
                  table.length > 0 &&
                  //dataTables = getData.body.content?.map((m: any) => delete m.type)
                  table.map((item: any, i: any) => {
                    // delete item.type
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={i}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: 'pointer'
                        }}
                      >
                        {Object.values(item)
                          .slice(2)
                          .map(
                            (cell: any, i: any) =>
                              cell !== 'F' &&
                              cell !== 'J' && (
                                <TableCell
                                  key={i}
                                  component={i !== 0 ? 'th' : 'td'}
                                  align={i !== 0 ? 'center' : 'left'}
                                  scope="row"
                                >
                                  {cell}
                                </TableCell>
                              )
                          )}
                        {dataUser.userRole !== 'Lector' && (
                          <TableCell align="right" sx={{ minWidth: 110 }}>
                            <RowActions
                              actionEdit={() => editRubroBtn(item.id)}
                              actionDelete={() => deleteRubroBtn(item.id)}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  })
                )}
              </Tables>
            </>
          ) : (
            <Typography
              variant="h5"
              color={'error'}
              align={'center'}
              my={10}
              mx={'auto'}
              maxWidth={700}
            >
              Esta tabla no contiene rubros, comuniquese con el administrador de base de datos
            </Typography>
          )
        ) : (
          <Typography variant="h5" color={'error'} align={'center'} mt={15}>
            {apiResponse.status
              ? `Error ${apiResponse.status}: ${apiResponse.error}`
              : apiResponse.statusText}
          </Typography>
        )
      ) : (
        <Progress />
      )}
      <Dialog onClose={() => setOpenEdit(false)} open={openEdit} fullWidth maxWidth="xs">
        {contentDialog}
      </Dialog>
      <SnackbarCustom
        open={snackBar.open}
        type={snackBar.type}
        message={snackBar.message}
        onClose={() => setSnackBar({ ...snackBar, open: false })}
      />
    </>
  )
}

export default Rubro
