import { ChangeEvent, SyntheticEvent, useState, useContext } from 'react'
import {
  Grid,
  Box,
  Container,
  Stack,
  Button,
  CircularProgress,
  useTheme,
  Link,
  Typography
} from '@mui/material'
import FancyInput from '../components/FancyInput'
import { getTwoFactorAutenticate } from '../services/Services'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import UserDataContext from '../context/UserDataContext'

interface loginFormInitTypes {
  username: string
  password: string
  code?: string
  userPortal: string
}

const loginFormInit = {
  username: '',
  password: '',
  code: '',
  userPortal: 'Administrativo'
}

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

const Login = (): JSX.Element => {
  const [loginForm, setLoginForm] = useState<loginFormInitTypes>(loginFormInit)
  const [response, setResponse] = useState<any>(undefined)
  const [loading, setLoading] = useState<boolean>(false)
  const [hidden, setHidden] = useState(true)
  const [, setCookie] = useCookies(['altio_auth', 'a_user'])
  const { setDataUser } = useContext(UserDataContext)

  const navigate = useNavigate()
  const theme = useTheme()

  const formChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target
    setLoginForm({
      ...loginForm,
      [name]: value
    })
  }

  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setResponse(undefined)
    if (loginForm.username !== '' && loginForm.password !== '') {
      setLoading(true)
      const nuevaData = await getTwoFactorAutenticate(loginForm)
      setResponse(nuevaData)
      setLoading(false)
      if (nuevaData?.status === 200) {
        setHidden(false)
        if (nuevaData?.body.status === 'ACTIVE') {
          setDataUser(nuevaData.body)
          setCookie('altio_auth', nuevaData.body.tokenInfo.access_token, {
            path: '/'
          })
          setCookie('a_user', nuevaData.body, {
            path: '/'
          })
          navigate('/dashboard-client')
        } else if (nuevaData?.body.status === 'PENDING_APPROVE') {
          alert('Usuario no activado, contacte a su administrador')
        } else if (nuevaData?.body.status === 'DELETE') {
          alert('Usuario eliminado, contacte a su administrador')
        }
      }
    }
  }

  return (
    <Grid container spacing={0} sx={{ backgroundColor: '#f8f5f3', minHeight: '100vh' }}>
      <Grid item xs={12}>
        <Container maxWidth="sm">
          <Stack justifyContent={'center'} alignItems={'center'} minHeight={'100vh'}>
            <img
              src="/logos/logo-altio-horizontal.svg"
              alt="ALTIO"
              width={200}
              style={{ marginBottom: 20 }}
            />
            <Typography variant="h5" color="primary" align="center" sx={{ mb: 4, fontWeight: 400 }}>
              Panel Administrativo
            </Typography>
            <Box
              component={'form'}
              onSubmit={onSubmit}
              width={'100%'}
              maxWidth={450}
              sx={{
                background: '#fff',
                borderRadius: '25px',
                padding: theme.spacing(3),
                mb: theme.spacing(4),
                boxShadow:
                  '0px 3px 5px -1px rgb(0 0 0 / 5%), 0px 6px 10px 0px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 5%)'
              }}
            >
              <Typography variant="h5" color="primary" align="center" sx={{ mb: 2 }}>
                Iniciar Sesión
              </Typography>
              <FancyInput
                required
                type={'email'}
                disabled={response?.status === 200}
                name="username"
                label="Usuario"
                value={loginForm.username}
                onChange={formChange}
                validation={[
                  {
                    validate: () => isValidEmail(loginForm.username),
                    msg: 'Correo inválido'
                  }
                ]}
                sx={{ mb: 3 }}
              />
              <FancyInput
                required
                disabled={response?.status === 200}
                type={'password'}
                label="Contraseña"
                name="password"
                value={loginForm.password}
                onChange={formChange}
                sx={{ mb: 3 }}
              />
              <Box hidden={hidden}>
                <FancyInput
                  required={!hidden}
                  name="code"
                  type={'text'}
                  label="Inserte Código"
                  value={loginForm.code}
                  onChange={formChange}
                />
                <Stack alignItems={'flex-end'}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={(e) => {
                      setLoginForm({ ...loginForm, code: '' })
                      setLoading(true)
                      setTimeout(() => {
                        onSubmit(e)
                      }, 2000)
                    }}
                  >
                    Reenviar Código
                  </Button>
                </Stack>
              </Box>
              {loading && !response && (
                <CircularProgress
                  size={30}
                  sx={{ margin: `${theme.spacing(2)} auto`, color: theme.palette.secondary.main }}
                />
              )}
              {response && (
                <Typography
                  variant="body2"
                  color="initial"
                  align="center"
                  sx={{ margin: `${theme.spacing(2)} 0` }}
                >
                  {response.estatus === 400 ? response.body.fieldErrors[0] : response.body.message}
                </Typography>
              )}

              <Stack justifyContent={'center'} direction={'row'}>
                <Button variant="contained" color="primary" sx={{ mt: 3 }} type="submit" fullWidth>
                  Iniciar Sesión
                </Button>
              </Stack>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Link
                  sx={{ color: '#00000080', textDecorationColor: '#00000080', mt: 1 }}
                  href="/recover-password"
                >
                  Olvidé mi contraseña
                </Link>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Grid>
    </Grid>
  )
}

export default Login
