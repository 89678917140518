import { useState, useEffect } from 'react'
import { Autocomplete, TextField, Stack, Grid, CircularProgress } from '@mui/material'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import ButtonGradient from '../../components/ButtonGradient'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { validationBussinessIndetification } from './validation'
import { BussinesIdenticationType } from '../../types/types'
import { saveProfile, updateProfile } from '../../services/Services'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import es from 'date-fns/locale/es'

const BussinessIdentification = () => {
  const { data, allData, allDataJ, address, handleNext, loadTypeProfile, loadData } = useClient()
  const [loadingSendData, setLoadingSendData] = useState(false)
  const miarray: any = []
  const userID = data.body?.userId
  const countryASC =
    allData &&
    allData.al_ta_countries.sort((a: any, b: any) => {
      if (a.description < b.description) {
        return -1
      }
    })

  const findData = data.body?.fields

  const defaultValues: BussinesIdenticationType = {
    investment_promoter: findData?.investment_promoter
      ? allData &&
        allData.al_ta_investment_promoter?.find(
          (f: any) => f.id === Number(findData?.investment_promoter)
        )
      : [],
    social_denomination: findData.social_denomination ? findData.social_denomination : '',
    trade_name: findData.trade_name ? findData.trade_name : '',
    incorporation_date: findData.incorporation_date ? new Date(findData.incorporation_date) : null,
    country_of_incorporation: findData.country_of_incorporation
      ? allData &&
        allData.al_ta_countries?.find(
          (f: any) => f.id === Number(findData.country_of_incorporation)
        )
      : [],
    city_of_incorporation: findData.city_of_incorporation ? findData.city_of_incorporation : '',
    entity_type: findData.entity_type
      ? datos.entity_type?.find((f: any) => f.id === findData.entity_type)
      : [],
    id_type_legal: findData.id_type_legal
      ? datos.id_type_legal?.find((f: any) => f.id === findData.id_type_legal)
      : [],
    type_of_legal_entity: findData.type_of_legal_entity
      ? datos.type_of_legal_entity?.find((f: any) => f.id === findData.type_of_legal_entity)
      : [],
    other_type_of_legal_entity: findData.other_type_of_legal_entity
      ? findData.other_type_of_legal_entity
      : '',
    document_number: findData.document_number ? findData.document_number : '',

    company_belong_to_economic_group: findData.company_belong_to_economic_group
      ? datos.selectBoolean.find((f: any) => f.id === findData.company_belong_to_economic_group)
      : [],
    commercial_register_number: findData.commercial_register_number
      ? findData.commercial_register_number
      : '',
    relationship_duration: findData.relationship_duration
      ? allData &&
        allData.al_ta_relationship_duration?.find(
          (f: any) => f.id === Number(findData.relationship_duration)
        )
      : [],
    company_incorporated_in_usa: findData.company_incorporated_in_usa
      ? datos.selectBoolean.find((f: any) => f.id === findData.company_incorporated_in_usa)
      : [],
    country: findData.country
      ? allData.al_ta_countries?.find((f: any) => f.id === Number(findData.country))
      : [],
    province: findData.province
      ? address && address.provinces.find((f: any) => f.id === Number(findData.province))
      : {},
    sector: findData.sector
      ? address && address.sector.find((f: any) => f.id === Number(findData.sector))
      : {},
    street_contact_person: findData.street_contact_person ? findData.street_contact_person : '',
    building: findData.building ? findData.building : '',
    phone_one: findData.phone_one ? findData.phone_one : '',
    name_contact_person: findData.name_contact_person ? findData.name_contact_person : '',
    position_in_entity: findData.position_in_entity ? findData.position_in_entity : '',
    contact_phone: findData.contact_phone ? findData.contact_phone : '',
    ext_contact_phone: findData.ext_contact_phone ? findData.ext_contact_phone : '',
    email: findData.email ? findData.email : '',
    website: findData.website ? findData.website : '',
    channel_catchment_client: '2'
  }

  const { register, handleSubmit, resetField, reset, formState, control, watch, getValues } =
    useForm<BussinesIdenticationType>({
      defaultValues,
      resolver: yupResolver(validationBussinessIndetification)
    })
  const { errors, isDirty, dirtyFields } = formState
  const watch_country: any = watch('country')
  const watch_province: any = watch('province')
  const watch_type_of_legal_entity: any = watch('type_of_legal_entity')

  useEffect(() => {
    reset(defaultValues)
  }, [data])

  useEffect(() => {
    if (data !== undefined && dirtyFields.country) {
      resetField('province', { defaultValue: [] })
      resetField('sector', { defaultValue: [] })
    } else if (watch_country?.id !== 63) {
      resetField('province')
      resetField('sector')
    }
  }, [watch_country])

  useEffect(() => {
    if (data !== undefined && dirtyFields.province) {
      resetField('sector', { defaultValue: [] })
    } else {
      resetField('sector')
    }
  }, [watch_province])

  useEffect(() => {
    if (data !== undefined && dirtyFields.type_of_legal_entity) {
      resetField('other_type_of_legal_entity', { defaultValue: '' })
    } else {
      resetField('other_type_of_legal_entity')
    }
  }, [watch_type_of_legal_entity])

  const onSubmit = async (data: any) => {
    //console.log(data)
    setLoadingSendData(true)
    let key: string | any
    let value: { id: string; description: string }[] | any = []

    for ([key, value] of Object.entries(data)) {
      miarray.push({ [key]: value })
      if (!value) {
        delete data[key]
      } else if (typeof value == 'object') {
        data[key] = value !== null && value.id ? value.id : `"${value}"`
      }
    }
    data.channel_catchment_client = '2'
    data.step_one_complete = 'true'

    let update = {}
    for ([key, value] of Object.entries(dirtyFields)) {
      value = getValues(key)
      if (typeof value == 'object') {
        Object.assign(update, {
          [key]:
            value !== null && value.id
              ? typeof value.id !== 'string'
                ? JSON.stringify(value.id)
                : value.id
              : `"${value}"`
        })
      } else {
        Object.assign(update, { [key]: value })
      }
      if (key === 'country') {
        if (value !== 63) {
          Object.assign(update, { province: '' })
          Object.assign(update, { municipality: '' })
          Object.assign(update, { sector: '' })
        }
      }
    }

    if (isDirty) {
      let fullUpData = {
        fields: {
          ...update
        },
        formType: 'J',
        userId: userID,
        stepForm: localStorage.getItem('stepForm')
      }
      const updateData = await updateProfile(fullUpData)
      if (updateData.status === 200) {
        loadData && loadData(userID)
        loadTypeProfile && loadTypeProfile(userID)
        handleNext && handleNext()
      }
    } else {
      handleNext && handleNext()
      setLoadingSendData(false)
    }
  }

  return (
    <form>
      <TitleForm>Datos Generales</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="investment_promoter"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue: any) => {
                  onChange(newValue)
                }}
                options={allData.al_ta_investment_promoter}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Promotor de Inversión"
                    variant="standard"
                    error={!!errors.investment_promoter}
                    helperText={errors.investment_promoter && errors.investment_promoter.message}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'social_denomination'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Denominación Social"
                type="text"
                variant="standard"
                value={value}
                autoComplete="social_denomination"
                {...register('social_denomination')}
                error={errors.social_denomination && Boolean(errors.social_denomination)}
                helperText={errors.social_denomination && errors.social_denomination.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'trade_name'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nombre comercial"
                type="text"
                variant="standard"
                value={value}
                autoComplete="trade_name"
                {...register('trade_name')}
                error={errors.trade_name && Boolean(errors.trade_name)}
                helperText={errors.trade_name && errors.trade_name.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'incorporation_date'}
            control={control}
            defaultValue={null}
            render={({ field, ...props }) => (
              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                <DatePicker
                  value={field.value}
                  onChange={(date) => {
                    field.onChange(date)
                  }}
                  label="Fecha de incorporación"
                  className={errors.incorporation_date && 'Mui-error'}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="country_of_incorporation"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={data && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="País de incorporación"
                      variant="standard"
                      error={!!errors.country_of_incorporation}
                      helperText={
                        errors.country_of_incorporation && errors.country_of_incorporation.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'city_of_incorporation'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Ciudad de incorporación"
                type="text"
                variant="standard"
                value={value}
                autoComplete="city_of_incorporation"
                {...register('city_of_incorporation')}
                error={errors.city_of_incorporation && Boolean(errors.city_of_incorporation)}
                helperText={errors.city_of_incorporation && errors.city_of_incorporation.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="entity_type"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.entity_type}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password3'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de entidad"
                      variant="standard"
                      error={!!errors.entity_type}
                      helperText={errors.entity_type && errors.entity_type.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="id_type_legal"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.id_type_legal}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password4'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de identificación"
                      variant="standard"
                      error={!!errors.id_type_legal}
                      helperText={errors.id_type_legal && errors.id_type_legal.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'document_number'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Número documento"
                type="text"
                variant="standard"
                value={value}
                autoComplete="document_number"
                {...register('document_number')}
                error={errors.document_number && Boolean(errors.document_number)}
                helperText={errors.document_number && errors.document_number.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="type_of_legal_entity"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.type_of_legal_entity}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password4'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo de persona Jurídica"
                      variant="standard"
                      error={!!errors.type_of_legal_entity}
                      helperText={
                        errors.type_of_legal_entity && errors.type_of_legal_entity.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {findData?.type_of_legal_entity?.id === '6' && (
          <Grid item md={4} sm={6} xs={12}>
            <Controller
              name={'other_type_of_legal_entity'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Otro tipo de persona Jurídica"
                  type="text"
                  variant="standard"
                  value={value}
                  autoComplete="other_type_of_legal_entity"
                  {...register('other_type_of_legal_entity')}
                  error={
                    errors.other_type_of_legal_entity && Boolean(errors.other_type_of_legal_entity)
                  }
                  helperText={
                    errors.other_type_of_legal_entity && errors.other_type_of_legal_entity.message
                  }
                />
              )}
            />
          </Grid>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'commercial_register_number'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Número de registro mercantil"
                type="text"
                variant="standard"
                value={value}
                autoComplete="commercial_register_number"
                {...register('commercial_register_number')}
                error={
                  errors.commercial_register_number && Boolean(errors.commercial_register_number)
                }
                helperText={
                  errors.commercial_register_number && errors.commercial_register_number.message
                }
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="company_belong_to_economic_group"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Pertenece la empresa a algún grupo económico?"
                      variant="standard"
                      error={!!errors.company_belong_to_economic_group}
                      helperText={
                        errors.company_belong_to_economic_group &&
                        errors.company_belong_to_economic_group.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="relationship_duration"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={
                  data && data.al_ta_relationship_duration ? data.al_ta_relationship_duration : []
                }
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="¿Desde cuándo es aportante en ALTIO SAFI?"
                    variant="standard"
                    error={!!errors.relationship_duration}
                    helperText={
                      errors.relationship_duration && errors.relationship_duration.message
                    }
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="company_incorporated_in_usa"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={datos.selectBoolean}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="¿Está su empresa constituida en los EEUU o bajo las leyes de los EEUU?"
                      variant="standard"
                      error={!!errors.company_incorporated_in_usa}
                      helperText={
                        errors.company_incorporated_in_usa &&
                        errors.company_incorporated_in_usa.message
                      }
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información de contacto
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 3 }}>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            control={control}
            name="country"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                onChange={(event, newValue) => {
                  onChange(newValue)
                }}
                options={data && countryASC}
                getOptionLabel={(option: any) => option.description || ''}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                value={value}
                renderInput={(params) => {
                  const inputProps = params.inputProps
                  inputProps.autoComplete = 'new-password'
                  return (
                    <TextField
                      {...params}
                      fullWidth
                      label="País"
                      variant="standard"
                      error={!!errors.country}
                      helperText={errors.country && errors.country.message}
                    />
                  )
                }}
              />
            )}
          />
        </Grid>
        {(findData?.country === '63' || watch_country?.id === 63) && (
          <>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="province"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={address.provinces}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) =>
                      option.provinceCode === value.provinceCode
                    }
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Provincia"
                          variant="standard"
                          error={!!errors.province}
                          helperText={!!errors.province && 'Campo es requerido'}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Controller
                control={control}
                name="sector"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    onChange={(event, newValue) => {
                      onChange(newValue)
                    }}
                    options={address.sector.filter(
                      (m: any) => m.provinceCode === watch_province?.provinceCode
                    )}
                    getOptionLabel={(option: any) => option.description || ''}
                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                    value={value}
                    renderInput={(params) => {
                      const inputProps = params.inputProps
                      inputProps.autoComplete = 'new-password'
                      return (
                        <TextField
                          {...params}
                          fullWidth
                          label="Sector"
                          variant="standard"
                          error={!!errors.sector}
                          helperText={!!errors.sector && 'Campo es requerido'}
                        />
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'street_contact_person'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Calle / Número"
                type="text"
                variant="standard"
                value={value}
                autoComplete="street_contact_person"
                {...register('street_contact_person')}
                error={errors.street_contact_person && Boolean(errors.street_contact_person)}
                helperText={errors.street_contact_person && errors.street_contact_person.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'building'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Edificio / Torre"
                type="text"
                variant="standard"
                value={value}
                autoComplete="building"
                {...register('building')}
                error={errors.building && Boolean(errors.building)}
                helperText={errors.building && errors.building.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'phone_one'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Teléfono Empresa / Entidad"
                type="text"
                variant="standard"
                value={value}
                autoComplete="phone_one"
                {...register('phone_one')}
                error={errors.phone_one && Boolean(errors.phone_one)}
                helperText={errors.phone_one && errors.phone_one.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'name_contact_person'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Nombre de la persona de contacto"
                type="text"
                variant="standard"
                value={value}
                autoComplete="name_contact_person"
                {...register('name_contact_person')}
                error={errors.name_contact_person && Boolean(errors.name_contact_person)}
                helperText={errors.name_contact_person && errors.name_contact_person.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'position_in_entity'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Cargo en la entidad"
                type="text"
                variant="standard"
                value={value}
                autoComplete="position_in_entity"
                {...register('position_in_entity')}
                error={errors.position_in_entity && Boolean(errors.position_in_entity)}
                helperText={errors.position_in_entity && errors.position_in_entity.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'contact_phone'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Teléfono de Contacto"
                type="text"
                variant="standard"
                value={value}
                autoComplete="contact_phone"
                {...register('contact_phone')}
                error={errors.contact_phone && Boolean(errors.contact_phone)}
                helperText={errors.contact_phone && errors.contact_phone.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'ext_contact_phone'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Extensión Teléfono de Contacto"
                type="text"
                variant="standard"
                value={value}
                autoComplete="ext_contact_phone"
                {...register('ext_contact_phone')}
                error={errors.ext_contact_phone && Boolean(errors.ext_contact_phone)}
                helperText={errors.ext_contact_phone && errors.ext_contact_phone.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'email'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Correo electrónico"
                type="text"
                variant="standard"
                value={value}
                autoComplete="email"
                {...register('email')}
                error={errors.email && Boolean(errors.email)}
                helperText={errors.email && errors.email.message}
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <Controller
            name={'website'}
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                size="small"
                label="Portal web de la empresa"
                type="text"
                variant="standard"
                value={value}
                autoComplete="website"
                {...register('website')}
                error={errors.website && Boolean(errors.website)}
                helperText={errors.website && errors.website.message}
              />
            )}
          />
        </Grid>
      </Grid>
      {loadingSendData && (
        <Stack spacing={2} direction="row" sx={{ mt: 1 }}>
          <CircularProgress size={24} sx={{ margin: '5px auto', color: '#6fc555' }} />
        </Stack>
      )}
      <Stack justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
        <ButtonGradient sx={{ width: { xs: '100%', sm: 'auto' } }} onClick={handleSubmit(onSubmit)}>
          Continuar
        </ButtonGradient>
      </Stack>
    </form>
  )
}

export default BussinessIdentification
