import { useEffect } from 'react'
import {
  Button,
  Grid,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  FormHelperText,
  Autocomplete
} from '@mui/material'
import { Controller, useForm, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { getQuotaSubscriptionFrameworkContract } from '../../../services/Documents'

const legal_representativeData = [
  {
    identification: '001-1808090-2',
    name: 'Eduardo José Turrull Leyba'
  },
  {
    identification: '001-1848238-9',
    name: 'Eliza Rafaelevna Sanchez Lomakina'
  }
]

const QuotaSubscriptionFrameworkContract = ({ dataClient }: any) => {
  const defaultValues = {
    legal_representative: {}
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset
  } = useForm({
    defaultValues,
    mode: 'onChange'
  })

  useEffect(() => {
    reset(defaultValues)
  }, [dataClient])

  const onSubmit = async (data: any) => {
    await getQuotaSubscriptionFrameworkContract(dataClient?.userId, data.legal_representative)
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="legal_representative"
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              onChange={(event, newValue: any) => {
                onChange(newValue)
              }}
              options={legal_representativeData}
              getOptionLabel={(option: any) => option.name || ''}
              isOptionEqualToValue={(option: any, value: any) =>
                option.identification === value.identification
              }
              value={value}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Representante legal"
                  variant="standard"
                  error={!!errors.legal_representative}
                  helperText={errors.legal_representative && 'Campo es requerido'}
                />
              )}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2, textAlign: 'center' }}>
        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
          Descargar
        </Button>
      </Grid>
    </Grid>
  )
}

export default QuotaSubscriptionFrameworkContract
