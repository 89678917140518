import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import {
  Grid,
  Stack,
  Typography,
  Divider,
  CircularProgress,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import { datos } from '../../data/data'
import {
  getKYC_fisico,
  getPerfil,
  getMatriz,
  getContract,
  getAccountRegister,
  getAuthorizedSignatures,
  getQuestionnaireProfile,
  getRequestDocumentClient
} from '../../services/Services'
import DocumentStage from '../../components/DocumentStage'

const InvestorTypeResults = () => {
  const { data, investmentProfile, allData, handleBack } = useClient()
  const [loadedPDFKYC, setLoadedPDFKYC] = useState(false)
  const [loadedPDFPerfil, setLoadedPDFPerfil] = useState(false)
  const [loadedPDFMatriz, setLoadedPDFMatriz] = useState(false)
  const [loadeContract, setLoadeContract] = useState(false)
  const [value, setValue] = useState<any>()
  const [dataContract, setDataContract] = useState({
    userId: data.body.userId,
    idAltioAgent: ''
  })
  const [selectAgent, setSelectAgent] = useState(false)
  const { id } = useParams()
  const [documentByClientInitial, setDocumentByClientInitial] = useState<any>(null)
  const [exemptAgentDocument, setExemptAgentDocument] = useState<any>(null)
  const [documentDeclareIncome, setDocumentDeclareIncome] = useState<any>(null)
  const [errorMsg, setErrorMsg] = useState('')

  const handleDownloadKYC = async () => {
    setLoadedPDFKYC(true)
    await getKYC_fisico(id)
    await getAccountRegister(id)
    await getAuthorizedSignatures(id)
    await getQuestionnaireProfile(id)
    await getPerfil(id)
    setLoadedPDFKYC(false)
  }

  const handleDownloadPerfil = async () => {
    setLoadedPDFPerfil(true)
    await getPerfil(id)
    setLoadedPDFPerfil(false)
  }

  const handleDownloadMatriz = async () => {
    setLoadedPDFMatriz(true)
    await getMatriz(id)
    setLoadedPDFMatriz(false)
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setLoadeContract(true)
    await getContract(dataContract.userId, dataContract.idAltioAgent)
    setLoadeContract(false)
  }

  useEffect(() => {
    setDataContract({
      ...dataContract,
      idAltioAgent: value ? value.id : ''
    })
    value ? setSelectAgent(true) : setSelectAgent(false)
  }, [value])

  const handleFetchDocumentsByClient = async (id: string, categoryType?: string | null) => {
    try {
      let documentsList = await getRequestDocumentClient(id, categoryType)
      return documentsList
    } catch (error: any) {
      setErrorMsg(error.message)
    }
  }

  const handleLoadDocumentsInitial = async () => {
    if (id) {
      const initialDocument = await handleFetchDocumentsByClient(id, 'Documentos iniciales')
      if (initialDocument) {
        setDocumentByClientInitial(initialDocument)
      }
      //Categoría Agente exento de retención
      const loadExemptAgentDocument = await handleFetchDocumentsByClient(
        id,
        'Agente exento de retención'
      )
      if (loadExemptAgentDocument) {
        setExemptAgentDocument(loadExemptAgentDocument)
      }
      //Categoría Declara renta
      const declareIncome = await handleFetchDocumentsByClient(id, 'Declara renta')
      if (declareIncome) {
        setDocumentDeclareIncome(declareIncome)
      }
    }
  }

  useEffect(() => {
    const loadDocuments = async () => {
      await handleLoadDocumentsInitial()
    }
    loadDocuments()
  }, [id])

  return (
    <div>
      <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <TitleForm sx={{ mt: 2, mb: 4 }}> Tipo de Inversionista</TitleForm>
          <Typography sx={{ mb: 2 }}>
            Según las selecciones del cliente, el resultado es:
          </Typography>
          <Typography align="center" variant="h6" color="primary">
            {investmentProfile && investmentProfile?.body?.result?.INVESTOR_TYPE}
          </Typography>
          {investmentProfile?.body?.result?.INVESTOR_CATEGORY && (
            <>
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: 'secondary.main',
                  width: '50%',
                  maxWidth: '450px',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />

              <Typography
                align="center"
                variant="h5"
                sx={{
                  width: '100%',
                  maxWidth: '300px',
                  backgroundColor: 'primary.main',
                  color: '#fff',
                  pb: '4px',
                  margin: '0 auto',
                  borderRadius: '4px',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.9]
                  }
                }}
                color="light.contrastText"
              >
                {investmentProfile?.body?.result?.INVESTOR_CATEGORY}
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Divider
        sx={{
          pt: 3,
          mb: 6,
          borderColor: 'secondary.main',
          width: '50%',
          maxWidth: '450px',
          ml: 'auto',
          mr: 'auto'
        }}
      />
      {data.body?.fields?.agree_cat_investor_profile === 'Sí' ||
        (data.body?.fields?.agree_cat_investor_profile === 'Si' && (
          <Typography sx={{ mb: 4 }} align="center" variant="h5">
            Cliente esta de acuerdo con la categorización de su perfil
          </Typography>
        ))}

      {data.body?.fields?.agree_cat_investor_profile === 'No' && (
        <>
          <Typography
            sx={{ mt: 4, mb: 4, fontSize: '1.25rem!important' }}
            align="center"
            variant="h5"
          >
            Sin embargo, el cliente solicitó considerarse de la siguiente manera:
          </Typography>
          <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
            {data.body?.fields?.agree_cat_investor_profile === 'No' && (
              <Grid item xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name="type_investor_considered"
                  label="Sino está de acuerdo con su perfil calculado, ¿Qué tipo de Inversionista se considera?"
                  value={
                    data.body?.fields?.type_investor_considered &&
                    allData &&
                    allData.al_ta_type_investor_considered.find(
                      (f: any) => f.id === Number(data.body?.fields?.type_investor_considered)
                    )?.description
                  }
                />
              </Grid>
            )}
            {data.body?.fields?.type_investor_considered !== '1' && (
              <Grid item xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name="what_cat_investor_considered"
                  label="Si el cliente se considera Inversionista No Profesional, ¿En que categoria de Inversionista se considera?"
                  value={
                    data.body?.fields?.what_cat_investor_considered &&
                    allData &&
                    allData.al_ta_what_cat_investor_considered.find(
                      (f: any) => f.id === Number(data.body?.fields?.what_cat_investor_considered)
                    )?.description
                  }
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Grid container spacing={6} maxWidth="lg" sx={{ mb: 5 }}>
        <Grid item xs={12}>
          {id && (
            <DocumentStage
              categoryType={'Documentos Iniciales'}
              documentByClient={documentByClientInitial}
              handleFetchDocumentsByClient={handleLoadDocumentsInitial}
              clientId={id}
              title="Documentos iniciales"
              notification={false}
            />
          )}
          {id && data.body?.fields?.axempt_agent_withholding === 'Sí' && (
            <DocumentStage
              categoryType={'Agente exento de retención'}
              documentByClient={exemptAgentDocument}
              handleFetchDocumentsByClient={handleLoadDocumentsInitial}
              clientId={id}
              title="Documentos de agente exento de retención"
              notification={true}
              require={true}
            />
          )}
          {id && data.body?.fields?.declare_income === 'Sí' && (
            <DocumentStage
              categoryType={'Declara renta'}
              documentByClient={documentDeclareIncome}
              handleFetchDocumentsByClient={handleLoadDocumentsInitial}
              clientId={id}
              title="Documentos declaración de renta"
              notification={true}
              require={true}
            />
          )}
        </Grid>
      </Grid>
      <TitleForm sx={{ mt: 4, mb: 4 }}> Descarga de Documentos</TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            justifyContent="center"
            alignItems="center"
            spacing={{ xs: 1, sm: 2, md: 4 }}
            sx={{ maxWidth: { xs: '600px', md: '100%' }, margin: { xs: '0 auto' } }}
          >
            <Button variant="outlined" onClick={handleDownloadKYC}>
              {loadedPDFKYC && <CircularProgress size={24} sx={{ color: '#6fc555', mr: 1 }} />}
              Descargar
            </Button>
            {/* <Button variant="outlined" onClick={handleDownloadPerfil}>
              {loadedPDFPerfil && <CircularProgress size={24} sx={{ mr: 1, color: '#6fc555' }} />}
              Descargar Evaluación del Perfil
                </Button>*/}
            <Button variant="outlined" onClick={handleDownloadMatriz}>
              {loadedPDFMatriz && <CircularProgress size={24} sx={{ mr: 1, color: '#6fc555' }} />}
              Descargar Matriz de Riesgo
            </Button>
          </Stack>
          {/* <Divider
            sx={{
              pt: 3,
              mb: 3,
              borderColor: 'secondary.main',
              width: '50%',
              maxWidth: '450px',
              ml: 'auto',
              mr: 'auto'
            }}
          /> */}
          {/* <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <Autocomplete
              id="altio_agents"
              value={value}
              options={datos.AltioAgents}
              onChange={(event: any, newValue: string | null) => {
                setValue(newValue)
              }}
              getOptionLabel={(option: any) => option.label}
              isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
              sx={{ width: 300, mb: 0 }}
              renderInput={(params) => (
                <TextField {...params} sx={{ mb: 0 }} variant="standard" label="Agente de Altio" />
              )}
            />
            <Button variant="outlined" onClick={handleSubmit} disabled={!selectAgent}>
              {loadeContract && (
                <CircularProgress size={24} sx={{ marginRight: '5px', color: '#6fc555' }} />
              )}
              Descargar Contrato
            </Button>
          </Stack> */}
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" sx={{ mt: 1 }}>
        <Button
          type="button"
          variant="outlined"
          onClick={handleBack}
          sx={{
            width: { xs: '100%', sm: 'auto' },
            boxShadow:
              '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)'
          }}
        >
          Regresar
        </Button>
      </Stack>
    </div>
  )
}

export default InvestorTypeResults
