import { FC, useEffect, useState } from 'react'
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Stack,
  Divider,
  Button,
  CircularProgress
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es'
import dayjs, { Dayjs } from 'dayjs'
import { useForm, Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import useClient from '../hooks/useClient'
import InputLabelToForm from '../components/InputLabelToForm'
import ButtonGradient from '../components/ButtonGradient'
import { getMetamap, getCountries } from '../services/Services'

const formSchemaCountry = {
  code: Yup.string().required('Campo es requerido'),
  country: Yup.string().required('Campo es requerido')
}

const validationMetaMap = Yup.object().shape({
  entityType: Yup.string().required('Campo es requerido'),
  birthYear: Yup.date().when('entityType', ([entityType], validationMetaMap) => {
    return entityType === 'person'
      ? validationMetaMap.typeError('Campo es requerido')
      : validationMetaMap.nullable()
  }),
  name: Yup.string().required('Campo es requerido'),
  countryCode: Yup.object().shape(formSchemaCountry).required('Campo es requerido')
})

type MetaMapType = {
  entityType: string
  birthYear: Dayjs | null
  name: string
  countryCode: {
    code: string
    country: string
  } | null
}

const MetaMap = () => {
  const defaultValues: MetaMapType = {
    entityType: '',
    birthYear: null,
    countryCode: null,
    name: ''
  }

  const { register, handleSubmit, resetField, formState, control, watch, reset } =
    useForm<MetaMapType>({
      defaultValues,
      resolver: yupResolver(validationMetaMap)
    })
  const { errors } = formState
  const { allData } = useClient()
  const [countries, setCountries] = useState([])
  const [valueDate, setValueDate] = useState<Dayjs | null>(dayjs(null))
  const [searchData, setSearchData] = useState(null)
  const watchEntityType: any = watch('entityType')
  const watchDate: any = watch('birthYear')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchData()
    }
    dataInit()

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (watchEntityType === 'company') {
      setValueDate(dayjs(null))
    }
  }, [watchEntityType])

  const handleFetchData = async () => {
    try {
      let countriesData = await getCountries()
      if (countriesData) {
        setCountries(countriesData.body.content)
      }
    } catch (error: any) {
      setErrorMsg(error.message)
      console.log(error)
    }
  }

  const handleSearch = async (data: any) => {
    setSearchData(null)
    setLoading(true)
    try {
      let searchData = await getMetamap({
        ...data
      })
      if (searchData) {
        setSearchData(searchData.body)
      }

      if (!searchData) {
        setErrorMsg('Error en buscar datos')
        setLoading(false)
        return
      }
      setLoading(false)
    } catch (error: any) {
      setErrorMsg(error.message)
      setLoading(false)
    }
  }

  const handleClear = () => {
    setSearchData(null)
    setValueDate(dayjs(null))
    reset(defaultValues)
  }

  const onSubmit = async (data: any) => {
    await handleSearch(data)
  }

  return (
    <Paper
      sx={{ width: '100%', maxWidth: '800px', overflow: 'hidden', p: 3, pb: 4, margin: '0 auto' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5" gutterBottom mb={4}>
          Ingresa tus datos
        </Typography>
        <Grid container rowSpacing={{ xs: 2, sm: 4 }} columnSpacing={{ xs: 2, sm: 4 }}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="countryCode"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={(event, newValue) => {
                    onChange(newValue)
                  }}
                  options={countries && countries}
                  getOptionLabel={(option: any) => option.country || ''}
                  isOptionEqualToValue={(option: any, value: any) => option.code === value.code}
                  value={value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Seleccione país"
                      variant="standard"
                      error={!!errors.countryCode}
                      helperText={errors.countryCode && errors.countryCode.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={'entityType'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <InputLabelToForm error={errors.entityType && Boolean(errors.entityType)}>
                    Tipo de persona
                  </InputLabelToForm>
                  <Select
                    variant="standard"
                    value={value}
                    {...register('entityType')}
                    error={errors.entityType && Boolean(errors.entityType)}
                  >
                    <MenuItem value="person">Física</MenuItem>
                    <MenuItem value="company">Jurídica</MenuItem>
                  </Select>
                  {errors.entityType && (
                    <FormHelperText>{errors.entityType.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name={'birthYear'}
              control={control}
              defaultValue={null}
              render={({ field, ...props }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                  <DatePicker
                    label={'Año de nacimiento'}
                    value={valueDate}
                    onChange={(date) => {
                      field.onChange(date)
                      setValueDate(date)
                    }}
                    views={['year']}
                    maxDate={dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 16)))}
                    disabled={watchEntityType === 'company'}
                    sx={{
                      width: '100%',
                      '& fieldset': {
                        borderWidth: '0 0 1px 0!important',
                        borderRadius: '0!important'
                      },
                      '& label': {
                        transform: 'translate(0px, 16px) scale(1)',
                        '&.Mui-focused, &[data-shrink="true"]': {
                          transform: 'translate(0px, -1.5px) scale(0.75)'
                        }
                      },
                      '& input': {
                        paddingBottom: '4px',
                        paddingLeft: '0'
                      }
                    }}
                  />
                  {errors.birthYear && (
                    <FormHelperText color="error">{errors.birthYear.message}</FormHelperText>
                  )}
                </LocalizationProvider>
              )}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <Controller
              name={'name'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  size="small"
                  label="Nombre"
                  type="text"
                  variant="standard"
                  value={value}
                  {...register('name')}
                  error={errors.name && Boolean(errors.name)}
                  helperText={errors.name && errors.name.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 1 }}
              direction="row"
              spacing={2}
            >
              {searchData !== null && (
                <Button
                  type="button"
                  variant="outlined"
                  onClick={handleClear}
                  sx={{ width: { xs: '100%', sm: 'auto' } }}
                >
                  Limpiar
                </Button>
              )}
              <ButtonGradient type="submit" sx={{ width: { xs: '100%', sm: 'auto', mt: '0' } }}>
                Buscar
              </ButtonGradient>
            </Stack>
          </Grid>
        </Grid>
      </form>
      {loading && (
        <CircularProgress
          size={45}
          sx={{ display: 'block', margin: '35px auto 5px', color: '#6fc555' }}
        />
      )}
      {searchData !== null && (
        <>
          <Divider sx={{ pt: 4, mb: 4 }} />
          <Grid container rowSpacing={{ xs: 2 }} columnSpacing={{ xs: 2, sm: 4 }}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Fecha de búsqueda: </strong>
                {
                  //@ts-ignore
                  searchData.searchedOn
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Id de búsqueda: </strong>
                {
                  //@ts-ignore
                  searchData.searchId
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>Nombre: </strong>
                {
                  //@ts-ignore
                  searchData.nameSearched
                }
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <ButtonGradient
                href={
                  //@ts-ignore
                  searchData.profileUrl
                }
                target="_blank"
                sx={{ textAlign: 'center' }}
              >
                Consulta aquí
              </ButtonGradient>
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  )
}

export default MetaMap
