import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Stack,
  TableCell,
  TableRow,
  TablePagination,
  Typography,
  Select,
  MenuItem,
  IconButton,
  FormHelperText
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useLayoutEffect, useState } from 'react'
import FancyInput from '../components/FancyInput'
import Progress from '../components/Progress'
import RowActions from '../components/RowActions'
import SnackbarCustom from '../components/SnackbarCustom'
import Tables, { TableHeadItem } from '../components/Tables'
import { deleteUser, editUser, getUsers, newUser, newUserAdmin } from '../services/Services'
import { en_es } from '../utils/I18n'
import { useSearcher } from '../hooks/useSearcher'
import { resendNotificationActivateUser } from '../services/Services'
import AddOrEditUser from '../components/utils/AddOrEditUser'

function isValidEmail(email: string) {
  return /\S+@\S+\.\S+/.test(email)
}

const defaultForm = {
  id: 0,
  idType: {},
  identification: '',
  firstName: '',
  lastName: '',
  username: '',
  status: '',
  phone: '',
  userRole: ''
}

const UserManage = () => {
  const [users, setUsers] = useState<any>(undefined)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [contentDialog, setContentDialog] = useState<any>('')
  const [apiResponse, setApiResponse] = useState<any>({ status: 0, statusText: '', body: '' })
  const [formEdit, setFormEdit] = useState<any>(null)
  const [isNewUser, setIsNewUser] = useState<boolean>(false)

  const [inputSearch, setInputSearch] = useState<string>('')
  const [btnSort, setBtnSort] = useState<string>('')
  const [sortAsc, setSortAsc] = useState(true)
  const [btnSearch, setBtnSearch] = useState<string>('')
  const [newItemUser, setNewItemUser] = useState<any>(undefined)

  const [snackBar, setSnackBar] = useState<any>({ open: false, type: 'success', message: '' })
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [customError, setCustomError] = useState(false)
  const [errorPass, setErrorPass] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openModalNewUser, setOpenModalNewUser] = useState(false)
  const [openModalUpdUser, setOpenModalUpdUser] = useState(false)
  /* const { searchData, InputSearcher, inputSearch } = useSearcher(
    users && users.body.content,
    'username'
  ) */

  const newUserBtn = () => {
    setFormEdit({
      id: '',
      username: '',
      identification: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      userRole: 'Lector'
    })
    setIsNewUser(true)
    setOpenDialog(true)
  }

  const confirmNewUser = async () => {
    console.log('Entra nuevo usuario')
    console.log('Pass: ', formEdit.password)
    console.log(
      'PassMatch: ',
      formEdit.password.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,10}$/)?.length > 0
    )
    setCustomError(false)
    setErrorPass(false)
    if (formEdit.password.match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,10}$/)?.length > 0) {
      console.log('entra error')
      setErrorPass(true)
      return false
    }
    if (
      formEdit.username === '' ||
      formEdit.identification === '' ||
      formEdit.firstName === '' ||
      formEdit.lastName === '' ||
      formEdit.password === ''
    ) {
      setCustomError(true)
      return false
    }
    //if (formEdit.password.length <= 5) {

    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Editar Item</DialogTitle>
        <Progress />
      </Box>
    )
    const setData = {
      identification: formEdit.identification,
      firstName: formEdit.firstName,
      lastName: formEdit.lastName,
      password: formEdit.password,
      phone: formEdit.phone,
      username: formEdit.username,
      userRole: formEdit.userRole
    }
    let response
    if (setData.userRole === 'Cliente') {
      response = await newUser(setData)
    } else {
      response = await newUserAdmin(setData)
    }
    console.log('response: ', response)
    setNewItemUser(response)
    setOpenDialog(false)
    setUsers(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: 'Usuario agregado con exito' })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: response.body.message
        //message: response.body.message 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const editUserBtn = async (id: number) => {
    const find = await users.find((item: any) => item.id === id)
    setFormEdit(find)
    setIsNewUser(false)
    setOpenDialog(true)
  }

  const confirmEditUser = async () => {
    if (
      formEdit.username === '' ||
      formEdit.identification === '' ||
      formEdit.identification === null ||
      formEdit.firstName === '' ||
      formEdit.lastName === ''
    ) {
      setCustomError(true)
      return false
    }
    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Editar Usuario</DialogTitle>
        <Progress />
      </Box>
    )
    const response = await editUser(formEdit)
    setNewItemUser(response)
    setOpenDialog(false)
    setUsers(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: response.body.info })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const deleteUserBtn = (id: any) => {
    setOpenDialog(true)
    setContentDialog(
      <Box minWidth={350} py={3}>
        <DialogTitle mb={3}>¿Esta seguro que desea desactivar a este usuario?</DialogTitle>
        <DialogActions sx={{ justifyContent: 'space-evenly', pb: 3 }}>
          <Button variant="outlined" color="error" onClick={() => setOpenDialog(false)}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={() => confirmDeleteUser(id)}>
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    )
  }

  const confirmDeleteUser = async (id: any) => {
    setContentDialog(
      <Box minWidth={350}>
        <DialogTitle>Eliminar Usuaio</DialogTitle>
        <Progress />
      </Box>
    )
    const response = await deleteUser(id)
    setNewItemUser(response)
    setOpenDialog(false)
    setUsers(undefined)
    if (response.status === 200) {
      setSnackBar({ open: true, type: 'success', message: response.body.info })
    } else {
      setSnackBar({
        open: true,
        type: 'error',
        message: 'Se encontro un error en la peticion al servidor'
      })
    }
  }

  const handleResendActivateUser = async (data: any) => {
    console.log(data.username)
    try {
      setLoading(true)
      const response = await resendNotificationActivateUser(data.username)
      if (response) {
        setSnackBar({ open: true, type: 'success', message: response })
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setSnackBar({
        open: true,
        type: 'error',
        message: error.message
      })
    }
  }

  const fillDialog = (isNewItem: boolean, isCustomError: boolean, isErrorPass: boolean) => {
    setContentDialog(
      <Box sx={{ padding: { md: 3, xs: 2 } }}>
        {!isNewItem && formEdit.status === 'PENDING_AP' && (
          <Stack flexDirection={'row'} justifyContent={'flex-end'}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleResendActivateUser(formEdit)}
            >
              Reenviar código activar usuario
            </Button>
          </Stack>
        )}
        <DialogTitle>{isNewItem ? 'Nuevo' : 'Editar'} Usuario</DialogTitle>

        {Object.entries(formEdit)
          .slice(1)
          .map(([key, value]: any, i: number) => {
            if (key !== 'activationCode') {
              return (
                <Grid container spacing={2} sx={{ mb: 1.5, px: 3 }} key={i} alignItems={'center'}>
                  <Grid item xs={12} sm={5}>
                    <Typography variant="body1" color="initial">
                      {en_es[key] ? en_es[key] : key}
                    </Typography>
                  </Grid>
                  {key !== 'userRole' ? (
                    <Grid item xs={12} sm={7}>
                      {key === 'username' ? (
                        <FancyInput
                          required={true}
                          name={en_es[key] ? en_es[key] : key}
                          autoComplete="new-password"
                          value={formEdit[key]}
                          placeholder={formEdit[key]}
                          type={'email'}
                          onChange={(e) => setFormEdit({ ...formEdit, [key]: e.target.value })}
                          disabled={key === 'status'}
                          error={isCustomError}
                          validation={[
                            {
                              validate: () => isValidEmail(formEdit[key]),
                              msg: 'Correo inválido'
                            }
                          ]}
                        />
                      ) : key === 'password' ? (
                        <FancyInput
                          required={true}
                          name={en_es[key] ? en_es[key] : key}
                          autoComplete="new-password"
                          value={formEdit[key]}
                          placeholder={formEdit[key]}
                          type={'password'}
                          onChange={(e) => setFormEdit({ ...formEdit, [key]: e.target.value })}
                          disabled={key === 'status'}
                          error={isCustomError}
                          customErrorPass={isErrorPass}
                          validation={[
                            {
                              //validate: () => formEdit[key].length < 5,
                              validate: () =>
                                formEdit[key].match(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,10}$/)
                                  ?.length > 0,
                              msg: 'Formato no es correcto, debe contener al menos 1 mayúscula, 1 dígito y máximo 10 caracteres'
                            }
                          ]}
                        />
                      ) : (
                        <FancyInput
                          required={key !== 'phone' ? true : false}
                          name={en_es[key] ? en_es[key] : key}
                          autoComplete="new-password"
                          value={formEdit[key]}
                          placeholder={formEdit[key]}
                          type={'text'}
                          onChange={(e) => setFormEdit({ ...formEdit, [key]: e.target.value })}
                          disabled={key === 'status'}
                          error={key !== 'phone' ? isCustomError : false}
                        />
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={7} sx={{ mb: 2 }}>
                      <Select
                        variant="standard"
                        fullWidth
                        value={formEdit[key]}
                        onChange={(e) => setFormEdit({ ...formEdit, userRole: e.target.value })}
                      >
                        <MenuItem value="Administrador">Administrador</MenuItem>
                        <MenuItem value="Editor">Editor</MenuItem>
                        <MenuItem value="Lector">Lectura</MenuItem>
                        <MenuItem value="Cliente">Cliente</MenuItem>
                      </Select>
                    </Grid>
                  )}
                </Grid>
              )
            }
          })}
        <DialogActions sx={{ justifyContent: 'space-evenly', pb: 3 }}>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setOpenDialog(false)
              setCustomError(false)
              setErrorPass(false)
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (isNewItem) {
                confirmNewUser()
              } else {
                confirmEditUser()
              }
            }}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Box>
    )
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setBtnSort('')
      if (formEdit) {
        fillDialog(isNewUser, customError, errorPass)
      }
    }
    return () => {
      isMounted = false
    }
  }, [formEdit, isNewUser, customError, errorPass])

  useLayoutEffect(() => {
    let isMounted = true
    const asyncEffect = async () => {
      if (isMounted) {
        setBtnSort('')
        const getData =
          inputSearch === ''
            ? await getUsers(`?page=${page}&size=${rowsPerPage}`)
            : await getUsers(`${encodeURI(inputSearch)}?page=${page}&size=${rowsPerPage}`)
        setApiResponse(getData)
        setUsers(getData.body.content)
      }
    }
    asyncEffect()
    return () => {
      isMounted = false
    }
  }, [page, rowsPerPage, btnSearch, newItemUser])

  const sortTable = async (allData: any, nameCol: string, order: boolean) => {
    setUsers([])
    setBtnSort(nameCol)
    const sortedData = await allData.sort((a: any, b: any) => {
      if (b[nameCol] < a[nameCol]) {
        return order ? 1 : -1
      }
      if (b[nameCol] > a[nameCol]) {
        return order ? -1 : 1
      }
      return 0
    })
    setSortAsc(order)
    setUsers(sortedData)
  }

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleOpenModal = async (id?: any) => {
    setOpenModalNewUser(true)
    if (id) {
      const find = await users.find((item: any) => item.id === id)
      setFormEdit(find)
      setOpenModalUpdUser(true)
    }
  }

  const handleCloseModal = async () => {
    setOpenModalNewUser(false)
  }

  return apiResponse && users ? (
    apiResponse.status === 200 ? (
      <>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} pb={3}>
          <Typography variant="h5">Gestión de Usuarios</Typography>
          <Stack direction={'row'} spacing={2} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'flex-end'}>
              <FancyInput
                fullWidth={false}
                variant="standard"
                value={inputSearch}
                label={'Buscar...'}
                autoFocus
                onChange={(e) => {
                  setInputSearch(e.target.value)
                  if (e.target.value === '') {
                    setBtnSearch(e.target.value)
                  }
                }}
                onKeyDown={(ev) => {
                  console.log(`Pressed keyCode ${ev.key}`)
                  if (ev.key === 'Enter') {
                    setBtnSearch(inputSearch)
                  }
                }}
              />
              <IconButton aria-label="search" onClick={() => setBtnSearch(inputSearch)}>
                <SearchIcon />
              </IconButton>
            </Stack>
            <Button
              variant="contained"
              color="primary"
              sx={{ mr: 3 }}
              //onClick={() => newUserBtn()}
              onClick={handleOpenModal}
            >
              Nuevo Usuario
            </Button>
          </Stack>
        </Stack>
        <Tables
          hiddenPagination={btnSearch !== ''}
          count={
            apiResponse.body.totalElements
              ? apiResponse.body.totalElements
              : apiResponse.body.content.length
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowHead={[
            'username',
            'firstName',
            'lastName',
            'phone',
            'status',
            'userRole',
            'createdAt',
            ' '
          ].map((item, i) => (
            <TableHeadItem
              key={i}
              ml={i !== 0 ? 'auto' : 0}
              sx={{ pr: item === btnSort ? 0 : 3, cursor: 'pointer', userSelect: 'none' }}
              name={item}
              arrowsHidden={item !== btnSort}
              modeSort={sortAsc}
              onClick={() => sortTable(users, item, btnSort === item ? !sortAsc : sortAsc)}
            />
          ))}
        >
          {Array.isArray(users) ? (
            users.map((item: any, i: any) => (
              <TableRow
                hover
                tabIndex={-1}
                key={i}
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
              >
                <TableCell component="th" scope="row">
                  {item.username}
                </TableCell>
                <TableCell align="right">{item.firstName}</TableCell>
                <TableCell align="right">{item.lastName}</TableCell>
                <TableCell align="right">{item.phone}</TableCell>
                <TableCell align="right">{item.status}</TableCell>
                <TableCell align="right">{item.userRole}</TableCell>
                <TableCell align="right">
                  {new Date(item.createdAt).toLocaleString('en-GB', { timeZone: 'UTC' })}
                </TableCell>
                <TableCell align="right" sx={{ minWidth: 110 }}>
                  <RowActions
                    actionEdit={() => handleOpenModal(item.id)}
                    //actionEdit={() => editUserBtn(item.id)}
                    actionDelete={() => deleteUserBtn(item.id)}
                  />
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}>
              <TableCell colSpan={3}>El elemento buscado no existe</TableCell>
            </TableRow>
          )}
        </Tables>

        <Dialog
          onClose={() => {
            setOpenDialog(false)
          }}
          open={openDialog}
          fullWidth
          maxWidth="sm"
        >
          {contentDialog}
        </Dialog>
        <SnackbarCustom
          open={snackBar.open}
          type={snackBar.type}
          message={snackBar.message}
          onClose={() => setSnackBar({ ...snackBar, open: false })}
        />
        {openModalNewUser && (
          <Dialog
            onClose={() => {
              setOpenModalNewUser(false)
            }}
            open={openModalNewUser}
            fullWidth
            maxWidth="sm"
          >
            <AddOrEditUser
              data={formEdit}
              cancelModal={handleCloseModal}
              setSnackBar={setSnackBar}
              openModalUpdUser={openModalUpdUser}
            />
          </Dialog>
        )}
      </>
    ) : (
      <Typography variant="h5" color={'error'} align={'center'} my={10}>
        {apiResponse.status
          ? `Error ${apiResponse.status}: ${apiResponse.error}`
          : apiResponse.statusText}
      </Typography>
    )
  ) : (
    <Progress />
  )
}

export default UserManage
