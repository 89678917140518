import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import FancyInputFile from './FancyInputFile'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { useApp } from '../hooks/useApp'
import {
  Grid,
  Typography,
  IconButton,
  useTheme,
  Button,
  Stack,
  Divider,
  InputLabel,
  Dialog
} from '@mui/material'
import { useForm } from 'react-hook-form'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FileViewModal from './utils/FileViewModal'

interface DocumentStageType {
  categoryType: string | null
  documentByClient: null
  handleFetchDocumentsByClient: any
  clientId: any
  title?: string
  require?: boolean
  notification: boolean
  promoterId?: string
}

const DocumentStage = ({
  categoryType,
  documentByClient,
  handleFetchDocumentsByClient,
  clientId,
  title,
  require,
  notification,
  promoterId
}: DocumentStageType) => {
  const theme = useTheme()
  const { setLoading, setErrorMsg, modalData, setModalData, setSuccessMsg, errorMsg } = useApp()
  const { handleSubmit } = useForm({
    mode: 'onChange'
  })

  const [customLoader, setCustomLoader] = useState(false)
  const [documentByClientByRole, setDocumentByClientByRole] = useState<any | null>(null)
  let newF = { newField: false }

  useEffect(() => {
    const dataInit = async () => {
      await handleFetchDocuments()
    }
    dataInit()
  }, [documentByClient])

  const handleFetchDocuments = async () => {
    try {
      setDocumentByClientByRole(
        documentByClient &&
          documentByClient !== null &&
          //@ts-ignore
          documentByClient.length > 0 &&
          //@ts-ignore
          documentByClient.map((d: any) => ({ ...d, ...newF }))
      )
    } catch (error: any) {
      setCustomLoader(false)
      setLoading && setLoading(false)
      setErrorMsg && setErrorMsg(error.message)
    }
  }

  useEffect(() => {
    if (modalData && modalData?.modalOpen && errorMsg) {
      setModalData &&
        setModalData({
          modalOpen: false,
          modalType: '',
          modalObject: null
        })
    }
  }, [modalData, errorMsg, setModalData])

  const handleOpenModal = async (event: any) => {
    event.preventDefault()

    const modalAction = event.currentTarget.getAttribute('data-name')
    let object = null
    const id = event.currentTarget.getAttribute('data-id')
    const nameD = event.currentTarget.getAttribute('data-name-document')
    const extensionD = event.currentTarget.getAttribute('data-extension')

    if (modalAction === 'delete') {
      object = { document: nameD, id: id }
    }

    if (modalAction === 'view') {
      object = { id: id, nameD: nameD, ext: extensionD }
    }

    //open modal
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: true,
        modalType: modalAction,
        //@ts-ignore
        modalObject: object
      })
  }

  const handleCancelModal = () => {
    setModalData &&
      setModalData({
        ...modalData,
        modalOpen: false,
        modalType: '',
        modalObject: null
      })
  }

  const addField = async (e: any) => {
    const nameDoc = e.currentTarget.getAttribute('data-name')
    const newDocumentByClient = documentByClientByRole.map((d: any) => {
      if (d.name !== nameDoc) return d
      return { ...d, newField: true }
    })
    setDocumentByClientByRole(newDocumentByClient)
    /* const nameDoc = e.currentTarget.getAttribute("data-name");
    const newDocumentByClient = documentByClient.map((d: any) => {
      if (d.name !== nameDoc) return d;
      return { ...d, newField: true };
    });
    setDocumentByClient(newDocumentByClient); */
  }

  return (
    <>
      <Typography
        variant="h5"
        color="greyDue.dark"
        sx={{ fontWeight: 400, fontSize: '1.25rem!important' }}
      >
        <ChevronRightIcon
          sx={{
            marginRight: '0px',
            color: 'secondary.main',
            transform: 'translateY(3px)',
            fontSize: '1.25rem'
          }}
        />{' '}
        {title ? title : 'Categoría de Documentos'}
      </Typography>
      <Divider />
      {customLoader ? (
        <Typography>Cargando...</Typography>
      ) : (
        <Grid container spacing={4} maxWidth="lg" sx={{ pt: 2, pb: 8 }}>
          {documentByClientByRole &&
            documentByClientByRole.map((dr: any, i: any) => (
              <Grid
                item
                xs={12}
                key={i}
                sx={{
                  '&:after': {
                    content: '""',
                    width: 'calc(100% - 165px)',
                    height: '1px',
                    display: 'block',
                    background: 'rgb(33, 37, 41)',
                    marginTop: '15px',
                    opacity: '0.25'
                  }
                }}
              >
                <InputLabel sx={{ color: theme.palette.primary.dark }}>
                  {dr.name}{' '}
                  {(require || dr.required) && (
                    <Typography variant="button" sx={{ color: theme.palette.success.main }}>
                      *
                    </Typography>
                  )}
                </InputLabel>
                {dr?.uploaded?.length > 0 && (
                  <>
                    {dr.uploaded.map((dup: any, i: any) => (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        clientId={clientId}
                        idDocumentByClient={dup.id}
                        documentId={dup.document.id}
                        nameDocument={dup.originalName}
                        systemNameDocument={dup.systemName ? dup.systemName : ''}
                        extension={dup.extension}
                        handleOpenModal={handleOpenModal}
                        require={require ? require : dr.required}
                        maxSize={dr.maxsizes}
                        notification={notification}
                        promoterId={promoterId}
                      />
                    ))}
                    {dr.newField && (
                      <FancyInputFile
                        key={i}
                        handleOptions={undefined}
                        label=" "
                        clientId={clientId}
                        idDocumentByClient={''}
                        documentId={dr.id}
                        require={require ? require : dr.required}
                        extensionList={dr.extension}
                        maxSize={dr.maxsizes}
                        notification={notification}
                        promoterId={promoterId}
                      />
                    )}
                  </>
                )}
              </Grid>
            ))}
          {modalData?.modalType === 'view' && (
            <Dialog open={modalData.modalOpen} onClose={handleCancelModal} maxWidth="xl" fullWidth>
              <FileViewModal
                //@ts-ignore
                document={modalData?.modalObject}
                cancelModal={handleCancelModal}
              />
            </Dialog>
          )}
        </Grid>
      )}
    </>
  )
}

export default DocumentStage
