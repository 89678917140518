import useClient from '../../hooks/useClient'
import FancyInput from '../../components/FancyInput'
import TitleForm from '../../components/TitleForm'
import { Grid, Divider } from '@mui/material'
import { formatDate } from '../../utils'
import { datos } from '../../data/data'

const AdditionalLegalInformation = () => {
  const { data } = useClient()

  return (
    <div>
      <TitleForm>Datos de Accionistas, Proveedores y Socios</TitleForm>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Principales proveedores en la entidad
      </TitleForm>
      {JSON.parse(data.body?.fields?.main_suppliers_entity).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_suppliers_entity.${index}.social_name`}
                  label="Nombres / Denominación social"
                  value={field.social_name}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_suppliers_entity.${index}.identification_number`}
                  label="Número de identificación"
                  value={field.identification_number}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_suppliers_entity.${index}.economic_sector`}
                  label="Sector económico"
                  value={field.economic_sector.description}
                />
              </Grid>
              {field.economic_sector?.id === '15' && (
                <Grid item md={3} sm={6} xs={12}>
                  <FancyInput
                    required
                    type={'text'}
                    disabled={true}
                    name={`main_suppliers_entity.${index}.other_economic_sector`}
                    label="Otro sector económico"
                    value={field.other_economic_sector}
                  />
                </Grid>
              )}
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`main_suppliers_entity.${index}.contact`}
                  label="Contacto"
                  value={field.contact}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información sobre los accionistas
      </TitleForm>
      {JSON.parse(data.body?.fields?.information_shareholders).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`information_shareholders.${index}.social_name`}
                  label="Nombres / Denominación social"
                  value={field.social_name}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`information_shareholders.${index}.identification_number`}
                  label="Número de identificación"
                  value={field.identification_number}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`information_shareholders.${index}.percentage_stake`}
                  label="Porcentaje de participación"
                  value={field.percentage_stake}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`information_shareholders.${index}.nationality_incorporation`}
                  label="Nacionalidad o lugar de incorporación"
                  value={field.nationality_incorporation.description}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`information_shareholders.${index}.US_nationality_or_residence`}
                  label="¿Posee nacionalidad o residencia en EEUU?"
                  value={field.US_nationality_or_residence.description}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Miembros del órgano de administración
      </TitleForm>
      {JSON.parse(data.body?.fields?.members_management_body).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`members_management_body.${index}.social_name`}
                  label="Nombres / Denominación social"
                  value={field.social_name}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`members_management_body.${index}.identification_number`}
                  label="Número de identificación"
                  value={field.identification_number}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`members_management_body.${index}.position`}
                  label="Cargo"
                  value={field.position}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`members_management_body.${index}.nationality`}
                  label="Nacionalidad"
                  value={field.nationality.description}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`members_management_body.${index}.US_nationality_or_residence`}
                  label="¿Posee nacionalidad o residencia en EEUU?"
                  value={field.US_nationality_or_residence.description}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item sm={6} xs={12}></Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Participación en la administración pública
      </TitleForm>
      <Grid container rowSpacing={{ xs: 2, sm: 3 }} columnSpacing={{ xs: 2, sm: 4 }}>
        <Grid item xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="manages_public_resources"
            label="¿La entidad administra recursos públicos?"
            value={
              data.body?.fields?.manages_public_resources &&
              datos.selectBoolean.find(
                (f: any) => f.id === data.body?.fields?.manages_public_resources
              )?.description
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="includes_shareholders_or_partners"
            label="¿Alguno de los accionistas, socios, beneficiarios finales, miembros del órgano de administración o ejecutivos es una persona expuesta políticamente?"
            value={
              data.body?.fields?.includes_shareholders_or_partners &&
              datos.selectBoolean.find(
                (f: any) => f.id === data.body?.fields?.includes_shareholders_or_partners
              )?.description
            }
          />
        </Grid>
      </Grid>
      {(data.body?.fields?.manages_public_resources === '1' ||
        data.body?.fields?.includes_shareholders_or_partners === '1') && (
        <>
          <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
            Datos de la persona expuesta políticamente
          </TitleForm>
          {JSON.parse(data.body?.fields?.politically_exposed_person_members).map(
            (field: any, index: number) => {
              return (
                <div key={index}>
                  {index !== 0 && (
                    <Divider
                      sx={{
                        pt: 3,
                        mb: 3,
                        borderColor: '#efe5df',
                        width: '50%',
                        ml: 'auto',
                        mr: 'auto'
                      }}
                    />
                  )}
                  <Grid
                    container
                    rowSpacing={{ xs: 2, sm: 3 }}
                    columnSpacing={{ xs: 2, sm: 4 }}
                    key={field.id}
                    sx={{ mb: 2 }}
                  >
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.name`}
                        label="Nombres / Denominación social"
                        value={field.name}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.identification_number`}
                        label="Número de identificación"
                        value={field.identification_number}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.institution_name`}
                        label="Nombre institución"
                        value={field.institution_name}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.position`}
                        label="Cargo"
                        value={field?.position}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.appointment_date`}
                        label="Fecha de Designación"
                        value={field.appointment_date && formatDate(field.appointment_date)}
                      />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                      <FancyInput
                        required
                        type={'text'}
                        disabled={true}
                        name={`politically_exposed_person_members.${index}.removal_date`}
                        label="Fecha de Termino"
                        value={field.removal_date && formatDate(field.removal_date)}
                      />
                    </Grid>
                  </Grid>
                </div>
              )
            }
          )}
        </>
      )}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Información representante legal o apoderados
      </TitleForm>
      {JSON.parse(data.body?.fields?.legal_representative).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.name`}
                  label="Nombres y Apellidos"
                  value={field.name}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.id_type`}
                  label="Tipo de Identificación"
                  value={field.id_type.description}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.document_number`}
                  label="Número Documento"
                  value={field.document_number}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.issuing_country`}
                  label="País emisor del documento"
                  value={field.issuing_country.description}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.date_of_birth`}
                  label="Fecha de Nacimiento"
                  value={field.date_of_birth && formatDate(field.date_of_birth)}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.country_of_birth`}
                  label="País de Nacimiento"
                  value={field.country_of_birth.description}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.UUEE_nationality`}
                  label="¿Posee usted nacionalidad o residencia permanente (Green Card) de los EEUU?"
                  value={field.UUEE_nationality.description}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.country`}
                  label="País de Residencia"
                  value={field.country?.description}
                />
              </Grid>
              {Number(field.country.id) === 63 && (
                <>
                  <Grid item md={3} sm={6} xs={12}>
                    <FancyInput
                      required
                      type={'text'}
                      disabled={true}
                      name={`legal_representative.${index}.province`}
                      label="Provincia"
                      value={field?.province?.description}
                    />
                  </Grid>
                  <Grid item md={3} sm={6} xs={12}>
                    <FancyInput
                      required
                      type={'text'}
                      disabled={true}
                      name={`legal_representative.${index}.sector`}
                      label="Sector"
                      value={field.sector?.description}
                    />
                  </Grid>
                </>
              )}
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.address`}
                  label="Dirección (Calle / Número)"
                  value={field.address}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.building_name`}
                  label="Nombre Edificio / Torre"
                  value={field.building_name}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.floor_number`}
                  label="N° Piso / Suite"
                  value={field.floor_number}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.postal_code`}
                  label="Código Postal"
                  value={field.postal_code}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.residential_phone`}
                  label="Teléfono Residencial"
                  value={field.residential_phone}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.office_phone`}
                  label="Teléfono de Oficina"
                  value={field.office_phone}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.cellphone`}
                  label="Teléfono Móvil"
                  value={field.cellphone}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`legal_representative.${index}.email`}
                  label="Correo Electrónico"
                  value={field.email}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Dirección de envío de correspondencia y comunicaciones
      </TitleForm>
      <Grid container spacing={4} sx={{ mb: 2 }}>
        <Grid item sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="email1_correspondence"
            label="Correo Electrónico"
            value={
              data.body?.fields?.email1_correspondence && data.body?.fields?.email1_correspondence
            }
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <FancyInput
            required
            type={'text'}
            disabled={true}
            name="email2_correspondence"
            label="Otro correo electrónico"
            value={
              data.body?.fields?.email2_correspondence && data.body?.fields?.email2_correspondence
            }
          />
        </Grid>
      </Grid>
      <TitleForm variant="h6" sx={{ mt: 5, mb: 4 }}>
        Cuentas bancarias
      </TitleForm>
      {JSON.parse(data.body?.fields?.bank_accounts_register).map((field: any, index: number) => {
        return (
          <div key={index}>
            {index !== 0 && (
              <Divider
                sx={{
                  pt: 3,
                  mb: 3,
                  borderColor: '#efe5df',
                  width: '50%',
                  ml: 'auto',
                  mr: 'auto'
                }}
              />
            )}
            <Grid container spacing={4} key={field.id} sx={{ mb: 2 }}>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`bank_accounts_register.${index}.account_holder`}
                  label="Titular de la cuenta"
                  value={field.account_holder}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`bank_accounts_register.${index}.entity_account`}
                  label="Entidad"
                  value={field.entity_account.description}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`bank_accounts_register.${index}.account_type`}
                  label="Tipo de cuenta"
                  value={field.account_type.description}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`bank_accounts_register.${index}.account_number`}
                  label="Número de cuenta"
                  value={field.account_number}
                />
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <FancyInput
                  required
                  type={'text'}
                  disabled={true}
                  name={`bank_accounts_register.${index}.currency_type`}
                  label="Moneda"
                  value={field.currency_type.description}
                />
              </Grid>
            </Grid>
          </div>
        )
      })}
    </div>
  )
}

export default AdditionalLegalInformation
