export const CONSTANT = {
  HEADER_TYPE: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  HEADER_TYPE_PDF: {
    Accept: 'application/pdf',
    'Content-Type': 'application/json'
  },

  /** TYPE MESSAGE */
  SUCCESS_MSG: 1,
  ERROR_MSG: 2,
  WARNING_MSG: 3,

  DISPATCHER_USER_PREFIX: 'users/',
  DISPATCHER_INVESTMENT_PROFILE: 'investmentprofile/',
  DISPATCHER_MAINTENANCE: 'maintenance/',
  DISPATCHER_DOCUMENTS: 'documents/'
}
